/**
 * @typedef {object} Tile
 * @property {boolean} fixed - position of this tile is fixed in a row once set
 * @property {string} id - unique identifier
 * @property {string} title
 * @property {boolean} canExpand - can this tile grow to fill a row?
 * @property {number} sortWeight - used to sort tiles in layout
 * @property {boolean} empty - is the tile empty (no items)
 */

export default class Tile {
  /**
   * @param {object} options
   * @param {number} options.sortWeight
   * @param {number} options.width
   * @param {boolean} options.canExpand
   * @param {number} options.maxWidth
   * @param {string} options.title
   * @param {string} options.id
   * @param {boolean} options.fixed
   */
  constructor(options = {}) {
    const defaults = {
      sortWeight: 0,
      width: 1,
      canExpand: true,
      maxWidth: 2,
      title: '',
      id: '',
      fixed: false
    };
    this.settings = Object.assign(defaults, options);
    this._items = [];
  }

  /**
   * Add an item to the tile
   * @param {object} data
   * @returns {Tile}
   */
  add(data) {
    this._items.push({
      data
    });
    return this;
  }

  /**
   * Split this tile into an array of new tiles
   * new tile inherit old attributes with sortWeight being the old value plus
   * the index of its occurance in the original items array
   * Mutations: empties current tile
   * @returns {[]Tile}
   */
  splitItems() {
    const out = this._items.map(item => {
      const tile = new Tile(this.settings);
      tile.add(item.data, []);
      return tile;
    });
    this._items = [];
    return out;
  }

  get fixed() {
    return this.settings.fixed;
  }

  get id() {
    return this.settings.id;
  }

  set id(id) {
    this.settings.id = id;
  }

  get title() {
    return this.settings.title;
  }

  get canExpand() {
    return this.settings.canExpand;
  }

  get width() {
    return this.settings.width;
  }

  get sortWeight() {
    return this.settings.sortWeight;
  }

  get empty() {
    return this._items.length === 0;
  }

  set width(width) {
    if (width > this.settings.maxWidth) return;
    this.settings.width = width;
    if (this.settings.width <= this.settings.maxWidth) {
      this.settings.canExpand = false;
    }
  }

  /**
   * Return an array of data
   * Strips out the decorated attributes
   * @return {[]object}
   */
  get items() {
    return this._items.map(({ data = {} }) => data);
  }
}
