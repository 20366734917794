import { useEffect, useRef } from "react";

/**
 * Delegates click handler to parent scope (default is window.document)
 * Returns a ref from useRef
 * callback (cb) is invoked with second argument indicating whether click
 * was inside or outside the ref
 * @param {function} cb should be a click hanlder
 * @param {element} container click hanlder is delegeated to this element default is window.document
 * @return {React.Ref} a ref meant to be attached to a component root
 */
function useDelegatedClickHandler(cb, container = window.document) {
  const ref = useRef(null);

  useEffect(() => {
    container.addEventListener("click", handleClick);
    return () => {
      container.removeEventListener("click", handleClick);
    };

    /**
     * Decorate cb with second parameter which indicates whether
     * click event is contained in the ref
     * @param {event} event
     */
    function handleClick(event) {
      if (!ref.current) return;
      const contains = ref.current.contains(event.target);
      cb(event, contains);
    }
  }, [ref, cb, container]);

  return ref;
}

export default useDelegatedClickHandler;
