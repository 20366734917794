import React, { useState } from "react";
import styles from "./style.module.css";
import { formatDate, classNames, wait } from "../../lib/helpers";
import useLanguage from "../../hooks/use_language";
import useDelegatedClickHandler from "../../hooks/use_delegated_click_handler";
import Calendar from "react-calendar";

export default function (props) {
  // -- Props
  const {
    fromDate,
    toDate,
    placeholder = "",
    setFromDate = () => { },
    setToDate = () => { },
    customDates = []
  } = props;

  // -- State
  const i18n = useLanguage(),
    [showCalendars, setShowCalendars] = useState(false),
    [showFromCalendar, setShowFromCalendar] = useState(true),
    [showToCalendar, setShowToCalendar] = useState(false);

  // -- Hooks
  const root = useDelegatedClickHandler(handleClick);

  const daysOfWeek = i18n("days_of_week_short").split(","),
    buttonText =
      fromDate || toDate
        ? `${formatDate(fromDate)} - ${formatDate(toDate)}`
        : placeholder,
    calendarProps = {
      formatMonthYear: formatMonthYear,
      formatShortWeekday:
        daysOfWeek.length === 7
          ? formatShortWeekday.bind(this, daysOfWeek)
          : undefined,
      locale: "en-us"
    },
    customDatesRendered = (
      <ul className={styles.customDates}>
        {customDates.map(([label = "", onClick = () => { }]) => {
          const handleClick = () => {
            onClick();
            closeCalendars();
          };
          return (
            <li role="option" className="Dropdown-option" onClick={handleClick}>
              {label}
            </li>
          );
        })}
      </ul>
    );

  return (
    <div
      className={classNames(
        styles.root,
        showCalendars ? styles.showCalendars : ""
      )}
      ref={root}
    >
      <div className={styles.controls}>
        <button
          onClick={() => setShowCalendars(!showCalendars)}
          className={classNames(
            styles.indicator /*value ? styles.active : ''*/
          )}
        >
          {buttonText}
          <i className="material-icons">calendar_today</i>
        </button>
      </div>
      <div className={styles.calendars}>
        {customDatesRendered}
        <div className={styles.tabs}>
          <button
            className={showFromCalendar ? styles.activeTab : ""}
            onClick={() => showCalendar("from")}
          >
            {i18n("explore", "beginning")}
          </button>
          <button
            className={showToCalendar ? styles.activeTab : ""}
            onClick={() => showCalendar("to")}
          >
            {i18n("explore", "end")}
          </button>
          <button className={styles.clear} onClick={clear}>
            <i className="material-icons">clear</i>
          </button>
        </div>
        {showFromCalendar && (
          <Calendar
            {...calendarProps}
            value={fromDate}
            onChange={calendarChangeHandler.bind(
              this,
              setFromDate,
              showCalendar.bind("to")
            )}
            className={styles.calendar}
            maxDate={toDate}
          />
        )}
        {showToCalendar && (
          <Calendar
            {...calendarProps}
            value={toDate}
            onChange={calendarChangeHandler.bind(
              this,
              setToDate,
              closeCalendars
            )}
            className={styles.calendar}
            minDate={fromDate}
          />
        )}
      </div>
    </div>
  );

  function clear() {
    setFromDate(null);
    setToDate(null);
    setShowCalendars(false);
  }

  function showCalendar(calendar = "") {
    if (calendar === "from") {
      setShowFromCalendar(true);
      setShowToCalendar(false);
      return;
    } else {
      setShowFromCalendar(false);
      setShowToCalendar(true);
      return;
    }
  }

  function closeCalendars() {
    showCalendar("from");
    setShowCalendars(false);
  }

  function formatShortWeekday(daysOfWeekStrings, locale, date) {
    return daysOfWeekStrings[date.getDay()];
  }

  function formatMonthYear(locale, date) {
    return `${date.toLocaleString(locale, {
      month: "short"
    })} ${date.getFullYear()} `;
  }

  function handleClick(_, isInRoot) {
    if (isInRoot === false) setShowCalendars(false);
  }

  async function calendarChangeHandler(setter, cb, date) {
    setter(date);
    await wait(300);
    cb();
  }
}
