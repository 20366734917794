import to from "await-to-js";
import { COUNTRIES } from "./constants";
import "unfetch/polyfill";
import queryString from "query-string";

// -- libs
import { API_ROOT as ROOT } from "./constants";
import { SQUIRRO_ROOT } from "./constants"
import { newContentStrategy, newStory } from "./normalize_data";
import { getUnixTimeStamp } from "./date";

const CREDS = {};
// const dateFrom = "1546297200000" // 1/1/2019

const dateFrom = "-1460days"

/**
 * Get trends data and reduce to an object of terms and counts
 * @param {object} opts - additional query params
 * @returns {object}
 */
export async function fetchTrends(opts = {}) {
  const params = {
    "date-from": dateFrom,
    "date-to": "now",
    field: "tag",
    ...opts
  };
  const [error, trends = []] = await fetchSquirroPath(`/trends/`, params);
  return [
    error,
    trends.reduce((acc = {}, { term, count }) => {
      acc[term] = count;
      return acc;
    }, {})
  ];
}

export async function fetchTrendingTerms(opts = {}) {
  const params = {
    "date-from": dateFrom,
    "date-to": "now",
    ...opts
  };
  const [error, trends = []] = await fetchSquirroPath(`/trends/get-trending-terms`, params);
  return [
    error,
    trends.reduce((acc = {}, { term, count }) => {
      acc[term] = count;
      return acc;
    }, {})
  ];
}

export async function fetchTweets(opts = {}) {
  const [error, entries = []] = await fetchPath(
    "/entries",
    Object.assign(
      {
        index: "entries",
        "content-types": [
          "tweet"
          // 'finity-data:post:facebook-object'
          // 'finity-data:post:googleplus-activity'
        ],
        sort: "latest"
      },
      opts
    )
  );
  if (error) return [error, []];
  return [error, entries.map(newContentStrategy).filter(d => !!d)];
}

export async function fetchEntry(id, opts = {}) {
  const [error, entries = []] = await fetchEntries({
      query: `$_id:${id}`,
      limit: 1
    }),
    [entry = {}] = entries;
  return [error, entry];
}

export async function fetchEntries(opts = {}) {
  opts = {
    query: "$link:*",
    ...opts
  };
  const [error, entries = []] = await fetchSquirroPath("/api/entries", opts);
  if (error) return [error, []];
  return [error, entries.items.map(newContentStrategy).filter(d => !!d), entries.count];
}

export async function fetchStoryGroups(opts = {}) {
  const [error, storyGroups = []] = await fetchSquirroPath(
    "/story-groups/",
    Object.assign(
      {
        status: 2
      },
      opts
    )
  );
  return [error, storyGroups.map(newContentStrategy).filter(d => !!d)];
}

export async function fetchStoryGroup(id = "", normalize = d => d) {
  if (!id) {
    return [new Error(`id is required`), {}];
  }
  const [error, storyGroup = {}] = await fetchSquirroPath(`/story-groups/get-story-group-with-id?id=${encodeURIComponent(id)}`);
  return [error, normalize(storyGroup)];
}

export async function fetchDetail(id, opts = {}) {
  if (!id) {
    return [new Error("story-group id is required"), null];
  }
  return await fetchPath(`/story-group/${id}`, opts);
}

export async function fetchStory(id) {
  const [error, story = {}] = await fetchPath(`/story/${id}`);
  const normalStory = newStory(story);
  return [error, normalStory];
}

export async function fetchStories(params = {}) {
  return await fetchSquirroPath(`/stories/`, {
    type: "story",
    sort: "last-modified",
    status: 2,
    ...params
  });
  // return [error, stories.map(newStory)];
}

export async function fetchPath(path = "", opts = {}) {
  const { error, query } = newOptions(opts);
  if (error) {
    return [error, null];
  }
  return await _get(`${path}?${query}`);
}

//Temporary fetchPath() method until Squirro endpoints are resolved
export async function fetchSquirroPath(path = "", opts = {}) {
  let { error, query } = newOptions(opts);
  if (error) {
    return [error, null];
  }

  if (query) {
    query = '?' + query
  }
  return await _squirro_get(`${path}${query}`);
}

/**
 * Create a query string from a hash of options
 * @param {object} options
 * @return {string} a query string
 */
function newOptions(options = {}) {
  let error, query;
  const { andTags = [] } = options;
  delete options.andTags;

  // validate
  error = validateDetailOptions(options);
  query = error
    ? null
    : queryString.stringify(options, {
        arrayFormat: "comma"
      });
  query = appendAndTags(query, andTags);

  return {
    error,
    query
  };

  /**
   * support andTags option.
   * This joins tags with andTags to create a `&`-separated query string
   * neccessary to query two tags where BOTH are present in the item
   * e.g., tags=country-germany&tags=featured will return items where both tags are present
   * @param {string} query
   * @param {[]string} andTags
   * @returns {string}
   */
  function appendAndTags(query = "", andTags = []) {
    return andTags.reduce((acc = "", tag) => {
      return `${query}&tags=${tag}`;
    }, query);
  }

  function validateDetailOptions() {
    let error;
    return error;
  }
}

// Temporary _get() method until Squirro endpoints are resolved
async function _squirro_get(path) {
  // const Authorization = `Basic ${Buffer.from(CREDS.u + ":" + CREDS.p).toString(
  //   "base64"
  // )}`;

  
  const [error, response] = await to(
    fetch(`${SQUIRRO_ROOT}${path}`, {
      // headers: {
      //   origin: "http://localhost:3000",
      //   Authorization
      // }
    })
  );

  if (error || !response.ok) {
    return [
      error ||
        new Error(
          `status: ${response.status}; status text: ${response.statusText}; url: ${ROOT}${path}`
        ),
      null
    ];
  }

  return await to(response.json());
}

async function _get(path) {
  const Authorization = `Basic ${Buffer.from(CREDS.u + ":" + CREDS.p).toString(
    "base64"
  )}`;

  const [error, response] = await to(
    fetch(`${ROOT}${path}`, {
      headers: {
        origin: "http://localhost:3000",
        Authorization
      }
    })
  );

  if (error || !response.ok) {
    return [
      error ||
        new Error(
          `status: ${response.status}; status text: ${response.statusText}; url: ${ROOT}${path}`
        ),
      null
    ];
  }

  return await to(response.json());
}

/**
 * Adapter for translating params for the API
 * @param {object} params
 * @returns {object}
 */
export function buildQuery(options) {
  const {
      countries = [],
      topics = [],
      search = "",
      issues = [],
      fromDate,
      toDate,
      contentTypes = [],
      people = [],
      locations = [],
      organizations = [],
      offset = 0,
      limit = 20,
      fields = [],
      sort = "",
      lang = [],
    } = options;
    // entities = [...people, ...organizations, ...locations];

  // Build tags
  let tags = [
    ...countries.map(countryId => {
      const country = COUNTRIES[countryId];
      const slug = country.slug || null;
      if (!slug) return null;
      return `country-${slug}`;
    }),
    // ...issues,
    // ...contentTypes
  ]
    .filter(p => !!p)
    // .join(",");

  // Build search query
  let query_array = search
    ? [`${search} -content-type:* -tag:static-page`]
    : [`-content-type:* -tag:static-page`];

  if (tags.length > 0) {
    const tags_query = tags[0].split(",").map(tag => `tag:${tag}`);
    query_array = query_array.concat(tags_query);
  }

  if (contentTypes.length > 0) {
    const content_type_query = contentTypes[0].split(",").map(tag => `tag:${tag}`);
    query_array = query_array.concat(content_type_query);
  }

  if (issues.length > 0) {
    const issue_query = issues[0].split(",").map(tag => `tag:issue-${tag.toLowerCase().split(" ").join("-")}`);
    query_array = query_array.concat(issue_query);
  }

  if (topics.length > 0) {
    const topics_query = topics[0].split(",").map(topic => `nlp_tag__topics:"${topic.toLowerCase()}"`);
    query_array = query_array.concat(topics_query);
  }

  if (locations.length > 0) {
    const location_query = locations[0].split(",").map(location => `Location:"${location.toLowerCase()}"`);
    query_array = query_array.concat(location_query);
  }

  if (people.length > 0) {
    const people_query = people[0].split(",").map(person => `Person:"${person.toLowerCase()}"`);
    query_array = query_array.concat(people_query);
  }

  if (organizations.length > 0) {
    const org_query = organizations[0].split(",").map(org => `Organization:"${org.toLowerCase()}"`);
    query_array = query_array.concat(org_query);
  }

  let query = query_array.join(" AND ");

  // nulify falsey w/ undefined
  // i.e., don't pass `tags=` if tags is empty
  tags = tags || undefined;
  query = query || undefined;

  return {
    limit,
    offset,
    fields,
    query,
    tags,
    "date-to": toDate ? getUnixTimeStamp(toDate) : undefined,
    "date-from": fromDate ? getUnixTimeStamp(fromDate) : undefined,
    lang,
    "sort-script-id": "relevancy" ? "relevancy" : undefined,
    sort: sort === "latest" ? "latest" : undefined,
  };
}

export const standardRange = {
  "date-from": dateFrom,
  "date-to": "now",
}