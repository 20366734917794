import React, { useState, useEffect } from "react";
import fadeFast from "../../styles/transitions/fadeFast.module.css";

// -- Libs
import useStoreon from "storeon/react";
import { COUNTRIES } from "../../lib/constants";
import { tileFillColorFromScore } from "../../lib/helpers";
import {
  getCountryIdFromSlug,
  getCountryTagId,
  getSlugFromId,
} from "../../lib/countries";

// -- Components
import Map, { ZoomOutButton } from "../../components/Map";
import MapKeyRange from "../../components/MapKeyRange";
import Link from "../../components/Link";
import Hotspot from "../../components/Hotspot";
import HotspotScorecard from "../../components/HotspotScorecard";

// -- Hooks
import useLanguage from "../../hooks/use_language";

const positiveNegativeColorRange = [
  tileFillColorFromScore(0),
  tileFillColorFromScore(2.5),
  tileFillColorFromScore(5),
  tileFillColorFromScore(7.5),
  tileFillColorFromScore(10),
];

function RelationshipsMap(props = {}) {
  const {
    countries = [],
    onTileClick = () => {},
    className = "",
    setHoverCountry = () => {},
    hoverCountry,
  } = props;

  const i18n = useLanguage();

  // -- State
  const {
      relationships = {},
      tags = {},
      statusHistory,
      dispatch,
    } = useStoreon("relationships", "tags", "statusHistory"),
    [hoverEl, setHoverEl] = useState(null);

  const { data = [] } = relationships;
  const { data: statusHistoryData = [] } = statusHistory;

  useEffect(() => {
    dispatch("relationships/fetch");
  }, []);

  useEffect(() => {
    if (!hoverCountry) return;
    setHoverEl(document.querySelector(`#${hoverCountry}`));
  }, [hoverCountry, hoverEl]);

  const scores = Object.keys(statusHistoryData).reduce((acc, key) => {
    // prevent unpublished locations from rendering score on map

    if (!isPublished(getCountryIdFromSlug(key))) {
      return acc;
    }
    // TODO: For some reason I don't have time to investigate, the score can be either sorted by date in ascending or descending order
    // depending on whether you are on a country page or not
    const latestScorePosition = countries[0] ? -1 : 0;
    acc[key.split("country-")[1]] =
      statusHistoryData[key].at(latestScorePosition).value;
    return acc;
  }, {});

  const [country] = countries;

  return (
    <>
      <Map
        countries={countries}
        tileClickHandler={tileClickHandler}
        tileFill={tileMapFill}
        geographyActiveFill={tileMapFill(country, true) || "rgba(20,10,0,0.25)"}
        onMouseEnter={mouseEnteredTile}
        onMouseLeave={mouseLeftTile}
        className={className}
      >
        {country && (
          <Link to={"/"} title="back to grid">
            <ZoomOutButton />
          </Link>
        )}
        <MapKeyRange
          leftLabel={i18n("map_keys", "negative")}
          rightLabel={i18n("map_keys", "positive")}
          colors={positiveNegativeColorRange}
        />
      </Map>
      <Hotspot
        target={hoverEl}
        timeout={750}
        transitionClassNames={fadeFast}
        topOffset={-50}
      >
        {hoverCountry && (
          <HotspotScorecard
            name={COUNTRIES[hoverCountry].name}
            code={COUNTRIES[hoverCountry].code}
            score={getScore(hoverCountry)}
            id={hoverCountry}
          />
        )}
      </Hotspot>
    </>
  );

  /**
   * Get the country score given an id
   * @param {string} id
   * @return {object}
   */
  function getScore(id = "") {
    return scores[getSlugFromId(id)];
  }

  /**
   * Fill function for map tiles
   * @param {string} countryId
   * @param {boolean} inEu - is country in EU?
   */
  function tileMapFill(countryId = "", inEu = false) {
    // exception for UK TODO
    if (!COUNTRIES[countryId] || (!inEu && countryId !== "GB")) {
      return false;
    }
    const score = getScore(countryId);

    if (score == null) {
      return "white";
    }

    return tileFillColorFromScore(score);
  }

  /**
   * Handle mouse enter on a tile
   * @param {string} countryId
   * @param {HTMLEvent} event
   * @param {HTMLElement} target
   */
  function mouseEnteredTile(countryId, event = {}) {
    const { target } = event;
    // Ignore mouseenter events when the map is transitioning to the zoomed-in state
    if (country && country !== countryId) return false;
    if (!COUNTRIES[countryId]) {
      return false;
    }
    setHoverEl(target);
    setHoverCountry(countryId);
  }

  /**
   * Handle mouse leave on
   */
  function mouseLeftTile() {
    setHoverCountry("");
    setHoverEl(null);
  }

  /**
   * Checks if Story group is published
   * @param {string} countryId
   * @returns {boolean}
   */
  function isPublished(countryId) {
    const countrySlug = getSlugFromId(countryId);

    // TODO fix this later
    if (countryId === "GB") return true;

    return data.some((country) => {
      return country.keywords.tag[0] === `country-${countrySlug}`;
    });
  }

  /**
   * Click handler for map tile
   * Navigates to that country's relationship page
   * @param {string} id - a country ID
   */
  function tileClickHandler(id) {
    setHoverCountry("");
    if (!isPublished(id, tags)) return;
    onTileClick(id);
  }
}

export default RelationshipsMap;
