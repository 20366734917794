import React from 'react';
import { INFOGRAPHIC_TAG, VIDEO_TAG, AUDIO_TAG } from '../lib/constants';
import Icon from './Icons';

export default React.memo(function(props) {
  const { tagName = '', className = '' } = props;

  return getMediaTypeIcon();

  function getMediaTypeIcon() {
    switch (tagName) {
      case INFOGRAPHIC_TAG:
        return (
          <Icon className={className} name="infographic" title="Infographic" />
        );
      case VIDEO_TAG:
        return <Icon className={className} name="video" title="Video" />;
      case AUDIO_TAG:
        return <Icon className={className} name="audio" title="Audio" />;
      default:
        return '';
    }
  }
});
