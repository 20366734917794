import { fetchTrends, fetchTrendingTerms } from "../lib/api";

export default store => {
  store.on("@init", () => ({
    trends: {
      data: [],
      error: null,
      pending: true
    }
  }));

  store.on("trends/fetch", fetch);

  store.on("trends/save", (_, trends = {}) => {
    return { trends };
  });

  store.on("trends/pending", ({ trends }) => {
    return {
      ...trends,
      pending: true
    };
  });

  /**
   * Fetch trends
   * @param {object} trends
   * @param {number} limit
   * @fires [trends/pending]
   * @fires [trends/save]
   */
  async function fetch({ trends = {} }, limit = 5) {
    const { data = [] } = trends;
    if (data.length) return;
    store.dispatch("trends/pending");
    const d = new Date();
    const [error, fetched = []] = await fetchTrendingTerms({
      'date-from': '-30days',
      limit,
      filter: "language:en",
    });
    store.dispatch("trends/save", {
      data: fetched,
      error
    });
  }
};
