import React, { useEffect, useMemo } from "react";

// -- Libs
import { ACTIVITY_RANGE_COLORS } from "../../lib/constants";
import { getMaxCount } from "../../lib/helpers";

// -- Modules
import Map, { ZoomOutButton } from "../../components/Map";
import MapKeyRange from "../../components/MapKeyRange";

// -- Hooks
import useLanguage from "../../hooks/use_language";
import useStore from "storeon/react";

const SearchMap = (props = {}) => {
  const {
    countries = [],
    onTileClick = () => { },
    zoomOut = () => { },
    className = ""
  } = props;

  // -- Hooks
  const i18n = useLanguage(),
    { articleCounts = {}, dispatch } = useStore("articleCounts");

  const { data = {} } = articleCounts,
    [country] = countries;

  useEffect(() => {
    dispatch("articleCounts/fetch");
  }, []);

  const maxCount = useMemo(() => {
    return getMaxCount(data);
  }, [Object.keys(data).length]);

  return (
    <Map
      countries={countries}
      tileFill={tileFill}
      textFill={textFill}
      tileClickHandler={onTileClick}
      geographyActiveFill={tileFill(country, true)}
      className={className}
    >
      {country && (
        <button onClick={zoomOut}>
          <ZoomOutButton />
        </button>
      )}
      <MapKeyRange
        leftLabel={i18n("map_keys", "less_active")}
        rightLabel={i18n("map_keys", "more_active")}
        colors={ACTIVITY_RANGE_COLORS}
      />
    </Map>
  );

  /**
   * Set the fill on the map
   * More instense colors indicate higher a higher article count associate w/ the country
   * @param {string} id - country id
   * @param {boolean} inEu - whether country is in EU
   * @returns {string} color
   */
  function tileFill(id, inEu) {
    const count = data[id];
    if (!inEu || typeof count !== "number") return false;
    const weightMin = 15,
      max = data ? maxCount : 0,
      weight = count === 0 ? 0 : (100 - weightMin) * (count / max) + weightMin;

    return `hsl(196, 88%, ${100 - weight * 0.55}%)`;
  }

  function textFill(_, isEU) {
    return isEU ? "black" : "rgba(0,0,0,0.25)";
  }
};

export default SearchMap;
