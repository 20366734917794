import * as d3 from "d3";
import styles from "./style.module.css";

function Axes(svg, { height = 0, width = 0, margin = {}, xScale, yScale }) {
  let xAxis, ticks;
  function appendHorizontalTicks(svg) {
    const horizontalTicks = d3
      .axisLeft(yScale)
      .tickSize(-width)
      .tickValues([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10])
      .tickFormat("");

    // append horizontal Ticks
    svg
      .append("g")
      .attr("transform", `translate(0, 0)`)
      .attr("class", styles.horizontalTicks)
      .call(horizontalTicks);
  }

  /**
   * Append the x axis
   * @param {d3.Selector} svg
   * @param {object} margin
   * @param {number} height
   * @param {number} width
   * @param {d3.Scale} yScale
   * @return {undefined}
   */
  function appendYAxis() {
    const leftAxisLabel = d3
      .axisLeft(yScale)
      .tickSize(0)
      .tickPadding(15)
      .tickValues([0, 5, 10]);

    // left mask
    svg
      .append("rect")
      .attr("x", -margin.left)
      .attr("y", -margin.top)
      .attr("width", margin.left)
      .attr("height", height + margin.top + margin.bottom)
      .attr("class", styles.leftMask);

    // append leftAxisLabel
    svg
      .append("g")
      .attr("transform", `translate(0, 0)`)
      .attr("class", styles.leftAxisLabel)
      .call(leftAxisLabel);

    // style left border
    svg
      .append("line")
      .attr("x0", 0)
      .attr("x1", 0)
      .attr("y0", 0)
      .attr("y1", height + margin.bottom)
      .attr("class", styles.leftBorder);
  }

  function appendXAxis(format = "%b %y") {
    xAxis = d3
      .axisBottom(xScale)
      .ticks(4)
      .tickSize(-height)
      .tickPadding(15)
      .tickFormat(d3.timeFormat(format));

    // bottom margin area
    svg
      .append("rect")
      .attr("x", 0)
      .attr("y", height)
      .attr("width", width)
      .attr("height", margin.bottom)
      .attr("class", styles.bottomArea);

    // append vertical Ticks
    ticks = svg
      .append("g")
      .attr("transform", `translate(0, ${height})`)
      .attr("class", styles.verticalTicks)
      .call(xAxis);
  }

  function rescaleX(newScale) {
    xAxis.scale(newScale);
    ticks.transition().call(xAxis);
  }

  return {
    appendHorizontalTicks,
    appendYAxis,
    appendXAxis,
    rescaleX,
    get x() { return xAxis },
    ticks
  };
}

/**
 * Get the tick format for date ticks
 * @param {number} n index of current zoom level
 * @return {function}
 */
const tickFormat = (n = 0) => {

  const defaultTickFormat = d3.timeFormat("%b %Y")
  const tickFormats = [
    d3.timeFormat("%d %b"),
    d3.timeFormat("%d %b"),
    d3.timeFormat("%d %b")
  ]
  return tickFormats[n] || defaultTickFormat
}

export { Axes, tickFormat };
