import React from "react";
import styles from "./style.module.css";
import useLanguage from "../../hooks/use_language";
import { classNames } from "../../lib/helpers";
import { formatDate } from "../../lib/helpers";

function Credit(props = {}) {
  const { link = "https://www.bfna.org", lastModified, foundation = "", className = "" } = props;

  const i18n = useLanguage();

  const text = foundation || i18n("foundation");

  return (
    <div className={classNames(styles.credit, className)}>
      <p>
        <span>{i18n("powered_by")}: </span>
        {link ? (
          <a href={link} target="_blank" rel="noreferrer noopener">
            {text}
            <i className="material-icons">launch</i>
          </a>
        ) : (
          text
        )}
      </p>
        {lastModified && <p>
            <span>{i18n("last_updated")}: </span> <span className={styles.lastModified}>{formatDate(new Date(lastModified))}</span>
      </p>}
    </div>
  );
}

export default Credit;
