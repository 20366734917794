import React from 'react';
import styles from './style.module.css';

export default function(props) {
  const {
    provider = '',
    providerIcon = '',
    date = '',
    mediaTypeIcon = ''
  } = props;

  return (
    <div className={styles.source}>
      {provider && (
        <h5>
          {mediaTypeIcon}
          {providerIcon && <img src={providerIcon} alt={provider} />}
          <span>{provider}</span>
        </h5>
      )}
      {date && <h5>{date}</h5>}
    </div>
  );
}
