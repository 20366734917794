import React, { useState } from 'react';
import styles from './style.module.css';
import { classNames } from '../../lib/helpers';

export default function(props) {
  const { className = '', render = () => {}, slides = [] } = props;

  const [current, setCurrent] = useState(0);

  const size = slides.length;

  const content = (
    <div
      className={styles.carousel}
      style={{
        width: `${size * 100}%`,
        transform: `translateX(-${(current / size) * 100}%)`
      }}
    >
      {slides}
    </div>
  );

  // -- Render
  return (
    <div className={classNames(className, styles.root)}>
      {render(content, {
        current,
        size,
        goTo
      })}
    </div>
  );

  function goTo(slide = 0) {
    setCurrent(slide);
  }
}
