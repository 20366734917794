import React, { useEffect, useRef, useState, useMemo } from "react";
import styles from "./style.module.css";
import statusChart from "../../lib/d3/relationship-single";
import { tileFillColorFromScore, minMaxProperty } from "../../lib/helpers";
import Hotspot from "../Hotspot";
import ZoomButtons from "../ZoomButtons";

const settings = {
  dotSize: 6,
  strokeWidth: 4,
};

const StatusChart = (props) => {
  const {
      sentimentHistory = [],
      events = [],
      height = 400,
      width = 400,
      eventFocusEvent = "",
      minDate,
      maxDate,
      expandScope = () => {},
      canExpandScope = false,
      contractScope = () => {},
      canContractScope = false,
      zoomLevel = 1,
      focusedEvent = null,
      // pad/max date in chart by 2 days
      padding = 0,
      step = 1,
    } = props,
    initialLimit = new Date(),
    showControls = canExpandScope || canContractScope;

  initialLimit.setMonth(initialLimit.getMonth() - 6);

  const container = useRef(null),
    chart = useRef(null),
    [minValue, maxValue] = minMaxProperty(sentimentHistory, "value");

  // -- State
  const [hoverEl, setHoverEl] = useState(null),
    [hotSpotScore, setHotSpotScore] = useState(null);

  // -- Initial draw
  useEffect(() => {
    chart.current = statusChart(container.current, width, height);
    const { dotSize, strokeWidth } = settings;
    const lineStroke =
      maxValue - minValue > 0
        ? "url(#linear)"
        : tileFillColorFromScore(maxValue);
    chart.current.draw(
      sentimentHistory.sort((a, b) => a.date.getTime() - b.date.getTime()),
      events,
      {
        lineStroke,
        dotFill: tileFillColorFromScore,
        minDate,
        maxDate,
        eventFocusEvent,
        strokeWidth,
        dotSize,
        dotMouseEnter,
        dotMouseLeave,
        padding,
        styles,
      }
    );
  }, []);

  // -- Rescale the data
  useMemo(() => {
    if (!chart.current) return;
    redraw();
  }, [minDate, maxDate, chart.current, events]);

  // -- Focus event
  // highlight event dot when focusedEvent is triggered
  useEffect(() => {
    if (!chart.current) return;
    chart.current.highlight(focusedEvent);
  }, [focusedEvent, chart.current]);

  return (
    <div className={styles.root}>
      <svg ref={container}>
        <defs>
          <linearGradient id="linear" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor={tileFillColorFromScore(maxValue)} />
            <stop
              offset="50%"
              stopColor={tileFillColorFromScore((maxValue + minValue) / 2)}
            />
            <stop offset="100%" stopColor={tileFillColorFromScore(minValue)} />
          </linearGradient>
        </defs>
      </svg>
      {showControls && (
        <ZoomButtons
          canZoomOut={canExpandScope}
          zoomOut={expandScope}
          canZoomIn={canContractScope}
          zoomIn={contractScope}
        />
      )}
      <Hotspot
        target={hoverEl}
        timeout={500}
        className={styles.hotSpot}
        topOffset={-20}
      >
        {hotSpotScore && (
          <div
            style={{ background: tileFillColorFromScore(hotSpotScore) }}
            className={styles.hotspotScore}
          >
            {hotSpotScore.toFixed(1)}
          </div>
        )}
      </Hotspot>
    </div>
  );

  /**
   * Rescale svg given new min/max dates
   */
  function redraw() {
    const { dotSize, strokeWidth } = settings;
    chart.current.redraw({
      minDate,
      maxDate,
      dotSize: dotSize * zoomLevel,
      strokeWidth: Math.max(strokeWidth * zoomLevel, 2),
      zoomLevel,
      padding,
      step,
    });
  }

  /**
   * Handle mouseenter event on a status value dot
   * @param {HTMLElement} el
   * @param {number} score
   */
  function dotMouseEnter(el, score) {
    setHoverEl(el);
    setHotSpotScore(score);
  }

  /**
   * Handle mouseleave event
   */
  function dotMouseLeave() {
    setHoverEl(null);
    setHotSpotScore(null);
  }
};

export default StatusChart;
