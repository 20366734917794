import React, { useState } from "react";
import ReactTags from "react-tag-autocomplete";
import { debounce } from "lodash";

const debounceTimer = 350;

export default function(props) {
  const {
    suggestions: suggestionsInitial = [],
    value = [],
    onChange = () => {},
    fetchSuggestions
  } = props;
  const [suggestions, setSuggestions = []] = useState(suggestionsInitial);

  const handleInputChangeDebounced = fetchSuggestions
    ? debounce(handleInputChange, debounceTimer)
    : () => {};

  return (
    <React.Fragment>
      <ReactTags
        tags={value}
        suggestions={suggestions}
        handleDelete={handleDelete}
        handleAddition={handleAddition}
        handleInputChange={handleInputChangeDebounced}
        {...props}
      />
    </React.Fragment>
  );

  /**
   * Add tag sets all tags as unique values
   * Calls props.onChange with new state
   * @param {object} tag
   */
  function handleAddition(tag = {}) {
    onChange([...new Set(value.concat(tag).map(tagToString))]);
  }

  /**
   * Removes tag by index
   * Calls props.onChange with new state
   * @param {number} idx
   */
  function handleDelete(idx) {
    onChange(
      value
        .slice(0)
        .filter((_, i) => idx !== i)
        .map(tagToString)
    );
  }

  /**
   * Handle change to input text
   * Calls fetchSuggestions if provided
   * @param {string} text
   */
  async function handleInputChange(text = "") {
    if (!fetchSuggestions) return;
    if (text.length < 2) return;
    const suggs = await fetchSuggestions(text);
    setSuggestions(suggs.map(stringToTag));
  }
}

/**
 * Adapter that turns simple string-based tags into object
 * consummable by react-tag-autocomplete
 * @param {string} str
 * @return {object}
 */
function stringToTag(str = "") {
  return {
    id: str,
    name: str
  };
}

/**
 * Adapter for react-tag-autocomplete to plain string
 * @param {object} obj
 * @param {object} obj.id
 * @return {string}
 */
function tagToString({ id = "" }) {
  return id;
}
