import React from 'react';
import styles from './style.module.css';
import { classNames } from '../../lib/helpers';
import { CSSTransition } from 'react-transition-group';
import useDisableScroll from '../../hooks/use_disable_scroll'

export default function (props) {
  const {
    children = [],
    headline = '',
    className = '',
    close = () => { },
    style = {}
  } = props;

  // Prevent body from scrolling when modal is open
  useDisableScroll(!!children)

  // Using global classes shade/modal to assist with transitions
  return (
    <CSSTransition
      in={!!children}
      timeout={500}
      classNames="modal"
      unmountOnExit
    >
      <div className={classNames(styles.root, className)}>
        <div className={classNames(styles.shade, 'shade')} onClick={close} />
        <aside style={style} className={classNames(styles.modal, 'modal')}>
          <div className={styles.top}>
            {headline && headline}
            <button className={styles.closeBtn} onClick={close}>
              &times;
            </button>
          </div>
          <div className={styles.body}>{children}</div>
        </aside>
      </div>
    </CSSTransition>
  );
}
