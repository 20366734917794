import {useEffect, useState} from "react";
import {fetchStoryGroup} from "../lib/api";

const useGetStoryGroup = (id, normalizer, initial={}) => {
    const [storyGroup = {}, setStoryGroup] = useState(initial)
    useEffect(() => {
        if (!id) return;
        (async () => {
            const [error, storyGroup = {}] = await fetchStoryGroup(id, normalizer);
            if (error) {
                console.error(error);
            }
            setStoryGroup(storyGroup);
        })();
    }, [id]);

    return storyGroup
}

export default useGetStoryGroup
