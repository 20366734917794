import React from "react";
import { root, img } from "./style.module.css";
import useTheme from "../../hooks/use_theme";

export default function (props) {
  useTheme("dark");

  return (
    <div className={root}>
      <div className="constrain">
        <h1>We can't find the page you are looking for</h1>
      </div>
      <div className={img} />
    </div>
  );
}
