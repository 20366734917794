import React from 'react';

export default React.memo(function(props, ref) {
  const {
    highlight = '',
    highlightColor = 'red',
    defaultColor = '#000',
    className = ''
  } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1201 1201"
      className={className}
      id="islands"
    >
      <path
        fill={applyFill('TUR')}
        d="M921.81,1004.6l-3.66,1.14-1.76-.66.61-2,4.41-.75Zm69.3-54.53-10.41.44-14.37-8.77-5.16-7.85-8.42,5.2-9.34-2.82-13.27,8-6.3,8.56,6.92,2.73.61,5.86L926,965.23l1.72,8.64-3.05,9.83,3,5h7.67l8.07-4.18.62,2.38-5.21,3.44-8.68,10.09,2.6,5.33,2.69-3.92,7.71-11.37,9.44-8.77,4.14-12,23.85-10,5.51.88,5.47-3.13Z"
      />
      <path
        fill={applyFill('SE')}
        d="M630.6,485l-.75,12.48-3.53,14.24-2.91,10.09-2.6-3.13,2.91-14.55,3.22-9.34,1.68-8.77Zm29.18-17.1,4-5.47-7.8-.31-7.63,12.48.75,11.59,2.47,3.4.44,4,3.22-1.19,2.29-7.14,3.83-6.35-1.1-5.07Z"
      />
      <path
        fill={applyFill('RUS')}
        d="M755.88,560.73l-12.3,6.92-20.19,4.1L696.46,573l7.71-11.24-6.48,1.1-.22-4.94-.79-4.32,5.2-.39,5.42-3.93,4.06.62,6.65.22,1.86-2.6-1.63-3.79.74-6,10.19,3.44,7.32.88,10.09-.62,5.95,5.2-1.67,7.14Z"
      />
      <path
        fill={applyFill('NL')}
        d="M384,690.11l-4.76.93L375,689.19l-7.23.53,2.74,3.88,10.71,2.95,8.9-1.37Z"
      />
      <path fill={applyFill('MLT')} d="M643.52,1179.12l.92,2.78,2.21-1.06Z" />
      <path
        fill={applyFill('IT')}
        d="M666.09,1112.56l4.45-2.38-1.54,6.08-8.51,23.19,3.88,5.24,3.26,8-4.89,11.77-14.16-.31-9.08-10.31-11.33-1.68-25.3-11.94-9.17-1.28-4.32-5.64,2.6-11.07,4.19-1.45,3.35,2.64,3.57-.13,2.82-3.44,4.85-2.42,10.8,6.61,9.44-2.21,11-.22,9-6,8.64.84,2.9-3.35Zm-155-78.29-10.4-11-20.46,14.24-3.7-3.84-4,7.41,10.72,16-1.24,6.53.93,5.64-1.55,6-.3,20.19,5.81,3.57,9.48,1.19.31-10.4,13.14,2.38,4.89-27.64-1.85-9.21,3.08-7.1Z"
      />
      <path
        fill={applyFill('HR')}
        d="M625,903.91l-2.07,1.19-3-5.64-.36-5.2-2-3,.08-2.25,1.37,0,2.69,4.54.53,5.12Zm14,7.76-4.59-2.95-.26,1.41,1.15,1.19,3.43,2.47.27,1.45,2,1.15.7-2.56Zm3.61,13.8-6.65-6h-1.46l-.39.93,5.46,5.55.66,1.94h2.47Zm41.44,21.82-1.85-.7-6.57-.14-.31,1.5,3.53.58,4.58,1.45,5.12-.93,1.5-.92ZM721,957.69l-11.15-5.59L705,953.46l-1.85.88-5.95-2.77-5.78-1.06L691,952l3.3,1,5.38,1.58,8.33,3.49.27-2.47,2.25-.05,5.81,3.31,1.24,2,3,.67,5,4.54.48-4.06Z"
      />
      <path
        fill={applyFill('GR')}
        d="M992.65,1149.59l-3.09,3.13-1-3.58-1.46-2.51,1.37-7.05,2.47-.71-.75,6.84Zm17.85-37.43s-7.67,6.17-8.11,6.7l-2.69,5.47,1.41,2.33.75,3.75,2.47,1.37,5.86-7.23,1.94-9.26ZM913.3,997.1l-1.89,2,1.89.57,3.09-1.67Zm-19.53.4-4,1.46-3.57-1.59.84-2.73,2.16-1.46,3.18.4Zm20,19.4,1.27,4.32-2.24,1.45-2.61-3-2.82.53-3.35-.84.75-3.26,6.7-.84,1.11-2.29,2.46.18ZM948,1038.14,945.7,1037l2,2.56-2.78,2.29-6.57-1.72,2.3-3.92-2.52-1.11-1.23,3.93-4-.53-1.54-1.85a28.35,28.35,0,0,1,1.41-2.52l3.44-1.36,1.89-3.84,4.68,1.1.3,1.77,7.41,6Zm23.1,39.9-4.54,4.72-2.6-1.5-2.91.13-1.19-1.28,3.48-2.91,4.72.31,2.82-1.81Zm-33.59-8.82,1.28-5.68-4.32-5.91,1.94-2.07,6.26,1.67.22,12.61Zm1.06,42-1.5,2.56-3-1.5v-6.92h3.7ZM814.38,1110l-2.16,1.36-2,2.51-5.16-5.37,1-3.13,2.82,2.73Zm-36.1-54.18-.53-2.87-1.11-2.2-.52-3.79-5.65,2.11.66,3,4.24,2.51,2.91,4.93,3.21.75,2.16-.61Zm25.17,38-3.27-5-1,4.06-3,.13-.31,5.25,2.24-.75,1.55,2.51h7l1.5-3Zm100.42-15.39-5.29,1.19-4-4-.53-5.64-2.6-4.85-5.51.4-11.77-2.25-2.73-5.29-6.31-1.19-7.76,7.54,1.5,1,2.47-1.23,4.94,0,5.07,3.35,6.79,2.91,1,2.73,1.19,1.68,9.35-1.46,2.69,5.34,7.4,5.6,6-.31Zm65.33,83.23-1.81,3.26-6.7.62-3.26,4.32-1.81-.13-2.73-5.78-3.57,2-13,1.58-3.44-1.36-16.09,5.68-2.52-2.51-10-1.5-4.32-2.73-1.19,4.32-2.16-.84-1.41,10.4,14.07-1.27L919,1176l10.89,2.21,1.1,4.1L963.6,1173l2.6-2.21,1.81.84,3.26.35.66-10.71Zm-93.77-31.08-12.56-17.85-.84-5.91,9.7,2.73.44,4,10.8-6.09-4.32-5.07-2.38,3-3.22-1.68-2.38-6.08-3.48-2.12,1.94-2.73-4.1-.22-3.44,2.73-5.64-2.91-11-2.55-8.42-3.57-8.42,7.36-5.2.83-1.28,6.31-3.26,3.57,11.9,7.14L835,1121.9l-1.32,5.25,3.48,4.23,1.06,4.19,6.48,1.89-.18-6.92L848,1128l1.5,3.79,7.36,4-.44,6.7,4.32,4,.88-10.93,4.32-3.13,6.26,7.14,6.26,2.07-1.94-3.35Z"
      />
      <path
        fill={applyFill('GB')}
        d="M224.55,576v3.4l-6.3,6-3.66-.75.18-2.65,5.51-5.64Zm85.26-194-3.88,1.9v6.92h-3.26l.22,2.51,3.88,2.34-1.94,8.59,2.16,1.46,4.54-10.28,1.1-5.06Zm-31.12,50-6-6.3-4.1-.45-1.76,4.41,1.1,4.23,3.66-2.33,2.6,1.28-.22,4.18Zm-65.16,13.27,1.68-1.63-.62-7.28-9.17,5.51-2.29,3-5-2.07-1,9.78-1.55,3.88,3.84,2.21,2.73-2.52,2.29-2.64,5,.88,2-6.39,4.15-2.07Zm-8.11,56.34-1.63-4.1-3.66,3.35,3,1.5-.84,1.89,2.16.71-5.07,1.89-.44,2,5.95-.49,5.51-1.28,1.94-2.2Zm-4.54,16.26-1.72,2.65-.44-.62L194,521.06l-2.38,5.24,3.79-.75-1.63,5.25,6.08-3,1.06-6,5.51-6.08Zm-13.62-51.22-3.66,3.75,2.29,4.27.14,12.21,5-1.94v-15.6Zm26.8,14.9-.66-4.41-4.72-4.41,1.28-5-3.48-2.52-1.5,4.19-4.54-1.28-2.16,2.34,3.88,2.91.22,2.11,7.36,5.69-1.06,4.19Zm-12.52,80-4.1-.84,2.56-3.35-4.72-14.06-6.3-2.74L179.9,542l-5.38,2.12-5.42,3.13-3,5.46-3.92.44H159.4l2.16,4.41-8.46,2.91,3.48,8.2,8.42,4.23,4.1.62,1.1-5.69,3.44-2.07,5.42,9,.22,3.4,5.82,1.06,5.82,1.23,3-4.41,5.64-.62,4.32-5.68Z"
      />
      <path
        fill={applyFill('FR')}
        d="M505.58,966.25l-4,.88-.61,8-4.9-.88-11.59,9.79.62,15.73,3,1.19-1.24,5.65,2.47,1.8-.92,4.15,8.55,5.33,5.82-2.64,2.73-15.74,2.16-13.36Z"
      />
      <path
        fill={defaultColor}
        d="M670.63,389.16l-5-1.19-4.28-1.81.14-2.64-1.37.31,2.73-6.84,7.19,3.53-4.28,2.38v2.56l3.84-.44Z"
      />
      <path
        fill={defaultColor}
        d="M723.53,417.07l-2.47,2.68-1.5,4.77L715,419.89l-5.68-.89-1.37-1.19,3.84-1.32,3-.31,3.21-4.32,2.3,2.69Zm6.86,8.17L729,428.77l-1.77-1.47h-5.63l-4.82,2.42-3.82,1.54-1,2-4.2.16,1.87,4.81-.33,2.79,5.54,1.8v3.12l-2.39,5.15,4.65.2,1-7,2.34-3.56,3.34-2.51,4.92-5.38,2.17-2.38,3.78-.37-4.16-4.84Z"
      />
      <path
        fill={applyFill('ES')}
        d="M374.7,1067.33l-4.36,1.5-3.71-3.13-5.24-2.56,1.54-3.62,7.1-.13Zm-76.26,18.6-7.45,6.48,3.57,2.82,8.64-6.74Zm45.84-18.29-1.72-6-20.05,11.24,4.76,4.5,4-1.32,10.23,8.55,9.25-15.3Z"
      />
      <path
        fill={applyFill('DK')}
        d="M604.72,562.41,602,565.89,596.3,565l-2.51-3.48,1.76-5.69,5.38,3.71Zm-59,12.56-1.59-1.5-6-.18-.18,1.77,5.2,8.11-.08-3.57Zm-5.47,5.77-4.18.44-2.69,1.33-5.87-1.5-2.68-2.82,1.27-.88-.88-3.13,3.35-2.52,4.06,4.23,1.8,1.42,2.3-1.42Zm-20.58-17.63-2.82,6.35-5.16.75-8.86-4.81-1.68-5.46.09-4.15L509.2,552l6.56,1,2.78,5.29Zm28.7-7.27,3.26,2.25-1,2.73-6.44,2.69,1.54,6.61-4.49,1.76-4.37-6.43-9.78-3.53L526,556.23l-4-6.92-.53-.88,5.24-1.19,3.75-3.48.26-3.88,4.72.18.4,4.36,2.6,3.79,2.07-8.68-.57-2.52,7.27-2.82,3.44,2.07.84,6.31,1.23,4-5.82,5.2ZM231.74,324.71,228,325l-1.19-5.65-4-4.14,1.54-1.76,6.13-1.5L229.27,317Z"
      />
      <path
        fill={applyFill('DE')}
        d="M593.39,604.33l-2.77,1.72-5.29-.36-1.63-3,2.29-1,1.85.26-2.65-3.13L580,596.92l6.52.58ZM575.8,585.86l.8-3.75L573,581.05l-1.76-2.47-4,2.87.09,4.8L564.74,588l6,4.72,3-3,5.95-.09Z"
      />
    </svg>
  );

  function applyFill(countryCode = '') {
    return highlight === countryCode ? highlightColor : defaultColor;
  }
});
