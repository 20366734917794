import { getUnixTimeStamp } from '../lib/date'

export default store => {
    store.on("@init", () => ({
        lastUpdated: {}
    }));

    store.on("lastUpdated/update", ({ lastUpdated }, {id, date}) => {
        if (date < lastUpdated[id]) {
            return { lastUpdated }
        }
        lastUpdated[id] = getUnixTimeStamp(date)
        return { lastUpdated }
    });
};
