import createStore from "storeon";
import persistState from "@storeon/localstorage";
import relationships from "./relationships";
import articleCounts from "./articleCounts";
import entriesTotal from "./entriesTotal";
import trends from "./trends";
import deepdives from "./deepdives";
import tags from "./tags";
import staticPages from "./staticPages";
import statusHistory from "./statusHistory";
import lastUpdated from "./lastUpdated";
import i18n from "./i18n";
import settings from "./settings";

export default createStore([
  data,
  relationships,
  articleCounts,
  entriesTotal,
  trends,
  deepdives,
  tags,
  staticPages,
  settings,
  statusHistory,
  i18n,
  lastUpdated,
  process.env.NODE_ENV !== "production" && require("storeon/devtools"),
  persistState(["settings"]),
]);

function data(store) {
  store.on("@init", () => {
    /**
     * Initial state
     */
    return {
      loadingState: "loading",
      isScrollPositionTop: true,
      lang: "en",
      theme: "light",
    };
  });

  store.on("setIsScrollPositionTop", (_, isScrollPositionTop = false) => {
    return {
      isScrollPositionTop,
    };
  });

  store.on("updateLoadingState", (_, loadingState = "loading") => {
    return {
      loadingState,
    };
  });

  store.on("setTheme", (_, theme = "light") => {
    return {
      theme,
    };
  });
}
