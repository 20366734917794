import { fetchStoryGroups } from "../lib/api";

export default store => {
  store.on("@init", () => ({
    relationships: {
      data: [],
      error: null,
      pending: false,
      fetched: false
    }
  }));

  store.on("relationships/pending", ({ relationships }) => {
    return {
      ...relationships,
      pending: true
    };
  });

  store.on("relationships/fetch", async ({ relationships = {} }) => {
    const { pending = false, fetched = false } = relationships;
    if (pending || fetched) return;

    const [error, storyGroups] = await fetchStoryGroups({
      types: ["country"],
      limit: 100,
      // fields: ["meta", "id"]
    });

    store.dispatch("relationships/save", {
      data: storyGroups,
      error,
      fetched: true,
      pending: false
    });
  });

  store.on("relationships/save", (_, relationships = {}) => {
    return { relationships };
  });
};
