import React, { useState, useEffect } from "react";
import styles from "./style.module.css";
import { classNames } from "../../lib/helpers";
import useDebounce from "../../hooks/use_debounce";

const magnifyingGlass = (
  <i className={classNames("material-icons", styles.magnifyingGlass)}>search</i>
);

// can pass as props
export default function(props) {
  const {
      className = "",
      submit = () => {},
      onChange = () => {},
      value = "",
      placeholder = "",
      name = "",
      onClear = () => {},
      datalist = [],
      submitSlot = magnifyingGlass,
      variant = ""
    } = props,
    [text, setText] = useState(value),
    [suggestions, setSuggestions] = useState(datalist);

  useDebounce(
    () => {
      if (!isDirty) return;
      (async () => {
        const suggs = await onChange(text);
        setSuggestions(suggs || []);
      })();
    },
    500,
    [text]
  );

  useEffect(() => {
    setText(value);
  }, [value]);

  const dataId = `${name}-data`;

  return (
    <div className={classNames(styles.root, className, styles[variant])}>
      <form onSubmit={textSearch}>
        <input
          type="text"
          name={name}
          placeholder={placeholder}
          onChange={e => setText(e.target.value)}
          value={text}
          list={dataId}
          autoComplete="on"
          required
        />
        <datalist id={dataId}>
          {suggestions.map((sug, idx) => {
            return <option key={idx} value={sug} />;
          })}
        </datalist>
        <button className={styles.submit} disabled={!isDirty()}>
          {submitSlot}
        </button>
        <button
          onClick={clearSearch}
          className={styles.clearButton}
          disabled={!text || text !== value}
        >
          <i className={classNames("material-icons")}>clear</i>
        </button>
      </form>
    </div>
  );

  function textSearch(e) {
    e.preventDefault();
    submit(text);
  }

  function clearSearch() {
    setText("");
    submit("");
    onClear();
  }

  function isDirty() {
    return text !== value;
  }
}
