import React from 'react';
import styles from '../styles/Loading.module.css';
import { classNames } from '../lib/helpers';

export default React.memo(function(props) {
  const { className = '' } = props;
  return (
    <div className={classNames(styles.root, className)}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
});
