import { useState, useMemo, useEffect } from 'react'

import {
    minMaxProperty
} from "../lib/helpers";

const MONTH = 30 * 24 * 60 * 60 * 1000
const baseTime = MONTH * 5
const inititalMultiplier = 2

const useTimelineSlices = (data = []) => {
    const [dataMinDate, dataMaxDate] = minMaxProperty(data, 'date')

    const timeSlices = useMemo(() => {
        const now = new Date();
        const monthAgo = new Date(now - baseTime)
        const out = [[monthAgo.getTime(), now.getTime()]]
        let currentMinDate = monthAgo
        let multiplier = inititalMultiplier

        while (currentMinDate > dataMinDate) {
            const nextMinDate = new Date(now - baseTime * multiplier)
            out.push([nextMinDate.getTime(), now.getTime()])
            multiplier = multiplier * inititalMultiplier
            currentMinDate = nextMinDate
        }
        return out
    }, [data])

    const [timeSliceIndex, setTimeSliceIndex] = useState(0)
    const [minDate, maxDate] = timeSlices[timeSliceIndex];

    useEffect(() => {
        dataMaxDate && setTimeSliceIndex(getInitialZoom(dataMaxDate))
    }, [setTimeSliceIndex, dataMaxDate])

    return {
        timeSlices,
        timeSliceIndex,
        setTimeSliceIndex,
        minDate,
        maxDate,
        dataMinDate,
        dataMaxDate
    }

    /**
   * Get the intial zoom so it always displays content
   * 
   * @returns {number}
   */
    function getInitialZoom() {
        if (!dataMaxDate) return;
        let currentSliceIndex = 0
        let currentSlice = timeSlices[currentSliceIndex]
        while (currentSlice !== undefined && new Date(currentSlice[0]) > dataMaxDate) {
            currentSliceIndex++
            currentSlice = timeSlices[currentSliceIndex]
        }
        return currentSliceIndex
    }
}


export default useTimelineSlices