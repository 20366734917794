import React from "react";
import styles from "./style.module.css";

// -- Libs
import { formatDate, classNames } from "../../lib/helpers";
import { INFOGRAPHIC_TAG, VIDEO_TAG, AUDIO_TAG } from "../../lib/constants";

// -- Components
import Image from "../Image";
import { Link } from "react-router-dom";
import DocumentTypeIcon from "../DocumentTypeIcon";

// -- Hooks
import useLanguage from "../../hooks/use_language";

export default React.memo(function (props) {
  const {
    title = "",
    value = "",
    date,
    extUrl = "",
    author = "",
    providerName = "",
    providerUrl = "",
    img = {},
    tags = [],
    documentSubtypes = []
  } = props,
    [documentSubtype = ""] = documentSubtypes;

  const i18n = useLanguage();

  // suppress images
  // const { width, height } = img,
  //   cropRatio = height / width || 0.55,
  const tagsWithName = tags
      .map(tag => {
        return {
          name: i18n("issue", tag),
          slug: tag
        };
      })
      .filter(({ name }) => !!name);

  // related tags
  const related = tagsWithName.length > 0 && (
    <aside className={styles.related}>
      <h5>{i18n("related_issues")}</h5>
      <ul>
        {tagsWithName.map(({ name, slug }) => (
          <li key={slug}>
            {/* <Link to={`/search/?issue=${slug.replace("issue-", "")}`}>{name}</Link> */}
            <Link to={`/search/?issue=${name}`}>{name}</Link>
          </li>
        ))}
      </ul>
    </aside>
  ),
    readMore = extUrl && (
      <a
        href={extUrl}
        className={classNames("button", styles.readMore)}
        target="_blank"
        rel="noreferrer noopener"
      >
        {getReadMoreText()}
      </a>
    ),
    showAncillary = readMore || related;

  // -- Render
  return (
    <article className={styles.root}>
      {title && <h1>{title}</h1>}
      <div className={styles.columnMain}>
        <header>
          <h3>
            <a href={providerUrl} target="_blank" rel="noopener noreferrer">
              {providerName}
            </a>
            <br />
            {author}
          </h3>
          <h3>{formatDate(new Date(date))} </h3>
        </header>
        {/*<Image src={img.url} alt={title} lazy={false} cropRatio={cropRatio} />*/}
        {value}
      </div>
      {showAncillary && (
        <div className={styles.columnAncillary}>
          {readMore}
          {related}
        </div>
      )}
    </article>
  );

  function getReadMoreText() {
    let text = ""

    switch (documentSubtype) {
      case INFOGRAPHIC_TAG:
        text = i18n("see_infographic");
        break;
      case VIDEO_TAG:
        text = i18n("watch_video");
        break;
      case AUDIO_TAG:
        text = i18n("listen");
        break;
      default:
        text = i18n("view_article");
    }
    return (
      <React.Fragment>
        {text}
        <DocumentTypeIcon tagName={documentSubtype} />
      </React.Fragment>
    );
  }
});
