import * as d3 from "d3";

const draw = ({
  selector = "",
  data = {},
  width: _width = 100,
  height: _height = 45,
  color = "#000"
}) => {
  const margin = { top: 0, right: 0, bottom: 0, left: 0 },
    width = _width - margin.left - margin.right,
    height = _height - margin.top - margin.bottom;

  const x = d3.scaleTime().range([0, width]),
    y = d3.scaleLinear().range([height, 0]);

  const area = d3
    .area()
    .x(d => x(d.date))
    .y0(height)
    .y1(d => y(d.value));

  const svg = d3
    .select(selector)
    .append("svg")
    .attr("viewBox", `0 0 ${width} ${height}`)
    .attr("preserveAspectRatio", "xMinYMin meet")
    .append("g")
    .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

  // scale the range of the data
  x.domain(
    d3.extent(data, function (d) {
      return d.date;
    })
  );
  y.domain([0, 10]);

  // add the area
  svg
    .append("path")
    .data([data])
    .attr("class", "area")
    .attr("d", area)
    .attr("fill", color);
};

export { draw };
