import React from 'react';
import styles from './style.module.css'

export default function (props={}) {
  const {show = false} = props;
  return show && <button className={styles.backToTop} onClick={smoothScrollToTop}>
    <i className="material-icons">keyboard_arrow_up</i>
  </button>
}

/**
 * Smooth-scroll to top of window
 */
function smoothScrollToTop() {
  return window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
}
