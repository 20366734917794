import React from "react";
import styles from "./style.module.css";

const ZoomButtons = (props = {}) => {
  const {
    canZoomOut = false,
    canZoomIn = false,
    zoomOut = () => {},
    zoomIn = () => {}
  } = props;

  return (
    <div className={styles.container}>
      <button
        disabled={!canZoomOut}
        onClick={zoomOut}
        className={styles.zoomButton}
      >
        -
      </button>
      <button
        disabled={!canZoomIn}
        onClick={zoomIn}
        className={styles.zoomButton}
      >
        +
      </button>
    </div>
  );
};

export default ZoomButtons;
