import React from 'react';
import styles from './style.module.css';
import { classNames } from '../../lib/helpers';


function Next(props = {}) {
  const { current = 0, size = 0, goTo = () => {}, className = '' } = props;
  return (
    <NextPrevButton
      {...props}
      label="Next"
      disabled={current >= size - 1}
      onClick={goTo.bind(this, current + 1)}
      className={classNames(className, styles.next)}
    />
  );
}

function Previous(props = {}) {
  const { current = 0, goTo = () => {}, className = '' } = props;
  return (
    <NextPrevButton
      {...props}
      label="Previous"
      disabled={current < 1}
      onClick={goTo.bind(this, current - 1)}
      className={classNames(className, styles.previous)}
    />
  );
}

/**
 * Internal base component for Next and Previous
 */
function NextPrevButton(props = {}) {
  const {
    className = '',
    label = '',
    disabled = false,
    onClick = () => {}
  } = props;
  return (
    <button
      className={classNames(styles.nextPrevious, className)}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </button>
  );
}

export { Next, Previous };