import * as d3 from "d3";
import { wait } from "../helpers";
import styles from "./style.module.css";
import { Axes, tickFormat } from "./shared";


export default function (el, _width = 400, _height = 200) {
  if (!el) {
    console.warn(`HTML element is required is required`);
    return;
  }

  let line,
    sentimentDots,
    eventDots,
    nearestEvent,
    axes;

  const margin = {
    top: 0,
    bottom: 70,
    left: 40,
    right: 0
  },
    height = _height - margin.top - margin.bottom,
    width = _width - margin.left - margin.right,
    svg = d3
      .select(el)
      .attr("viewbox", `0 0 ${_height} ${_width}`)
      .attr("class", styles.root)
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`),
    yScale = d3.scaleLinear().range([height, 0]),
    xScale = d3.scaleLinear().range([0, width - margin.right]);

  function draw(sentimentHistory = [], events = [], opts = {}) {
    const {
      lineStroke = "black",
      dotFill = () => "red",
      minDate,
      maxDate,
      eventFocusEvent = "",
      strokeWidth = 3,
      dotSize = 6,
      dotMouseEnter = () => { },
      dotMouseLeave = () => { },
      padding = 0
    } = opts,
      lineD = d3
        .line()
        .x(({ date }, i) => {
          return xScale(date);
        })
        .y(({ value }) => yScale(value));

    if (!minDate || !maxDate) {
      console.error(`minDate and maxDate are required`);
      return null;
    }

    xScale.domain([minDate - padding, maxDate + padding]);
    yScale.domain([0, 10]);

    axes = Axes(svg, { width, height, margin, yScale, xScale });
    axes.appendHorizontalTicks(svg, width, yScale);

    const mouseG = svg.append("g").attr("class", "mouse-over-effects");

    const mouseLine = mouseG
      .append("path")
      .attr("class", styles.mouseLine)
      .style("opacity", "0");

    mouseG
      .append("svg:rect")
      .attr("width", width)
      .attr("height", height + margin.top + margin.bottom)
      .attr("fill", "none")
      .attr("pointer-events", "all")
      .on("mouseout", () => {
        mouseLine.style("opacity", "0");
      })
      .on("mouseover", () => {
        mouseLine.style("opacity", "1");
      })
      .on("mousemove", function () {
        const [mouseX] = d3.mouse(this);
        mouseLine.attr("d", () => {
          return `M ${mouseX}, ${height +
            margin.bottom +
            margin.top} ${mouseX}, 0`;
        });
        eventDots.classed(styles.event__highlight, false);
        nearestEvent = findNearest(eventDots, mouseX);
        nearestEvent && nearestEvent.classList.add(styles.event__highlight);
      })
      .on("mouseleave", () => {
        eventDots.classed(styles.event__highlight, false);
      })
      .on("click", () => {
        if (!nearestEvent) return;
        nearestEvent.dispatchEvent(new MouseEvent("click"));
      });

    axes.appendXAxis();

    // -- Line path
    line = svg
      .append("path")
      .datum(sentimentHistory)
      .attr("class", styles.line)
      .attr("stroke", lineStroke)
      .attr("stroke-width", strokeWidth)
      .attr("d", lineD);

    // -- Sentiment
    sentimentDots = svg
      .selectAll(".dot")
      .data(sentimentHistory)
      .enter()
      .append("circle")
      .attr("class", styles.dot)
      .attr("fill", ({ value }) => dotFill(value))
      .attr("cx", ({ date }) => {
        return xScale(date);
      })
      .attr("cy", ({ value = 0 }) => yScale(value))
      .attr("r", dotSize)
      .on("mouseout", dotMouseLeave)
      .on("mouseover", function ({ value = 0 }) {
        dotMouseEnter(this, value);
      });

    // -- Event dots
    eventDots = svg
      .selectAll(".event")
      .data(events)
      .enter()
      .append("g")
      .attr("width", 10)
      .attr("height", 10)
      .attr("class", styles.eventG)
      .attr("transform", ({ date }) => {
        return `translate(${xScale(date)}, ${height - 20})`;
      });

    eventDots.append("circle").attr("r", 5);

    eventDots.append("circle").attr("r", 7);

    axes.appendYAxis({ svg, margin, height, width, yScale });

    eventDots.on("click", d => {
      window.dispatchEvent(
        new CustomEvent(eventFocusEvent, {
          detail: d
        })
      );
    });
  }

  function redraw(opts = {}) {
    const {
      minDate,
      maxDate,
      strokeWidth = 3,
      dotSize = 5,
      padding = 0,
      step = 0
    } = opts;

    axes.x.tickFormat(tickFormat(step));

    xScale.domain([minDate - padding, maxDate + padding]);
    axes.rescaleX(xScale);
    line
      .transition()
      .attr("stroke-width", Math.max(strokeWidth, 2))
      .attr(
        "d",
        d3
          .line()
          .x((d, i) => {
            return xScale(d.date);
          })
          .y(({ value }) => yScale(value))
      );
    sentimentDots
      .transition()
      .attr("cx", ({ date = 0 }) => xScale(date))
      .attr("r", dotSize > 2 ? dotSize : 0);

    eventDots
      .transition()
      .attr(
        "transform",
        ({ date }) =>
          `translate(${xScale(date)}, ${height + margin.bottom * 0.65})`
      );
  }

  function highlight(data, duration = 500) {
    if (!eventDots) return;
    const highlighted = eventDots.filter(function ({ date }) {
      return data === date;
    });

    highlighted.nodes().forEach(async node => {
      highlighted.classed(styles.event__highlight, true);
      await wait(duration);
      highlighted.classed(styles.event__highlight, false);
    });
  }

  function clear() {
    svg.selectAll("*").remove();
  }

  function findNearest(selection, xPos) {
    const x = xScale.invert(xPos);
    const [index] = selection.data().reduce(
      (acc, data, idx) => {
        const date = new Date(data.created_at);
        const [_, shortestDistance] = acc,
          distance = Math.abs(x - date.getTime());
        if (shortestDistance === null || distance < shortestDistance) {
          acc = [idx, distance];
        }
        return acc;
      },
      [null, null]
    );
    return selection.nodes()[index];
  }

  return {
    draw,
    redraw,
    highlight,
    clear
  };
}
