/**
 * A list of rows
 * balances rows of tiles
 * @typedef {object} Grid
 * @property {Row} head - first row in linked list
 * @property {Row} tail - last row in linked list
 * @property {[]Row} rows - all rows as array
 * @property {number} gaps - count of all gaps in the grid structure
 */

export default class Grid {
  constructor(head) {
    this.head = head;
  }

  get tail() {
    let row = this.head,
      out;
    while (row) {
      out = row;
      row = row.next;
    }
    return out;
  }

  get rows() {
    let out = [];
    let row = this.head;
    while (row) {
      out = out.concat(row);
      row = row.next;
    }
    return out;
  }

  get gaps() {
    return this.rows.reduce((acc, row) => {
      acc += row.capacity;
      return acc;
    }, 0);
  }

  /**
   * Fill gaps in the grid by expanding and shifting tiles
   * @property {number} tries - number of passes to take to fill in gaps
   */
  fillGaps(tries = 4) {
    while (tries && this.gaps) {
      this._fillGaps();
      tries--;
    }
  }

  /**
   * Internal function of fillGaps
   */
  _fillGaps() {
    let start = false;
    if (!this.gaps) return;

    let row = this.head;
    while (row && this.gaps) {
      if (row.capacity > 0) {
        row.fill(start);
        start = !!start;
      }
      row = row.next;
    }
    row = this.head;
    while (row && this.gaps) {
      row.expandFirstExpandable();
      row = row.next;
    }
  }

  /**
   * Add tiles to grid at head
   * @param {[]Tile} tiles
   */
  add(tiles = []) {
    tiles.forEach(tile => {
      this.head.add(tile);
    });
  }
}
