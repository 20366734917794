import useStoreon from "storeon/react";
import {DEFAULT_LANGUAGE} from "../lib/constants";

function _i18n(lang, data) {
  return (...args) => {
    try {
      const loc = [...args].reduce((acc, part) => acc[part], data);
      if (typeof loc === undefined) return ''
      return loc[lang] || loc[DEFAULT_LANGUAGE]
    } catch (e) {}
  };
}

/**
 * Use language hook
 * Returns curried funciton which takes language as param
 * @return {function}
 */
export default function useLanguage() {
  const { settings, i18n } = useStoreon("settings", "i18n"),
    { lang = "en" } = settings;
  return i18n.getText(lang)
}