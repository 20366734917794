import {
  useEffect
} from 'react';
import {
  path
} from 'ramda';

export default function useHashChange(func) {
  useEffect(() => {
    const hash = path(['location', 'hash'], window),
      hashContent = hash.split('#')[1] || null;

    func(hashContent)
  }, [window.location.hash]);
}
