import React from "react";
import useStoreon from "storeon/react";

// -- Styles
import styles from "./style.module.css";
import fade from "../../styles/transitions/fade.module.css";

// -- Lib
import { classNames } from "../../lib/helpers";

// -- Hooks
import useLanguage from "../../hooks/use_language";

// -- Components
import { CSSTransition } from "react-transition-group";
import { Switch, Route } from "react-router-dom";
import NavIcon from "./NavIcon";

export default function (props) {
  const {
      children,
      highlight = "",
      style = {},
      className = "",
      sticky = false,
      disabled = false,
      breadcrumb = false,
    } = props,
    { isScrollPositionTop = true } = useStoreon("isScrollPositionTop"),
    topClass = isScrollPositionTop && sticky ? styles.top : "";

  const i18n = useLanguage();
  const { theme } = useStoreon("theme");

  const breadCrumbView = (
      <CSSTransition
        classNames={fade}
        timeout={{ enter: 500, exit: 0 }}
        in={!isScrollPositionTop}
      >
        <Switch>
          <Route
            path="/"
            exact
            render={() =>
              isScrollPositionTop || (
                <h1>{i18n("metadata", "relationship", "title")}</h1>
              )
            }
          />
          <Route
            path="/deep-dives"
            render={() =>
              isScrollPositionTop || (
                <h1>{i18n("metadata", "deep_dive", "title")}</h1>
              )
            }
          />
          <Route
            path="/search"
            exact
            render={() =>
              isScrollPositionTop || (
                <h1>{i18n("metadata", "explore", "title")}</h1>
              )
            }
          />
          <Route
            path={["/relationship"]}
            render={() =>
              isScrollPositionTop || (
                <h1>{i18n("metadata", "relationship", "title")}</h1>
              )
            }
          />
        </Switch>
      </CSSTransition>
    ),
    nav = (
      <ul>
        <li>
          <NavIcon
            isActive={isHome}
            icon="relationship"
            title={i18n("metadata", "relationship", "title")}
            to="/"
            className={highlightedClass("relationship")}
            disabled={disabled}
          />
        </li>
        <li>
          <NavIcon
            icon="deepdive"
            title={i18n("metadata", "deep_dive", "title")}
            to="/deep-dives"
            className={highlightedClass("deepdive")}
            disabled={disabled}
          />
        </li>
        <li>
          <NavIcon
            icon="explore"
            title={i18n("metadata", "explore", "title")}
            to="/search"
            className={highlightedClass("explore")}
            disabled={disabled}
          />
        </li>
      </ul>
    );

  return (
    <nav
      className={classNames(
        styles.root,
        styles[theme],
        topClass,
        sticky ? styles.sticky : "",
        className
      )}
      style={style}
    >
      <div className={classNames("constrain", styles.container)}>
        <p>{i18n("view")}</p>
        {nav}
        <div className={styles.right}>{children}</div>
      </div>
    </nav>
  );

  /**
   * Apply highlight class if slug matches highlight prop
   * @param {string} slug
   * @return {string}
   */
  function highlightedClass(slug = "") {
    return highlight === slug ? styles.highlight : "";
  }
}

/**
 * Is this a relationship or home page
 * @param {any} _ ignored
 * @param {object} obj
 * @param {string} obj.pathname
 * @return {boolean}
 */
function isHome(_, { pathname }) {
  if (pathname === "/" || pathname.includes("/relationship")) return true;
  return false;
}
