import React from "react";
import styles from "./style.module.css";
import { findFlagUrlByIso3Code } from "country-flags-svg";
import {COUNTRIES} from '../../lib/constants'

export default React.memo(function (props) {
  const { id = "", name = "", code = "" } = props,
    flag = findFlagUrlByIso3Code(COUNTRIES[id].code);

  return (
    <div className={styles.root} {...props}>
      <div>
        <span>{code}</span>
        {flag && <img src={flag} alt={`Flag of ${name}`} />}
      </div>
    </div>
  );
});
