import { MEDIA_CHILDREN } from "../lib/constants";
import { fetchPath } from "../lib/api";
import { fetchSquirroPath } from "../lib/api"

const initial = {
  country: {},
  issue: {},
  issueCategory: {},
  contentType: {},
  deepdive: []
};

export default store => {
  store.on("@init", () => ({
    tags: { ...initial, pending: false, fetched: false, error: null }
  }));

  store.on("tags/fetch", async ({ tags = {} }) => {
    if (tags.pending === true || tags.fetched === true) return;
    store.dispatch("tags/pending");
    const [error, results = []] = await fetchSquirroPath("/keywords-api/get-keyword");
    store.dispatch("tags/save", {
      ...tagsReducer(results["data"]["tag"], initial),
      pending: true,
      fetched: false,
      error
    });
  });

  store.on("tags/pending", ({ tags = {} }) => {
    return {
      tags: { ...tags, pending: true, fetched: false }
    };
  });

  store.on("tags/save", (_, tags) => {
    return { tags };
  });

  /**
   * Reduce tags
   * Decorates with sortweight
   * @param {object} acc
   * @param {object} item
   * @param {number} idx - index
   * @return {object}
   */
  function tagsReducer(tags = [], tagsInitial = {}) {
    // reverse() used here to preserve order by adding weight to some tags
    // by reversing we have last in, first out so tags in the front of the array
    // have a lower sort order but a higher sort weight
    return tags.reverse().reduce((acc = {}, item = {}, idx) => {
      const id = item;
      // Don't store some media types as media categories
      if (MEDIA_CHILDREN.includes(id)) {
        return acc;
      }
      if (id.match(/^country-/)) {
        acc.country[id] = item;
      } else if (id.match(/^document-type-/)) {
        acc.contentType[id] = Object.assign(item, {
          weight: 100 + idx
        });
      } else if (id.match(/^issue-category/)) {
        acc.issueCategory = item;
      } else if (id.match(/^issue-/)) {
        acc.issue[id] = Object.assign(item, {
          weight: idx
        });
      } else if (id.match(/^deepdive-/)) {
        acc.deepdive.push(item);
      }
      return acc;
    }, tagsInitial);
  }
};
