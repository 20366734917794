import React from "react";
import styles from "./style.module.css";
import Icon from "../Icons";

const TileHeader = (props = {}) => {
  const { featured = false, text = "" } = props;
  return (
    <h3 className={styles.root}>
      {featured && <Icon name="featured" className={styles.icon} />}
      {text}
    </h3>
  );
};

export default TileHeader;
