import React from 'react';
import styles from './style.module.css';
import { classNames } from '../../lib/helpers'

// -- Components
import { NavLink } from 'react-router-dom';
import Icon from '../Icons';

export default function(props, j) {
  const {
    title = '',
    isActive,
    to = '',
    icon = '',
    className = '',
    disabled = false
  } = props;

  return (
    <NavLink
      to={disabled ? '' : to}
      isActive={isActive}
      activeClassName={styles.active}
      title={title}
      className={classNames(styles.navIcon, className)}
    >
      <Icon name={icon} />
      {title}
    </NavLink>
  );
}
