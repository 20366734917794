import React from 'react';
import { classNames } from '../lib/helpers';
import styles from '../styles/Empty.module.css';

export default function(props) {
  const { children, style = '', className = '' } = props;
  return (
    <article className={classNames(styles.root, className)} style={style}>
      <div>{children}</div>
    </article>
  );
}
