import React, {useEffect} from 'react';
import TrendLink from "./TrendLink";
import useStoreon from "storeon/react";

const TrendingTerms = ({onSelect=()=>{}}) => {
    const {
            trends = {},
            dispatch,
        } = useStoreon("trends");

        useEffect(() => {
            dispatch("trends/fetch", 12);
        }, []);

    return <>
        {Object.entries(trends.data).map(([key, value]) => {
            return (
                <TrendLink
                    keyword={key}
                    key={key}
                    onClick={() => onSelect(key)}
                />
            );
        })}
        </>
}

export default TrendingTerms
