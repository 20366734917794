import React, { useEffect, useMemo } from "react";
import styles from "./style.module.css";
import { classNames, tileFillColorFromScore } from "../../../lib/helpers";
import { getCountryIdFromSlug } from "../../../lib/countries";
import useStoreon from "storeon/react";

import DashboardFigure from "../DashboardFigure";
import DashboardSparkline from "../DashboardSparkline";
import DashboardNumber from "../DashboardNumber";
import useLanguage from "../../../hooks/use_language";

function RelationshipData(props = {}) {
  const { className = "", relationships = [] } = props;
  const i18n = useLanguage();

  // -- State
  const {
    tags,
    statusHistory = {},
    dispatch,
  } = useStoreon("tags", "statusHistory");

  useEffect(() => {
    dispatch("relationships/fetch");
    dispatch("status-history/fetch-all");
  }, []);

  const sorted = useMemo(
      () => sortRelationships(relationships, tags.country),
      [relationships.length, Object.keys(tags.country).length]
    ),
    { positive = [], negative = [], neutral = [] } = sorted;

  return (
    <div className={classNames(styles.className, className)}>
      {trendingSubhead(
        i18n("dashboard", "positive"),
        positive.length,
        tileFillColorFromScore(10)
      )}
      {positive.map(renderScores)}
      {trendingSubhead(
        i18n("dashboard", "negative"),
        negative.length,
        tileFillColorFromScore(0)
      )}
      {negative.map(renderScores)}
      {trendingSubhead(
        i18n("dashboard", "neutral"),
        neutral.length,
        tileFillColorFromScore(5)
      )}
      {neutral.map(renderScores)}
    </div>
  );

  /**
   * Create a trending subhead
   * @param {string} direction [neutral/positive/negative]
   * @param {*} size number of relationships trending this way
   * @param {*} bg backgrond color
   * @return {Element}
   */
  function trendingSubhead(direction = "", size = 0, bg = "") {
    return (
      size > 0 && (
        <DashboardFigure
          className={styles.bigFigure}
          subhead={i18n("dashboard", "since_last_survey")}
          figure={<DashboardNumber big bg={bg} number={size} />}
        >
          {headline()}
        </DashboardFigure>
      )
    );

    function headline() {
      const headlineTpl = i18n("dashboard", "trending_direction"),
        headlineParts = headlineTpl.split("@@");

      headlineParts.splice(1, 0, direction);

      return headlineParts.join("");
    }
  }

  /**
   * Render relationship scores
   * @param {object} obj
   * @param {object} obj.country
   * @param {number} obj.score
   * @return {Element}
   */

  function renderScores({ country = "", score = 5 }) {
    let countryId = "";
    const { data = {} } = statusHistory;
    let id = country;

    // TODO UK exception
    if (country === "country-united-kingdom") {
      countryId = "GB";
      id = "country-uk";
    } else {
      countryId = getCountryIdFromSlug(id) || "";
    }
    if (!countryId) {
      return;
    }

    return (
      <DashboardFigure
        to={`/relationship/${countryId}`}
        key={countryId}
        figure={<DashboardSparkline {...getColors()} data={data[id]} id={id} />}
      >
        {i18n("countries", countryId.toLowerCase())}
      </DashboardFigure>
    );

    function getColors() {
      if (score > 5) {
        return {
          bg: tileFillColorFromScore(6),
          color: tileFillColorFromScore(10),
        };
      }
      if (score < 5) {
        return {
          bg: tileFillColorFromScore(4),
          color: tileFillColorFromScore(0),
        };
      }
      return {
        bg: "white",
        color: tileFillColorFromScore(5),
      };
    }
  }
}

/**
 * Sort relationships
 * @param {object} obj
 * @param {object} obj.data
 * @param {object} countries
 * @return {object[]}
 */
function sortRelationships(data = [], countries = {}) {
  let out = {
    positive: [],
    negative: [],
    neutral: [],
  };
  data.forEach((meta) => {
    const keywords = meta["keywords"];

    let score = 5;
    if ("av-score" in keywords) {
      score = keywords["av-score"][0];
    }

    const countryId = keywords["story-groups-id"][0];
    const countryScore = {
      country: countryId,
      score: score,
    };

    if (score > 5) return (out.positive = [...out.positive, countryScore]);
    if (score < 5) return (out.negative = [...out.negative, countryScore]);
    out.neutral = [...out.neutral, countryScore];
  });
  return out;
}

export default RelationshipData;
