import React, { useEffect, useMemo } from "react";
import useStoreon from "storeon/react";
import { NavLink } from "react-router-dom";
import { upsertToObject } from "../lib/helpers";
import useLanguage from "../hooks/use_language";
import Loading from "./Loading";

export default function (props) {
  let staticPagesByCategory = {};
  const { className = "" } = props,
    { staticPages = [], settings, dispatch } = useStoreon("staticPages", "settings"),
    { data = [], pending = false } = staticPages;

  const i18n = useLanguage();

  useEffect(() => {
    dispatch("pages/fetch");
  }, [settings.lang]);

  staticPagesByCategory = data.reduce(staticPagesReducer, {});

  const menu = Object.keys(staticPagesByCategory)
    //  legal goes elsewhere on the site
    .filter(category => category !== 'legal')
    .sort(sortObjectByItemLength.bind(this, staticPagesByCategory))
    .map(key => {
      return (
        <div key={key}>
          <h2>{i18n("static_page_categories", key)}</h2>
          <ul>
            {staticPagesByCategory[key].map(({ name, id }, idx) => {
              return (
                <li key={idx}>
                  <NavLink to={`/page/${id}`}>
                    {i18n("footer", id) || id}
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </div>
      );
    }),
    loading = <Loading />;

  return <nav className={className}>{pending ? loading : menu}</nav>;
};

/**
 * Sort object by item length
 * @param {object} obj
 * @param {array} a
 * @param {array} b
 * @return {number} -1||1
 */
function sortObjectByItemLength(obj, a, b) {
  return obj[a].length > obj[b].length ? -1 : 1;
}

/**
 * Reduce static page array to object by static-page-type-{type} tag
 * @param {object} acc
 * @param {object} item
 * @returns {object}
 */
function staticPagesReducer(acc, { tags = [], name = "", id = "", type = "" }) {
  upsertToObject(acc, { id, name }, type);
  return acc;
}
