import React, { useEffect } from "react";
import useStoreon from "storeon/react";
import { path } from "ramda";
import withData from "./with-data";

// -- Styles
import styles from "./style.module.css";
import fade from "../../styles/transitions/fade.module.css";

// -- Libs
import { classNames } from "../../lib/helpers";
import { loadImages } from "../../lib/images";
import Grid from "./tile_layout/grid";
import Row from "./tile_layout/row";
import TileSet from "./tile_layout/tileset";
import {
  pairOrphans,
  getTileId,
  getGridItemClassName,
  addFeatured
} from "./lib";

// -- Components
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Loading from "../Loading";
import ExpandedItemModal from "../ExpandedItemModal";
import TileComponent from "./Tile";

// -- Hooks
import useLanguage from "../../hooks/use_language";

let i18n;

const StoryGroup = props => {
  const {
    animationTimeout = 500,
    rowSize = 4,
    featured = [],
    tweets = [],
    deepdives = [],
    rest = [],
    loaded = false
  } = props;

  // -- Store
  i18n = useLanguage();

  const { tags } = useStoreon("tags"),
    { issue: issues, contentType = {} } = tags;

  const grid = createGrid();

  useEffect(() => {
    if (!loaded) return;
    loadImages();
  }, [loaded]);

  const loadingSpinner = (
    <div className={styles.loadingSpinnerContainer}>
      <Loading />
    </div>
  );

  // -- Render
  return (
    <React.Fragment>
      {loaded || loadingSpinner}
      <TransitionGroup className={classNames("grid", "constrain")}>
        {grid.rows.map(({ tiles, size }) => {
          return tiles.map(tile => {
            const { width = 1, id = "" } = tile;
            return (
              <CSSTransition
                classNames={fade}
                timeout={animationTimeout}
                key={id}
              >
                <div className={classNames(getGridItemClassName(size, width))}>
                  {TileComponent(tile, isFeatured(tile))}
                </div>
              </CSSTransition>
            );
          });
        })}
      </TransitionGroup>
      <ExpandedItemModal />
    </React.Fragment>
  );

  /**
   * Fetch relevant content and marshal into a grid
   * @returns {Grid}
   */
  function createGrid() {
    const tileset = new TileSet();
    const grid = new Grid(new Row(rowSize)),
      deepdivesTile = tileset.addTile("deepdives", {
        sortWeight: 10,
        width: 1,
        canExpand: true,
        title: i18n("metadata", "deep_dive", "title")
      });

    // Add deepdives tile
    deepdives.forEach(deepdive => deepdivesTile.add(deepdive));

    // Iterate through entries, sort them into tiles by tag
    // add to tileset
    rest.forEach(entry => {
      const tagName = getTileId(entry),
        sortWeight = getSortWeight(tagName),
        opts = {
          sortWeight,
          title: i18n("issue", tagName) || i18n("document_types", tagName)
        };
      tileset.tile(tagName, opts).add(entry);
    });

    // Split the tiles if there are too few tiles
    tileset.ids.length < 3 &&
      tileset.ids
        .filter(id => id !== "social")
        .forEach(id => tileset.split(id));

    pairOrphans(tileset);

    // grid.add()

    // add non-featured tiles to grid
    grid.add(tileset.sort().tilesArray);

    // add featured tiles to grid
    addFeatured(grid, featured, i18n("featured"));

    // try to make sure the grid is a perfect rectangle
    grid.fillGaps(4);

    return grid;
  }

  /**
   * Get a tag's sortweight
   * @param {string} tag
   * @return {number}
   */
  function getSortWeight(tag = "") {
    return (
      path([tag, "weight"], issues) || path([tag, "weight"], contentType) || 0
    );
  }
};

/**
 * is a tile featured"
 * @param {Tile} tile
 * @returns {bool}
 */
function isFeatured(tile) {
  return tile.id === "featured";
}

export default withData(StoryGroup);
