import React from 'react';
import Dropdown from 'react-dropdown';

export default function(props) {
  return (
    <Dropdown
      {...props}
      arrowClosed={<i className="material-icons">expand_more</i>}
      arrowOpen={<i className="material-icons">expand_less</i>}
    />
  );
}
