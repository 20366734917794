import {
  useEffect
} from 'react'

/**
 * useEffect but debounced
 * @param {function} fn - function to call
 * @param {number} delay - debounce delay
 * @param {[]Any} deps - 2nd arg to useEffect
 */
export default function useDebounce(fn = () => {}, delay, deps = []) {
  useEffect(() => {
    const handler = setTimeout(() => {
      fn();
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, deps);
}
