const isBot = /bot|googlebot|crawler|spider|robot|crawling/i.test(navigator && navigator.userAgent)

export default store => {
  store.on("@init", () => ({
    settings: {
      lang: "en",
      blockIntro: isBot
    }
  }));

  store.on("settings/set-language", ({ settings = {} }, lang = "en") => {
    return { settings: { ...settings, lang } };
  });

  store.on("settings/hide-intro", ({ settings = {} }, perm = false) => {
    return {
      settings: {
        ...settings,
        blockIntro: !perm
      }
    };
  });
};
