import React from "react";
import styles from "./style.module.css";
import Link from '../../Link'


function TrendLink(props = {}) {
    const { keyword = '', onClick = () => {} } = props;

    return <button onClick={onClick} className={styles.root}>{keyword}</button>
}

export default TrendLink;

