import React from 'react';
import { classNames } from '../../lib/helpers';
import styles from './style.module.css';
import useStoreon from 'storeon/react';

export default React.memo(function (props) {
  const { children,
    className = '',
    collapseOnScroll = false,
    after = () => { }
  } = props,
    { isScrollPositionTop = true } = useStoreon('isScrollPositionTop'),
    collapsibleClass = collapseOnScroll ? styles.collapsible : '',
    collapseClass = shouldCollapse() ? styles.collapsed : '';

  return (
    <header
      className={classNames(
        'pageheader',
        styles.root,
        className,
        collapseClass,
        collapsibleClass
      )}
    >
      {children}
      {after()}
    </header>
  );

  /**
   * Should element collapse
   * @returns {boolean}
   */
  function shouldCollapse() {
    return collapseOnScroll && !isScrollPositionTop;
  }
});
