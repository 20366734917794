import { COUNTRIES } from "./constants";
import { path } from "ramda";
import { memoize } from "lodash";

/**
 * Safely get the slug for a country based on ID
 * @param {string} countryApiId - from the api e.g., country-germany
 * @return {string}
 */
function getCountryIdFromSlug(countryApiId = "") {
  const entry = Object.entries(COUNTRIES).find(([_, value]) => {
    return `country-${value.slug}` === countryApiId;
  });
  if (!entry) return "";
  return entry[0];
}

/**
 * Safely get the slug for a country based on ID
 * @param {string} countryId
 * @return {string} a country slug
 */
function getCountrySlug(countryId) {
  return path([countryId, "slug"], COUNTRIES);
}

/**
 * Safely get the country tag of a country (whether or not it exists)
 * @param {string} countryId
 * @return {string}
 */
function getCountryTagId(countryId = "") {
  return `country-${getCountrySlug(countryId)}`;
}

/**
 * Determine whether a country is in the EU
 */
const isEU = memoize((countryId = "") => {
  return COUNTRIES[countryId] && COUNTRIES[countryId].inEU;
});

/**
 * Countries memozied as array
 */
const countriesAsArray = memoize(() => {
  return Object.keys(COUNTRIES).map(id =>
    Object.assign(COUNTRIES[id], {
      id
    })
  );
});

/**
 * Get the slug of a country from its ID
 * @param {string} id
 * @returns {string}
 */
function getSlugFromId(id = "") {
  return path([id, "slug"], COUNTRIES);
}

export {
  getCountryIdFromSlug,
  getCountrySlug,
  getCountryTagId,
  isEU,
  countriesAsArray,
  getSlugFromId
};
