import React from "react";
import styles from "./style.module.css";

// -- Lib
import { classNames } from "../../../lib/helpers";
import { BLUE } from "../../../lib/constants";

// -- Hooks
import useLanguage from "../../../hooks/use_language";
import { useHistory } from "react-router-dom";

// -- Components
import RelationshipData from "../RelationshipData";
import DashboardNumber from "../DashboardNumber";
import DashboardFigure from "../DashboardFigure";
import SingleItem from "../../../components/SingleItem";
import TrendingTerms from "../../../components/TrendingTerms";

const IndexView = (props = {}) => {
  const {
    relationships = {},
    deepdives = {},
    allDeepDives = [],
    entriesTotal = {},
    searchStats = []
  } = props;

  const i18n = useLanguage();

  // -- Deepdives rendered
  const deepDiveTeasers = allDeepDives.slice(0, 3).map(teaser => {
    teaser["value"] = teaser["body"];
    return (
      <SingleItem data={teaser} className={styles.singleItem} key={teaser.id} />
    );
  });

  const history = useHistory();

  const goToSearchTerm = term => {
    history.push(`/search?terms=${term}`);
  }

  return (
    <div className={classNames("constrain", "grid")}>
      <div className={classNames("grid--item__third", styles.column)}>
        <h2 className={styles.subhead}>
          {i18n("dashboard", "relationships_subhead")}
        </h2>
        <RelationshipData relationships={relationships.data} />
        <h2 className={classNames(styles.secondChild, styles.subhead)}>
          {i18n("dashboard", "trending_terms")}
        </h2>
        <TrendingTerms onSelect={goToSearchTerm}/>
      </div>
      <div className={classNames("grid--item__third", styles.column)}>
        <h2 className={styles.subhead}>
          {i18n("dashboard", "deepdives_subhead")}
        </h2>
        {deepdives.pending || (
          <DashboardFigure
            className={styles.bigFigure}
            figure={
              <DashboardNumber
                number={allDeepDives.length}
                big
                shadow
                bg={BLUE}
              />
            }
          >
            {i18n("dashboard", "recent_deepdives")}
          </DashboardFigure>
        )}
        {deepDiveTeasers}
      </div>
      <div className={classNames("grid--item__third", styles.column)}>
        <h2 className={styles.subhead}>
          {i18n("dashboard", "search_subhead")}
        </h2>
        {entriesTotal.pending || (
          <DashboardFigure
            className={styles.bigFigure}
            figure={<DashboardNumber number={entriesTotal.data} big />}
            variants={["big"]}
          >
            {i18n("dashboard", "current_searchable")}
          </DashboardFigure>
        )}
        {searchStats.map(({ name = "", count = "", id = "" }) => {
          return (
            <DashboardFigure
              key={name}
              figure={<DashboardNumber number={count} />}
              to={`/search?country=${id}`}
            >
              {name}
            </DashboardFigure>
          );
        })}
      </div>
    </div>
  );
};

export default IndexView;
