import React from "react";
import styles from "./style.module.css";
import Link from "../Link";
import Image from "../Image";
import Sourceband from "./Sourceband";

export default function LargeTeaser(props) {
  const {
      title = "",
      date = "",
      value = "",
      provider = "",
      providerIcon = "",
      img = {},
      link = "",
      maxCropRatio = Infinity,
      noCrop = false
    } = props,
    { url = "", height, width } = img;

  return (
    <Link to={link}>
      <figure className={styles.imgContainer}>
        <Image
          src={url}
          cropRatio={Math.min(maxCropRatio, getCropRatio(width, height))}
          width={1}
          alt={title}
          lazy
          noCrop={noCrop}
        />
        <Sourceband
          provider={provider}
          providerIcon={providerIcon}
          date={date}
        />
      </figure>
      <div className={styles.textContainer}>
        <h3 className={styles.title}>{title}</h3>
        {value && <p>{value}</p>}
      </div>
    </Link>
  );
}

function getCropRatio(width, height) {
  return height / width || 0.5;
}
