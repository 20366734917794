import queryString from 'query-string';
import {
  fetchEntries,
  buildQuery
} from '../../lib/api';
import {
  SOCIAL_MEDIA_TAG
} from '../../lib/constants';
import {
  getDatesFromRange
} from '../../lib/date';

export const PAGE_SIZE = 20

/**
 * Read values form query string by keys specified
 * @param {...string} keys
 * @return {object}
 */
export function parseQueryString(...keys) {
  const parsed = queryString.parse(window.location.search);
  return keys.reduce((acc, key) => {
    acc[key] = parsed[key];
    return acc;
  }, {});
}

/**
 * Fetch results from API
 * @param {object} params
 * @param {number} offset
 * @param {number} limit
 */
export async function fetchResults(params = {}, offset = 0) {
  const [error, articles = [], count = 0] = await fetchEntries(
    Object.assign(params, {
      limit: PAGE_SIZE,
      offset
    })
  );
  if (error) {
    console.error(error);
  }
  return [articles, count];
}

/**
 * build API query params by translating a query string
 * @param {string} search
 * @returns {object}
 */
export function translateQueryStrings(qs = '') {
  const parsedQs = queryString.parse(qs);
  const {
    country = '',
    topic = '',
    terms = '',
    issue = '',
    people = '',
    locations = '',
    organizations = '',
    date = '',
    type = '',
    lang = '',
    sort
  } = parsedQs, {
    to,
    from
  } = getDatesFromRange(date);

  let query = {
    countries: country ? [country] : [],
    topics: topic ? [topic] : [],
    search: terms,
    issues: issue ? [issue] : [],
    fromDate: from,
    toDate: to,
    contentTypes: type ? [type] : [],
    people: people ? [people] : [],
    locations: locations ? [locations] : [],
    organizations: organizations ? [organizations] : [],
    lang: lang ? [lang] : [],
    sortScript: 'relevancy',
    sort
  }
  // If document type is social Media, this query must be pretty different :(
  // return isSocialMediaSearch(query) ?
  //   buildQuery(socialMediaSearch(query)) :
  //   buildQuery(query)
  return buildQuery(query)
}

/**
 * Remove a string from an array of string
 * @param {[]string} arr
 * @param {string} str
 * @return {[]string}
 */
export function removeFromArray(arr = [], str = '') {
  return arr.filter(item => item !== str);
}

/**
 * Marshal a falsy value or comma-seprated string into an array
 * @param {Any}
 * @return {[]string}
 */
export function arrayFromString(val) {
  return val ? val.split(',') : [];
}

/**
 * Decorate search with socialMedia attributes
 * @param {object} query
 * @return {object} query decorated with additional params
 */
function socialMediaSearch(query) {
  return {
    ...query,
    contentTypes: [],
    sort: 'karma',
    sortScript: undefined,
  }
}

/**
 * is query a social media type search?
 * @param {object} query
 * @return {boolean}
 */
function isSocialMediaSearch({
  contentTypes = []
}) {
  return contentTypes.includes(SOCIAL_MEDIA_TAG)
}
