import React from "react";

// -- Lib
import { sortByDate } from "../../lib/date";

// -- Components
import SingleItem from "../SingleItem";
import { ArticleList } from "../List";
import TileHeader from "../TileHeader";

/**
 * Strategy to render a component from a Tile
 * @param {Tile} tile
 * @return {Component}
 */
export default function(tile = {}, featured = false) {
  const { items = [], title = "", id = "" } = tile;
  const header = title ? <TileHeader text={title} featured={featured} /> : "";

  return items.length > 1 ? (
    <ArticleList
      items={items.sort(sortByDate)}
      groupSize={1}
      perpage={featured ? 1 : 2}
      size={featured ? 2 : 0}
      variant="progress"
    >
      {header}
    </ArticleList>
  ) : (
    <SingleItem data={items[0]} size={2}>
      {header}
    </SingleItem>
  );
}
