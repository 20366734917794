import React from "react";
import styles from "./style.module.css";

import Link from "../Link";
import Image from "../Image";
import Sourceband from "./Sourceband";

export default function (props = {}) {
  const {
    title = "",
    value = "",
    provider = "",
    providerIcon = "",
    img = {},
    link = "",
    after = () => ""
  } = props;

  return (
    <Link to={link}>
      <figure className={styles.imgContainer}>
        <Image src={img.url} cropRatio={0.75} width={1} alt={title} lazy={false} />
      </figure>
      <Sourceband provider={provider} providerIcon={providerIcon}/>
      <div className={styles.textContainer}>
        <h3 className={styles.title}>{title}</h3>
        {value && <p>{value}</p>}
        {after()}
      </div>
    </Link>
  );
}
