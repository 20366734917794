import React, { useEffect, useState, useMemo } from "react";

// -- Lib
import RelationshipsCombined from "../../../lib/d3/relationship-combined";
import { COUNTRIES } from "../../../lib/constants";
import { getCountryIdFromSlug } from "../../../lib/countries";

// -- Components
import Loading from "../../../components/Loading";
import Hotspot from "../../../components/Hotspot";
import HotspotIndicator from "../../../components/HotspotIndicator";
import ZoomButtons from "../../../components/ZoomButtons";

// -- Styles
import styles from "./style.module.css";
import fadeFast from "../../../styles/transitions/fadeFast.module.css";

// -- Hooks
import useStoreon from "storeon/react";
import useLanguage from "../../../hooks/use_language";
import useTimeslices from "../../../hooks/use_timeslices";

const hoverDataInitial = {
  name: "",
  code: "",
  id: "",
  score: ""
};

const svgId = `relationship-combined`;
const selector = `#${svgId}`

const CombinedRelationships = (props = {}) => {
  const { hoverCountry = "", setHoverCountry = () => { } } = props;

  const i18n = useLanguage();

  // -- State
  const { statusHistory = {}, relationships={}, dispatch } = useStoreon("statusHistory", "relationships")
  const { data={}, fetched = false, error } = statusHistory;
  const {data: relationshipsData = {}, fetched: relationshipsFetched } = relationships

  const [hoverEl, setHoverEl] = useState(null),
    [hoverData, setHoverData] = useState(
      Object.assign(hoverDataInitial, getHotSpotData(hoverCountry))
    )

  const {
    timeSlices,
    timeSliceIndex,
    setTimeSliceIndex,
    minDate,
    maxDate
  } = useTimeslices(Object.values(data).reduce((acc, dataset) => {
    return acc.concat(dataset);
  }, []))

  const {
    draw,
    redraw,
    resetHighlight,
    highlightFromId
  } = useMemo(() => {
    if (!fetched || !relationshipsFetched) return {};

    /**
     * Filter the combined relationship statuses by  published results
     */
    const publishedRelationshipData = relationshipsData.reduce((acc, {keywords: {tag}}) => {
      if (data[tag]) {
        acc[tag] = data[tag]
      }
      return acc;
    }, {})

    return RelationshipsCombined({
      selector,
      data: publishedRelationshipData,
      width: 800,
      height: 322,
      timeScale: [minDate, maxDate],
      onPathMouseEnter: ([firstDataPoint], __, [el]) => {
        const { countryId = "", value = 5 } = firstDataPoint,
          score = value,
          { id = "", name = "", code = "" } = getHotSpotData(
            getCountryIdFromSlug(countryId)
          );

        setHoverData({
          name,
          code,
          id,
          score
        });
        highlightFromId(id);
        setHoverCountry(id);
      },
      onPathMouseLeave: () => {
        setHoverData(hoverDataInitial);
        resetHighlight();
        setHoverCountry(null);
      },
      styles
    })
  }, [data, fetched, relationshipsFetched])

  useEffect(() => {
    if (!draw) return
    draw()
  }, [draw])

  useEffect(() => {
    if (!redraw) return;
    redraw([minDate, maxDate], timeSliceIndex)
  }, [timeSliceIndex, redraw])

  useEffect(() => {
    dispatch("status-history/fetch-all");
  }, []);

  // Set the highlight status based on hovering map
  useEffect(() => {
    if (!highlightFromId) return;
    if (!hoverCountry) {
      resetHighlight && resetHighlight()
    }
    const { head } = highlightFromId(hoverCountry);
    setHoverEl(head);
  }, [highlightFromId, hoverCountry, resetHighlight]);

  if (error) {
    console.error(error);
    return "";
  }

  if (!fetched) return <Loading />;

  const zoomIn = () => {
    setTimeSliceIndex(timeSliceIndex - 1)
  }

  const zoomOut = () => {
    setTimeSliceIndex(timeSliceIndex + 1)
  }

  const canZoomIn = () => {
    return timeSliceIndex > 0
  }
  const canZoomOut = () => {
    return timeSliceIndex < timeSlices.length - 1
  }

  return (
    <div className={styles.root}>
      <h1>{i18n("dashboard", "trendline_headline")}</h1>
      <div className={styles.svgContainer}>
        <svg id={svgId} />
        <ZoomButtons
          canZoomOut={canZoomOut()}
          zoomOut={zoomOut}
          canZoomIn={canZoomIn()}
          zoomIn={zoomIn}
        />
      </div>
      <Hotspot
        target={hoverEl}
        transitionClassNames={fadeFast}
        topOffset={-50}
        timeout={750}
        className={styles.hotSpot}
      >
        {hoverData.id && (
          <HotspotIndicator
            name={hoverData.name}
            code={hoverData.code}
            id={hoverData.id}
          />
        )}
      </Hotspot>
    </div>
  );
};

function getHotSpotData(id = "") {
  if (!id)
    return {
      id: "",
      name: "",
      code: ""
    };
  const { name = "", code = "" } = COUNTRIES[id];
  return {
    id,
    name,
    code
  };
}

export default CombinedRelationships;
