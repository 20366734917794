import React, { useMemo, useState } from "react";
import styles from "./style.module.css";
import useTheme from "../../hooks/use_theme";

// -- Libs
import { classNames } from "../../lib/helpers";
import { fetchStories } from "../../lib/api";
import { newStory } from "../../lib/normalize_data";
import { getState } from "../../components/StateProvider";

// -- Components
import Image from "../../components/Image";
import PageHeader from "../../components/PageHeader";
import Meta from "../../components/Meta";
import SubMenu from "../../components/Submenu";
import Loading from "../../components/Loading";
import NotFound from "../NotFound";
import useStoreon from "storeon/react";

export default function (props) {
  const { match, history } = props,
    { params: { id = "" } = {} } = match;


  const { settings: { lang='en' } } = useStoreon("settings");

  let content;

  const [page, setPage] = useState({}),
    [error, setError] = useState(null),
    [loading, setLoading] = useState(true);

  const { name = "", image = "", description = "" } = page;

  useTheme("light");

  useMemo(async () => {
    let story = null;
    const [error, stories = []] = await fetchStories({
      limit: 1,
      andTags: [`static-page-language-${lang}`],
      tags: [`static-page-id-${id}`],
    });
    if (error) {
      console.error(error);
      return setError(error);
    }
    [story] = stories;
    setPage(story ? newStory(story) : null);
    setLoading(false);
  }, [id, lang]);

  // main content of page
  if (loading) {
    content = <Loading />;
  } else if (error || !page) {
    content = <NotFound />;
  } else {
    content = (
      <article className={classNames(styles.root, "body")}>
        {name && (
          <PageHeader>
            <h1>{name}</h1>
          </PageHeader>
        )}
        {description && (
          <div className={classNames("constrain", styles.container)}>
            {image && (
              <div className={styles.imgContainer}>
                <Image
                  src={image}
                  lazy={false}
                  cropRatio={1}
                  className={styles.image}
                  alt={name}
                />
              </div>
            )}
            <div
              className={classNames("html-container", styles.text)}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
        )}
      </article>
    );
  }

  return (
    <>
      <Meta
        title={name}
      />
      <SubMenu sticky />
      {content}
    </>
  );
}
