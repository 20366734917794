/**
 * Linked list containing several items
 * @typedef {object} Row
 * @property {object} settings
 * @property {number} settings.size
 * @property {Row} next
 * @property {[]Tile} tiles
 * @property {number} capacity - the row size - number of items
 */

export default class Row {
  constructor(size = 4) {
    this.settings = { size };
    this.next = null;
    this._tiles = [];
  }

  get tiles() {
    return this._tiles;
  }

  get capacity() {
    const widthTotal = this._tiles.reduce(
      (acc = 0, { width }) => (acc += width),
      0
    );
    return this.settings.size - widthTotal;
  }

  /**
   * Add a tile to the row
   * @param {Tile} tile
   */
  add(tile = {}) {
    if (!tile) return;
    const { capacity } = this;
    if (!capacity || capacity < tile.width) {
      this.next = this.next || new Row();
      this.next.add(tile);
      return;
    }
    this._tiles.push(tile);
  }

  /**
   * If the row is over capacity and isn't full of fixed position tiles,
   * move the last moveable (non-fixed) tile to the next row until it it as at capacity
   */
  reflow() {
    while (this.capacity < 0 && this.lastMoveableIndex > -1) {
      const [last] = this._tiles.splice(this.lastMoveableIndex, 1);
      this.next = this.next || new Row();
      this.next.insert(last, 0);
    }
  }

  /**
   * Fill the remaining capacity by expanding tiles
   * @param {boolean} fromStart - if true, expands tiles from the front of _tiles, otherwise the reverse
   */
  fill(fromStart = true) {
    if (fromStart) {
      let next = 0;
      while (this.capacity && next < this._tiles.length) {
        this.expandTile(next);
        next++;
      }
    } else {
      let next = this._tiles.length - 1;
      while (this.capacity && next >= 0) {
        this.expandTile(next);
        next--;
      }
    }
  }

  /**
   * Expand the first expandable tile
   */
  expandFirstExpandable() {
    this.expandTile(this._tiles.findIndex(({ canExpand }) => !!canExpand));
  }

  /**
   * Expand the tile at specified index
   * @param {number} index - index of tile to be expanded
   */
  expandTile(index = 0) {
    if (index < 0) return;
    const tile = this._tiles[index];
    if (!tile) return;

    tile.width = tile.width + 1;
    this.reflow();
  }

  /**
   * Inset a tile at the specified index
   * triggers reflow if over capacity
   * @param {Tile} tile
   * @param {number} index
   */
  insert(tile = {}, index = 0) {
    if (index > this.settings.size - 1) {
      throw new Error(
        `cannot insert tile at row with capacity ${
          this.capacity
        } at index ${index}`
      );
    }
    this._tiles.splice(index, 0, tile);
    this.reflow();
  }

  // -- Internals

  get lastMoveableIndex() {
    return this._tiles.map(({ fixed }) => fixed).lastIndexOf(false);
  }
}
