import React, { useEffect } from "react";
import style from "./style.module.css";

// -- Libs
import { fetchStoryGroup } from "../../lib/api";
import { newDeepDive } from "../../lib/normalize_data";
import { classNames } from "../../lib/helpers";

// -- Modules
import Credit from "../../components/Credit";
import StoryGroup from "../../components/StoryGroup";
import SingleItem from "../../components/SingleItem";
import DeepDivesMap from "../../maps/DeepDivesMap";
import PageHeader from "../../components/PageHeader";
import Timeline from "../../components/Timeline";
import Loading from "../../components/Loading";
import Meta from "../../components/Meta";

// -- Hooks
import useFetchEntries from "../../hooks/use_fetch_entries";
import useLanguage from "../../hooks/use_language";
import useGetStoryGroup from "../../hooks/use_get_story_group";
import useStoreon from 'storeon/react'

const initialCustomArticle = {
  title: "",
  short_description: "",
  content: "",
  curator: "",
  tag: ""
};

export default function (props) {
  const {
    history,
    match: {
      params: { id }
    }
  } = props;

  const deepdive = useGetStoryGroup(id, newDeepDive, initialCustomArticle);
  const { deepdive_tag = "" } = deepdive;
  const { dispatch, lastUpdated } = useStoreon("lastUpdated")

  const i18n = useLanguage(),
    deepDivesTitle = i18n("metadata", "deep_dive", "title"),
    pageTitle = `${deepDivesTitle}: ${deepdive.title || ""}`;

  useEffect(() => {
    !!deepdive.lastModified && dispatch("lastUpdated/update", {
      id, date: new Date(deepdive.lastModified)
    })
  }, [deepdive.lastModified])

  const {
    data: timelineEvents = [],
    error: timelineError = null,
    pending: timelinePending = true
  } = useFetchEntries(`${deepdive_tag}-timeline`, 50),
    showTimeline =
      !timelineError && timelineEvents.length > 0 && !timelinePending,
    customArticleClass = showTimeline
      ? "grid--item__third"
      : classNames("grid--item__two-thirds"),
    countries = getCountries(deepdive);

  timelineError && console.error(timelineError);

  // -- Partials
  const customArticle = (
    <div className={customArticleClass}>
      <SingleItem
        data={{ ...deepdive, link: null }}
        size={showTimeline ? 2 : 3}
      />
    </div>
  ),
    timeline = showTimeline && (
      <div className="grid--item__third">
        <Timeline
          className={style.timeline}
          events={timelineEvents}
          error={timelineError}
          pending={timelinePending}
        />
      </div>
    );

  // -- Render
  return (
    <>
      <Meta
        title={pageTitle}
        description={deepdive.value || i18n("meta", "deepdives", "description")}
      />
      <section className="body">
        <PageHeader after={() => <Credit link="https://www.bfna.org" lastModified={lastUpdated[id]} />}>
          <h1>{pageTitle}</h1>
        </PageHeader>

        <div className={classNames("grid", "constrain")}>
          <div className="grid--item__third">
            <DeepDivesMap
              tileClickHandler={id => history.push(`/deep-dives/country/${id}`)}
              countries={countries}
            />
          </div>
          {timelinePending ? (
            <Loading />
          ) : (
              <>
                {customArticle}
                {timeline}
              </>
            )}
        </div>

        <StoryGroup
          history={history}
          publishedTag={deepdive_tag}
          socialMediaTag={deepdive_tag}
          delay={500}
          id={id}
        />
      </section>
    </>
  );
}

/**
 * Get an array of country codes from a deepdive object
 * @param {Entry[]}
 * @return {string[]}
 */
function getCountries(deepdive) {
  const { countries = [] } = deepdive;
  return countries.reduce((acc = [], item) => {
    if (!item) return acc;
    return [...acc, item.id];
  }, []);
}
