import { fetchStories } from "../lib/api";

export default store => {
  store.on("@init", () => ({
    staticPages: {
      data: [],
      fetched: false,
      pending: false,
      error: null
    }
  }));

  store.on("pages/fetch", async ({ staticPages = {}, settings = {} }) => {
    const { fetched = false, pending = false } = staticPages;
    if (pending || fetched === settings.lang) return;
    store.dispatch("pages/pending");
    const [error, results = []] = await fetchStories({
      limit: 900,
      andTags: [`static-page-language-${settings.lang}`],
      tags: ["static-page"],
      fields: ["tags", "name"]
    });

    store.dispatch("pages/save", {
      data: results
        .sort(sortByWeightTag.bind(this, /^static-page-weight-/))
        .map(extractId)
        .map(extractType),
      pending: false,
      fetched: settings.lang,
      error
    });
  });

  store.on("pages/save", (_, pages = {}) => {
    return { staticPages: pages };
  });

  store.on("pages/pending", ({ ...pages }) => {
    return {
      pages: {
        ...pages,
        pending: true,
        fetched: false
      }
    };
  });
};

/**
 * Sort an item by a tag whose valiue is indicated by the tag matching the pattern provided
 * i.e., tag with item-sort-weight-10 < item-sort-weight-20
 * @param {regex} matcher
 * @param {object} a
 * @param {object} b
 * @returns {number} 1:-1
 */
function sortByWeightTag(matcher, a, b) {
  if (!matcher || !a || !b) return 1;
  return getSortWeight(a, matcher) > getSortWeight(b, matcher) ? -1 : 1;
}

/**
 * Get an id form a static-page-id tag and assign it to object as id
 * @param {object} item
 * @return {object}
 */
function extractId(item) {
  const { tags = [] } = item;
  const id = extractPropertyFromTag(tags, /^static-page-id-/);
  return {
    ...item,
    id
  };
}

/**
 * Get a page type form a static-page-type tag and assign it to object as type
 * @param {object} item
 * @return {object}
 */
function extractType(item) {
  const { tags = [] } = item;
  const type = extractPropertyFromTag(tags, /^static-page-type-/);
  return {
    ...item,
    type
  };
}

/**
 * Given on object with a tags property, find the sort weight of the item
 * @param {[]string} tags
 * @param {regexp} sortTagPattern - match the sort tag against this pattern. should follow format tag-used-to-sort-10
 * @return {number}
 */
function getSortWeight({ tags = [] }, sortTagPattern = new RegExp()) {
  const sortTag = tags.find(tag => tag.match(sortTagPattern));
  const sortWeight = sortTag ? sortTag.split("-").pop() : 0;
  return Number(sortWeight);
}

/**
 * Get a property from a tag by finding its match and
 * splitting it at at that tags matcher
 * @param {[]string} tags
 * @param {Regex} matcher
 * @return {string}
 */
function extractPropertyFromTag(tags = [], matcher) {
  const tag = tags.find(tag => tag.match(matcher)),
    [match = ""] = tag.match(matcher);
  return tag.split(match).pop();
}
