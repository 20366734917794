import React from "react";
import StoreContext from "storeon/react/context";

// -- Lib
import store from "./store/index.js";

// -- Components
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import DeepDive from "./pages/DeepDives/Single";
import DeepDivesAll from "./pages/DeepDives";
import Explore from "./pages/Explore";
import Relationship from "./pages/Relationship";
import Empty from "./components/Empty";
import Main from "./components/Main";
import ScrollHandler from "./components/ScrollHandler";
import Static from "./pages/Static";
import FourOhFour from "./pages/FourOhFour";
import Footer from "./components/Footer";

function AppRouter() {
  return (
      <StoreContext.Provider value={store}>
        <Router>
          <ScrollHandler>
            <Main>
              <Switch>
                <Route
                  path="/"
                  exact
                  render={({ match, history }) => (
                    <Relationship
                      match={match}
                      history={history}
                      country={match && match.params.country}
                    />
                  )}
                />
                <Route path="/search" exact component={Explore} />
                <Route
                  path={"/deep-dives"}
                  render={() => {
                    return (
                      <React.Fragment>
                        <Route
                          path="/deep-dives/:id"
                          exact
                          component={DeepDive}
                        />
                        <Route
                          path={["/deep-dives", "/deep-dives/country/:country"]}
                          exact
                          render={({ match, history }) => (
                            <DeepDivesAll history={history} match={match} />
                          )}
                        />
                      </React.Fragment>
                    );
                  }}
                />
                <Route
                  path={["/relationship", "/relationship/:country"]}
                  exact
                  render={({ match, history }) => (
                    <Relationship
                      match={match}
                      history={history}
                      country={match && match.params.country}
                    />
                  )}
                />
                <Route
                  path="/help"
                  exact
                  render={() => (
                    <Empty>
                      <h1>Help Page</h1>
                    </Empty>
                  )}
                />
                <Route path="/page/:id" exact component={Static} />
                <Route component={FourOhFour} state={{ theme: "dark" }} />
              </Switch>
              <Switch>
                <Route path="/search" />
                <Route component={Footer} />
              </Switch>
            </Main>
          </ScrollHandler>
        </Router>
      </StoreContext.Provider>
  );
}

export default AppRouter;
