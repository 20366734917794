import React from "react";
import { CSSTransition } from "react-transition-group";
import styles from "./style.module.css";

const transitionStyles = {
  exitActive: styles.labelsActiveExit,
  enterActive: styles.labelsActiveEnter,
  enterDone: styles.labelsDoneEnter,
  exitDone: styles.labelsDoneExit
};

export default function Tile(props) {
  const {
    id = "",
    fill = "",
    clickHandler,
    d = "",
    tile,
    labelFn = id => id,
    showLabels = false,
    textColor = "white",
    onMouseEnter = () => { },
    onMouseLeave = () => { }
  } = props;
  const [y, x] = tile;

  return clickHandler ? (
    <a
      onMouseEnter={onMouseEnter.bind(this, id)}
      onMouseLeave={onMouseLeave.bind(this, id)}
      onClick={clickHandler}
    >
      {g()}
    </a>
  ) : (
      g()
    );

  function g() {
    return (
      <g>
        <path d={d} id={id} style={{ fill }} />;
        <CSSTransition
          in={showLabels}
          timeout={700}
          classNames={transitionStyles}
        >
          <text x={`${x * 10 + 1.9}%`} y={`${y * 10 + 6.5}%`} fill={textColor}>
            {labelFn(id)}
          </text>
        </CSSTransition>
      </g>
    );
  }
}
