import {
  getDocumentTypes
} from '../../lib/helpers';

import Tile from './tile_layout/tile'


const untaggedTileId = 'document-type-article';

/**
 * Find non-document-type tiles with only one item and try to pair it with other tiles
 * Mutates the tileset in place
 * @param {Tileset} tileset
 */
export function pairOrphans(tileset = {}) {
  const orphanIds = [];
  tileset.tiles.forEach((tile, key) => {
    tile.items.length === 1 && !isDocumentTag(key) && orphanIds.push(key);
  });
  orphanIds.forEach(id => {
    const [item = {}] = tileset.tiles.get(id).items, {
      tags = []
    } = item,
      target = tags
        .filter(tag => tag !== id)
        .find(tag => tileset.tiles.get(tag));
    if (!target) return;
    tileset.tile(target).add(item);
    tileset.deleteTile(id);
  });
}

/**
 * Get a tile id from inspecting the entry's properties
 * @param {Entry} entry
 * @return {string|null}
 */
export function getTileId(entry = {}) {
  const [firstMatch] = documentTags(entry);

  // add to issue or document type bucket
  if (firstMatch) return firstMatch;

  // no tags on this bad boy at all :(
  return untaggedTileId;
}

/**
 * Apply CSS class based on row context
 * @param {number} rowSize - Row.size
 * @param {number} width - initial width of tile (Tile.width)
 * @return {string}
 */
export function getGridItemClassName(rowSize = 4, width = 1) {
  const isWide = width > 1;
  if (rowSize < 4) {
    return isWide ? 'grid--item__two-thirds' : 'grid--item__third';
  }
  if (rowSize >= 4) {
    return isWide ? 'grid--item__half' : 'grid--item__quarter';
  }
}

/**
 * Add featured items to the grid
 * @param {Grid} grid
 * @param {[]Entry} featured
 */
export function addFeatured(grid = {}, entries = [], title = '') {
  let lastRow = grid.head;
  let toStart = true;
  if (!entries.length) {
    return;
  }
  const featuredTile = new Tile({
    width: 2,
    canExpand: true,
    maxWidth: 2,
    title,
    id: 'featured',
    fixed: true
  });
  entries.forEach((entry, idx) => {
    featuredTile.add(entry);
  });
  lastRow.insert(featuredTile, 0);
}

/**
 * Is the tag a documenttype?
 * @param {string} tag
 * @return {boolean}
 */
function isDocumentTag(tag = '') {
  return tag.match(/^document-type-/);
}


/**
 * Get an entry's concatenated document type and issue tags
 * @param {Entry} entry
 * @return {[]string}
 */
function documentTags(entry = {}) {
  return getDocumentTypes(entry);
}
