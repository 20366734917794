import "unfetch/polyfill";
import {DEFAULT_LANGUAGE} from "../lib/constants";

export default store => {
    store.on("@init", () => ({
        i18n: {
            getText: () => () => '',
            data: {},
            error: null,
            pending: true,
            fetched: false
        }
    }));

    store.on("i18n/fetch", async ({ i18n = {} }) => {
        const { fetched = false } = i18n;
        if (fetched) return;

        try {
            const res = await fetch(`${process.env.PUBLIC_URL}/i18n.json`)
            const data = await res.json();

            store.dispatch("i18n/save", {
                data,
                getText: (lang) => (...args) => {
                    try {
                        const loc = [...args].reduce((acc, part) => acc[part], data);
                        if (typeof loc === undefined) return ''
                        return loc[lang] || loc[DEFAULT_LANGUAGE]
                    } catch (e) {
                        return ''
                    }
                },
                pending: false,
                fetched: true,
                error: null
            });
        } catch(error) {
            console.error(error)
            return {
                data: null,
                pending: false,
                fetched: false,
                error
            }
        }
    });

    store.on("i18n/save", (_, i18n = {}) => {
        return { i18n };
    });
};
