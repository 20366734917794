import React, { useState } from "react";
import { classNames } from "../../../lib/helpers";
import styles from "./style.module.css";
import parentStyles from "../style.module.css";
import useLanguage from "../../../hooks/use_language";

const AdvancedFilterControls = (props = {}) => {
  const {
    showFilters = () => {},
    removeFilter = () => {},
    shownFilters = [],
    searchFilters = {}
  } = props;

  const [showFilterToggles, setShowFilterToggles] = useState(false);

  const i18n = useLanguage();

  return (
    <div
      className={classNames(
        parentStyles.filtersRow,
        styles.advancedFilterToggles,
        showFilterToggles ? styles.show : ""
      )}
    >
      <button onClick={() => setShowFilterToggles(!showFilterToggles)}>
        <span>{i18n("explore", "add_search_term")}</span>
        <span className={styles.flippyDoubleArrow}>&raquo;</span>
      </button>
      <div className={styles.showFilterButtons}>
        {Object.keys(searchFilters).map(key => {
          return (
            <button
              key={key}
              className={shownFilters.includes(key) ? styles.selected : ""}
              onClick={() => toggleShowFilters(key)}
            >
              {i18n("explore", "advanced_search_filters", key.toLowerCase()) ||
                key}
            </button>
          );
        })}
      </div>
    </div>
  );

  /**
   * Toggle whether filter appears in shownFilters
   * @param {string} filter
   */
  function toggleShowFilters(filter) {
    shownFilters.includes(filter) ? removeFilter(filter) : showFilters(filter);
  }
};

export default AdvancedFilterControls;
