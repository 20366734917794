export const CONTENT_TYPES_MAP = new Map([
  ["finity-data:article", "Article"],
  ["finity-data:post:tweet", "Tweet"]
]);

export const COUNTRIES = {
  AL: {
    name: "Albania",
    code: "ALB",
    inEU: false,
    slug: "albania"
  },
  AT: {
    name: "Austria",
    code: "AUT",
    inEU: true,
    slug: "austria"
  },
  BE: {
    name: "Belgium",
    code: "BEL",
    inEU: true,
    slug: "belgium"
  },
  BG: {
    name: "Bulgaria",
    code: "BGR",
    inEU: true,
    slug: "bulgaria"
  },
  BA: {
    name: "Bosnia and Herzegovina",
    code: "BIH",
    inEU: false
  },
  BY: {
    name: "Belarus",
    code: "BLR",
    inEU: false,
    slug: "belarus"
  },
  CA: {
    name: "Canada",
    code: "CAN",
    inEU: false,
    slug: "canada"
  },
  CH: {
    name: "Switzerland",
    code: "CHE",
    inEU: false,
    slug: "switzerland"
  },
  CY: {
    name: "Cyprus",
    code: "CYP",
    inEU: true,
    slug: "cyprus"
  },
  CZ: {
    name: "Czech Republic",
    code: "CZE",
    inEU: true,
    slug: "czech-republic"
  },
  DK: {
    name: "Denmark",
    code: "DNK",
    inEU: true,
    slug: "denmark"
  },
  DE: {
    name: "Germany",
    code: "DEU",
    inEU: true,
    slug: "germany"
  },
  EE: {
    name: "Estonia",
    code: "EST",
    inEU: true,
    slug: "estonia"
  },
  ES: {
    name: "Spain",
    code: "ESP",
    inEU: true,
    slug: "spain"
  },
  EU: {
    name: "European Union",
    code: "EUU",
    inEU: true,
    slug: "eu"
  },
  FI: {
    name: "Finland",
    code: "FIN",
    inEU: true,
    slug: "finland"
  },
  FR: {
    name: "France",
    code: "FRA",
    inEU: true,
    slug: "france"
  },
  GB: {
    name: "United Kingdom",
    code: "GBR",
    inEU: false,
    slug: "uk"
  },
  GR: {
    name: "Greece",
    code: "GRC",
    inEU: true,
    slug: "greece"
  },
  HU: {
    name: "Hungary",
    code: "HUN",
    inEU: true,
    slug: "hungary"
  },
  HR: {
    name: "Croatia",
    code: "HRV",
    inEU: true,
    slug: "croatia"
  },
  IR: {
    name: "Ireland",
    code: "IRL",
    inEU: true,
    slug: "ireland"
  },
  IS: {
    name: "Iceland",
    code: "ISL",
    inEU: false,
    slug: "iceland"
  },
  IT: {
    name: "Italy",
    code: "ITA",
    inEU: true,
    slug: "italy"
  },
  LV: {
    name: "Latvia",
    code: "LVA",
    inEU: true,
    slug: "latvia"
  },
  LT: {
    name: "Lithuania",
    code: "LTU",
    inEU: true,
    slug: "lithuania"
  },
  LU: {
    name: "Luxembourg",
    code: "LUX",
    inEU: true,
    slug: "luxembourg"
  },
  MK: {
    name: "Macedonia",
    code: "MKD",
    inEU: false,
    slug: "macedonia"
  },
  MD: {
    name: "Moldova",
    code: "MDA",
    inEU: false,
    slug: "moldova"
  },
  ME: {
    name: "Montenegro",
    code: "MNE",
    inEU: false,
    slug: "montenegro"
  },
  MT: {
    name: "Malta",
    code: "MLT",
    inEU: true,
    slug: "malta"
  },
  NL: {
    name: "Netherlands",
    code: "NLD",
    inEU: true,
    slug: "netherlands"
  },
  NO: {
    name: "Norway",
    code: "NOR",
    inEU: false,
    slug: "norway"
  },
  PL: {
    name: "Poland",
    code: "POL",
    inEU: true,
    slug: "poland"
  },
  PR: {
    name: "Portugal",
    code: "PRT",
    inEU: true,
    slug: "portugal"
  },
  RO: {
    name: "Romania",
    code: "ROU",
    inEU: true,
    slug: "romania"
  },
  SE: {
    name: "Sweden",
    code: "SWE",
    inEU: true,
    slug: "sweden"
  },
  RS: {
    name: "Serbia",
    code: "SRB",
    inEU: false,
    slug: "serbia"
  },
  SI: {
    name: "Slovenia",
    code: "SVN",
    inEU: true,
    slug: "slovenia"
  },
  SK: {
    name: "Slovakia",
    code: "SVK",
    inEU: true,
    slug: "slovakia"
  },
  TR: {
    name: "Turkey",
    code: "TUR",
    inEU: false,
    slug: "turkey"
  },
  UA: {
    name: "Ukraine",
    code: "URK",
    inEU: false,
    slug: "ukraine"
  },
  KS: {
    name: "Kosavo",
    code: "KOS",
    inEU: false,
    slug: "kosavo"
  },
  RU: {
    name: "Russia",
    code: "RUS",
    inEU: false,
    slug: "russia"
  },
  LI: {
    name: "Lichtenstein",
    code: "LIE",
    inEU: false,
    slug: "lichtenstein"
  },
  AD: {
    name: "Andorra",
    code: "AND",
    inEU: false,
    slug: "andorra"
  },
  SM: {
    name: "San Marino",
    code: "SMR",
    inEU: false,
    slug: "san-marino"
  }
};

export const EU_COUNTRIES = Object.keys(COUNTRIES).reduce((acc = {}, key) => {
  if (!COUNTRIES[key].inEU) {
    return acc;
  }
  acc[key] = {
    ...COUNTRIES[key]
  };
  delete acc[key].inEU;
  return acc;
}, {});

export const LANGUAGES = [
  {
    id: "en",
    name: "English",
    flag: "GB"
  },
  {
    id: "de",
    name: "German",
    flag: "DE"
  },
  {
    id: "es",
    name: "Spanish",
    flag: "ES"
  },
  {
    id: "fr",
    name: "French",
    flag: "FR"
  },
  {
    id: "it",
    name: "Italian",
    flag: "IT"
  },
  {
    id: "pl",
    name: "Polish",
    flag: "PL"
  },
  {
    id: "gr",
    name: "Greek",
    flag: "GR"
  },
  {
    id: "sw",
    name: "Swedish",
    flag: "SE"
  },
  {
    id: "du",
    name: "Dutch",
    flag: "NL"
  },
  {
    id: "bg",
    name: "Bulgarian",
    flag: "BG"
  },
  {
    id: "hu",
    name: "Hungarian",
    flag: "HU"
  },
  {
    id: "est",
    name: "Estonian",
    flag: "EE"
  },
  {
    id: "pt",
    name: "Portuguese",
    flag: "PT"
  },
];

const PROXY = "";

export const API_ROOT = `${PROXY}https://periscope.finity.app/api`;
export const SQUIRRO_ROOT = 'https://bfna.squirro.com/studio';

export const MAP_ANIMATION_TIME = 1000;

export const IMAGE_LOAD_ERROR = "imageLoadError";

export const DEFAULT_LANGUAGE = "en";

export const LANGUAGE_CACHE = "lang";

// -- Colors

export const BLUE = `hsl(196, 88%, 55%)`;

export const ACTIVITY_RANGE_COLORS = [
  "hsl(196, 88%, 100%)",
  "hsl(196, 88%, 88%)",
  "hsl(196, 88%, 79%)",
  "hsl(196, 88%, 67%)",
  BLUE
];

export const LIGHT_GREY = "#ebebec";

// -- Custom events

export const EVENT_FOCUS = "eventFocus";

// -- Tags
export const GRID_CONFIG = {
  allowDupes: false,
  articleCount: 60,
  defaultListTileCategory: "document-type-article",
  groupTilesLimit: 4
};
export const NO_CROP_TAG = "picture-no-crop";
export const MEDIA_PARENT = "document-type-media";
export const INFOGRAPHIC_TAG = "document-type-infographic";
export const VIDEO_TAG = "document-type-video";
export const AUDIO_TAG = "document-type-audio";
export const MEDIA_CHILDREN = [INFOGRAPHIC_TAG, VIDEO_TAG, AUDIO_TAG];
export const SOCIAL_MEDIA_TAG = "document-type-social-media";
