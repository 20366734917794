import React from 'react';
import styles from './style.module.css';
import { classNames } from '../../lib/helpers';

export default function Cropper(props) {
  const {
      orientation = 'horizontal',
      cropRatio = 1,
      width = 1,
      children,
      className
    } = props,
    paddingBottom = `${cropRatio * 100 * width}%`;

  return (
    <div
      className={classNames(
        styles.root,
        styles[orientation],
        className,
        styles.cropper
      )}
      style={{ paddingBottom }}
    >
      {children}
    </div>
  );
}
