import { path } from "ramda";
import "unfetch/polyfill";
import to from "await-to-js";
import * as _slugify from "slugify";
import { fetchEntry } from "./api";

export const easing = {
  linear: function(t) {
    return t;
  },
  // accelerating from zero velocity
  easeInQuad: function(t) {
    return t * t;
  },
  // decelerating to zero velocity
  easeOutQuad: function(t) {
    return t * (2 - t);
  },
  // acceleration until halfway, then deceleration
  easeInOutQuad: function(t) {
    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
  },
  // accelerating from zero velocity
  easeInCubic: function(t) {
    return t * t * t;
  },
  // decelerating to zero velocity
  easeOutCubic: function(t) {
    return --t * t * t + 1;
  },
  // acceleration until halfway, then deceleration
  easeInOutCubic: function(t) {
    return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
  },
  // accelerating from zero velocity
  easeInQuart: function(t) {
    return t * t * t * t;
  },
  // decelerating to zero velocity
  easeOutQuart: function(t) {
    return 1 - --t * t * t * t;
  },
  // acceleration until halfway, then deceleration
  easeInOutQuart: function(t) {
    return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t;
  },
  // accelerating from zero velocity
  easeInQuint: function(t) {
    return t * t * t * t * t;
  },
  // decelerating to zero velocity
  easeOutQuint: function(t) {
    return 1 + --t * t * t * t * t;
  },
  // acceleration until halfway, then deceleration
  easeInOutQuint: function(t) {
    return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t;
  }
};

export async function wait(t = 1000) {
  return await new Promise(r => setTimeout(r, t));
}

export async function getHead(url) {
  let contentLength, contentType;
  const [error, res] = await to(
    fetch(url, {
      method: "HEAD"
    })
  );
  if (error || !res.ok) {
    return {
      error: error || new Error(res.statusText),
      contentLength,
      contentType
    };
  }

  return {
    error,
    contentLength,
    contentType
  };
}

// Reduce the counts to an object of total counts by key
export const getMaxCount = (counts = {}) => {
  const values = Object.values(counts);
  if (!values.length) {
    return 1;
  }
  return values.reduce((item, acc) => (item > acc ? item : acc));
};

export function formatDate(
  date,
  options = {
    month: "long"
  }
) {
  if (!date) return "";
  const dd = date.getDate(),
    mm = date.toLocaleString("en-us", options),
    yy = date.getFullYear();
  return `${dd} ${mm} ${yy}`;
}

export function placeholderImage(color = "#888", ratio = 60) {
  return {
    background: `${color}`,
    height: "0",
    paddingBottom: `${ratio}%`
  };
}

export function articlesToArray(articles = {}) {
  return Object.keys(articles).map(id => {
    return Object.assign(articles[id], {
      id
    });
  });
}

export function classNames(...names) {
  return [...names].join(" ");
}

export function toggleInArray(arr, str) {
  if (arr.includes(str)) {
    return arr.filter(item => item !== str);
  }
  return arr.concat(str);
}

export function dereferenceArticle(collection = {}, ...ids) {
  return ids.map(id => {
    const out = collection[id];
    if (!out) {
      throw new Error(`Could not find article ${id}`);
    }
    return Object.assign(out, {
      id
    });
  });
}

export function dereferenceArticles(collection = {}, ids = []) {
  return dereferenceArticle(collection, ...ids);
}

export function getImageUrlFromEntity(entity = {}) {
  return path(["views", "large", "url"], entity);
}

export function animateLowFrameRate(fn, time = 20, duration = 1000) {
  let elapsed = 0;
  const interval = setInterval(() => {
    elapsed += time;
    fn(elapsed / duration);
    if (elapsed >= duration) {
      clearInterval(interval);
    }
  }, time);
}

export function animate(fn, duration = 1000, easeFn, pause = 0) {
  if (!performance || !requestAnimationFrame) {
    throw new Error(
      "animate requires window.performance and window.requestAnimationFrame"
    );
  }
  let start;
  const total = duration;
  let elapsed;

  function tick(now) {
    elapsed = now - start;
    const progress = getProgress();
    fn(easeFn(progress));
    if (progress < 1) requestAnimationFrame(tick);
  }

  function getProgress() {
    return Math.max(Math.min(elapsed / total, 1), 0);
  }

  setTimeout(() => {
    start = performance.now();
    tick(start);
  }, pause);
}

export function elipse(text = "", limit = 25) {
  const words = text.split(" "),
    elipse = words.length > limit ? `...` : "";

  return words
    .slice(0, limit)
    .join(" ")
    .concat(elipse);
}

/**
 * Create a lowercase slug of a string
 * @param {string} str
 * @param {string}
 */
export function slugify(str = "") {
  return _slugify(str, {
    lower: true
  });
}

/**
 * Create or push an item to an object property
 * Mutates the object!
 * @param {object} obj
 * @param {any} data
 * @param {string} address
 */
export function upsertToObject(obj = {}, data, address = "") {
  obj[address] = obj[address] ? obj[address].concat(data) : [data];
}

/**
 * Get a red or green value based on a score from 1-10
 * @param {number} score - between 0-10
 * @return {string} hsl value
 */
export function tileFillColorFromScore(score = 5) {
  if (score < 0 || score > 10) {
    throw new Error(`score must be between 0 and 10. Received ${score}`);
  }
  const green = [38, 222, 129],
    red = [252, 92, 102],
    neutral = [209, 216, 224],
    target = score > 5 ? green : red,
    multiplier = Math.abs(score - 5) / 5,
    color = target.map((val, idx) => step(neutral[idx], val, multiplier)),
    [r, g, b] = color;
  return `rgb(${r}, ${g}, ${b})`;

  function step(start, end, multiplier) {
    const out = start + (end - start) * multiplier;
    return Math.ceil(out);
  }
}

/**
 * Is debug mode set to true
 * @return {boolean}
 */
export function isDebug() {
  return (
    window &&
    window.localStorage &&
    window.localStorage.getItem("debug") === "true"
  );
}

/**
 * Find an issue tag in an array of tags
 * @param {object} obj
 * @param {[]string} obj.tags
 * @return {[]string}
 */
export function getIssues({ tags = [] }) {
  return findTagByTagType(tags, /^issue-/);
}

/**
 * Find a document type tag in an array of tags
 * Filter out media tags
 * @param {object} obj
 * @param {[]string} obj.tags
 * @return {[]string}
 */
export function getDocumentTypes({ tags = [] }) {
  return findTagByTagType(tags, /^document-type-/);
}

/**
 * Get the min/max property of a data set
 * @param {[]object} data
 * @param {string} property
 * @returns {[]number} - length of 2; min/max values
 */
export function minMaxProperty(data = [], property = "") {
  return data.reduce((acc, item) => {
    if (!acc[0] || item[property] < acc[0]) acc[0] = item[property];
    if (!acc[1] || item[property] > acc[1]) acc[1] = item[property];
    return acc;
  }, []);
}
/**
 * Group data by groupSize
 * @param {[]any} items
 * @param {number} groupSize
 * @return {[]Array}
 */
export function groupByGroupSize(items = [], groupSize = 1) {
  return items.reduce((acc, item, idx) => {
    const address = Math.floor(idx / groupSize);
    acc[address] = acc[address] ? acc[address].concat(item) : [item];
    return acc;
  }, []);
}

/**
 * Fetch entry by ID based on window hash
 * @return {[error, Entry]}
 */
export async function fetchEntryFromHash(hash = "") {
  if (!hash) {
    return [null, null];
  }
  return await fetchEntry(hash);
}

/**
 * Is the string a URN
 * @param {string|null}
 * @return {boolean}
 */
export function isUrn(str = "") {
  if (!str) return false;
  return str.match(/^urn:/);
}

/**
 * Find first matching tag with prefix in an array of tags
 * @param {[]string} tags
 * @param {regex} regex
 * @return {[]string}
 */
function findTagByTagType(tags = [], regex) {
  return tags.filter(tag => tag.match(regex));
}
