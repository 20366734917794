import React from 'react';
import styles from './style.module.css';
import { classNames } from '../../lib/helpers';

export default function(props) {
  const {
    size = 0,
    current = 0,
    goTo = () => {},
    variant = '',
    className
  } = props;

  return (
    <ul className={classNames(styles.pagination, styles[variant], className)}>
      {getPages().map(page => {
        const isCurrent = page === current;
        return (
          <li key={page}>
            <button
              className={isCurrent ? styles.active : ''}
              onClick={() => goTo(page)}
            >
              {page}
            </button>
          </li>
        );
      })}
    </ul>
  );

  function getPages() {
    const out = [];
    for (let x = 0; x < size; x++) {
      out.push(x);
    }
    return out;
  }
}
