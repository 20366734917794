import React, { useState } from 'react';

// -- Libs
import { fetchEntryFromHash, isUrn } from '../../lib/helpers';

// -- Components
import Modal from '../Modal';
import ExpandedItem from '../ExpandedItem';

// -- Hooks
import useHashChange from '../../hooks/use_hash_change';

export default function() {
  const [modalContent, setModalContent] = useState(null);

  useHashChange(async hash => {
    // if (!isUrn(hash)) return setModalContent(null);
    const [error, entry = {}] = await fetchEntryFromHash(hash);
    if (error) {
      return console.error(error);
    }
    setModalContent(entry);
  });

  return (
    <Modal close={closeModal}>
      {modalContent && (
        <ExpandedItem {...modalContent} value={<p>{modalContent.value}</p>} />
      )}
    </Modal>
  );

  function closeModal() {
    const scrollPosition = window.scrollY;
    window.location.hash = '';
    window.scrollTo(window.scrollX, scrollPosition);
  }
}
