import React, {useEffect} from "react";
import useLanguage from "../../hooks/use_language";
import { ancillary, shareIcons } from "./style.module.css";
import { Link } from "react-router-dom";
import Icon from "../Icons";
import useStoreon from "storeon/react";

export default React.memo(function (props) {
  const i18n = useLanguage();
  const location = window.location.href;
  const { staticPages = [], settings, dispatch } = useStoreon("staticPages", "settings");
  const { data = [], pending = false } = staticPages;

  useEffect(() => {
    dispatch("pages/fetch");
  }, [settings.lang]);

  const legalPages = data.filter((page) => {
    return page.tags.find(tag => tag === 'static-page-type-legal')
  })
  
  return (
    <div className={ancillary}>
      <div>
        <h3>{i18n("footer", "share")}</h3>
        <div className={shareIcons}>
          <a
            target="_blank"
            href={`https://twitter.com/intent/tweet?url=${location}&text=`}
            rel="noopener noreferrer"
          >
            <Icon name="Twitter" title="Twitter" />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://www.facebook.com/sharer/sharer.php?u=${location}`}
          >
            <Icon name="Facebook" title="Facebook" />
          </a>
        </div>
      </div>
      <p>
        {legalPages.map(({name, id}, idx) => {
          return <React.Fragment key={id}>
            <Link to={`/page/${id}`} key={id}>{name}</Link>
            {legalPages[idx+1] ? ' | ' : null}
          </React.Fragment>
        })}
      </p>
    </div>
  );
});
