import React from 'react';
import styles from './style.module.css';
import { classNames } from '../../lib/helpers';
import { CSSTransition } from 'react-transition-group';
import fade from '../../styles/transitions/fade.module.css';

export default function(props) {
  const {
    children,
    target,
    topOffset = 0,
    timeout = 0,
    className = '',
    transitionClassNames = fade
  } = props;
  let top, left;

  if (target) {
    [left, top] = getPosition();
  }

  // -- Render
  return (
    <CSSTransition
      in={show()}
      timeout={timeout}
      classNames={transitionClassNames}
      mountOnEnter
    >
      <div
        style={{
          top: `${top}px`,
          left: `${left}px`
        }}
        className={classNames(styles.root, className)}
      >
        <div>{children}</div>
      </div>
    </CSSTransition>
  );

  function show() {
    return !!children && !!top && !!left;
  }

  /**
   * Get the left and top position of the popup relative to the target element
   * @returns {[]string}
   */
  function getPosition() {
    const rect = target.getBoundingClientRect(),
      { top, bottom, left, right } = rect;

    return [
      Math.floor((right + left) * 0.5),
      Math.floor((top + bottom + topOffset) * 0.5)
    ];
  }
}
