import React, { useEffect, useState } from "react";

// -- Libs
import { GRID_CONFIG } from "../../lib/constants";
import { wait } from "../../lib/helpers";
import { fetchEntries, fetchStoryGroups } from "../../lib/api";

// -- hook
import useStoreon from 'storeon/react'

const contentInitial = {
  featured: [],
  deepdives: [],
  rest: []
};

export default Component => props => {
  const {
    relatedStoryGroupTag = "",
    delay = 0,
    socialMediaTag = "",
    featuredTag = "",
    publishedTag = "",
    id=""
  } = props;
  const { dispatch } = useStoreon("lastUpdated")
  const [content={}, setContent] = useState(contentInitial),
    [error, setError] = useState(null),
    [loaded, setLoaded] = useState(false);

  useEffect(() => {
    resetState();
    if (!publishedTag) return;
    fetch();
  }, [publishedTag]);

  useEffect(() => {
    if (!loaded) {
      return;
    }
    const { featured, rest, deepdives } = content
    const latest = [...featured, ...rest, ...deepdives].reduce((acc, { date }) => {
      return date > acc ? date : acc;
    }, null)

    dispatch("lastUpdated/update", {id, date: new Date(latest)})
  }, [loaded, content]);

  return (
    <Component
      {...props}
      featured={content.featured}
      tweets={content.tweets}
      deepdives={content.deepdives}
      rest={content.rest}
      error={error}
      loaded={loaded}
    />
  );

  function resetState() {
    setLoaded(false);
    setContent(contentInitial);
    setError(null);
  }

  async function fetch() {
    // Fetch content for next state
    const [error, results] = await fetchContent();

    if (error) {
      setError(error);
    }

    setContent(results);
    setLoaded(true);
  }

  /**
   * Fetch relevant content, marshal content
   * @returns {[]error|object}
   */
  async function fetchContent() {
    let error;
    await wait(delay);
    const [
      [entriesErr, entries = []],
      [deepdivesErr, deepdives = []]
    ] = [
      await fetchStoryGroupEntries(publishedTag),
      relatedStoryGroupTag
        ? await fetchStoryGroups({
            tags: [relatedStoryGroupTag],
            types: ["deepdive"]
          })
        : await new Promise(res => res([]))
    ];
    error = entriesErr || deepdivesErr;
    // Create a tile set
    // Organize all the entries into buckets so they can later
    // be displayed as grid tiles
    const { featured = [], rest = [] } = entries.reduce(separateFeatured, {
      featured: [],
      rest: []
    });

    return [
      error,
      {
        featured,
        deepdives,
        rest
      }
    ];
  }

  /**
   * Fetch the content for a story-group
   * @param {...string} tags
   * @return {[]Entry}
   */
  async function fetchStoryGroupEntries(...tags) {
    const params = {
      tags,
      limit: GRID_CONFIG.articleCount,
      offset: 0,
      "sort-script-id": "relevancy"
    };
    return await fetchEntries(params);
  }

  /**
   * Reduce which returns an object separating entries by featued
   * @param {object} acc
   * @param {Entry} entry
   * @return {object}
   */
  function separateFeatured(acc, entry) {
    const { tags = [] } = entry,
      isFeatured = tags.some(tag => tag === featuredTag);
    if (isFeatured) {
      acc.featured.push(entry);
      return acc;
    }
    acc.rest.push(entry);
    return acc;
  }
};
