import React, { useEffect, useRef, useState } from "react";
import styles from "./style.module.css";
import { classNames } from "../../lib/helpers";
import { IMAGE_LOAD_ERROR } from "../../lib/constants";
import Cropper from "./Cropper";

/**
 * General purpose image component
 * Supports lazy load
 * Removes itself if it detects an error trying to load
 * @param {object} props
 * @param {string} props.src - image src
 * @param {string} props.orientation - horizontal|vertical
 * @param {number} props.cropRatio - must be 0-1; width:height ratio of image
 * @param {number} props.width - must be 0-1; represents the width of its parent container. 1 means the image takes up the full width of the container
 * @param {string} props.alt
 * @param {boolean} props.lazy - is the image meant to lazy load. Defaults to true
 * @param {string} props.className
 * @param {string} props.background - CSS background color
 *
 */
export default React.memo(function(props) {
  const {
      src = "",
      orientation = "horizontal",
      cropRatio = 1,
      width = 1,
      alt = "",
      lazy = true,
      className = "",
      noCrop = false
    } = props,
    _src = lazy ? "" : src,
    dataSrc = lazy ? src : src;

  const root = useRef(null);
  const [error, setError] = useState(false);

  // Removes the component from the DOM if lazyLoad detects an error (like 404)
  // Listen for imageLoadError and set error to true
  useEffect(() => {
    if (!root.current) return;
    root.current.addEventListener(IMAGE_LOAD_ERROR, handleImageLoadError);
    return () => {
      if (!root.current) return;
      root.current.removeEventListener(IMAGE_LOAD_ERROR, handleImageLoadError);
    };
  }, [root.current]);

  const image = (
      <img
        data-src={dataSrc}
        alt={alt}
        src={_src}
        ref={root}
        className={lazy ? styles.lazy : ""}
      />
    ),
    rootClasses = classNames(styles.root, className);

  // -- Render
  if (!src || error) return "";

  return noCrop ? (
    <div className={rootClasses}>{image}</div>
  ) : (
    <Cropper
      orientation={orientation}
      cropRatio={cropRatio}
      width={width}
      className={className}
    >
      {image}
    </Cropper>
  );

  /**
   * sets error to true
   */
  function handleImageLoadError() {
    setError(true);
  }
});
