import React, { useEffect } from "react";
import useStoreon from "storeon/react";
import styles from "./style.module.css";
import { Route, Switch } from "react-router-dom";

// -- Components
import Header from "../Header";
import Submenu from "../Submenu";

// -- HOC
import withIntro from "../../hoc/with_intro";

export default withIntro(function (props) {
  const { children } = props,
    { dispatch } = useStoreon("tags", "settings");

  // -- Fetch application wide data
  useEffect(() => {
    dispatch("tags/fetch");
    dispatch("i18n/fetch");
  }, [dispatch]);

  return (
    <div className={styles.root}>
      <Header menu />
      <Switch>
        <Route path={["/search", "/page/*"]} exact />
        <Route render={() => <Submenu sticky breadcrumb />} />
      </Switch>
      {children}
    </div>
  );
});
