import React, { useEffect } from 'react';
import Masonry from 'react-masonry-css';
import styles from './style.module.css';
import { loadImages } from '../../lib/images';
import { wait, classNames } from '../../lib/helpers';

// -- Components
import { useInView } from 'react-intersection-observer';
import SingleItem from '../SingleItem';
import Loading from '../Loading';

// -- Hooks
import useLanguage from '../../hooks/use_language';

export default React.memo(function (props) {
  const {
    showType = true,
    entries = [],
    className = '',
    onScrollEnd = () => { },
    isLoaded = false,
    enabled = false,
    noResults = <NoResults />
  } = props;


  const [bottomRef, isAtBottom] = useInView({});

  useEffect(() => {
    if (!isAtBottom) return;
    onScrollEnd();
  }, [isAtBottom]);

  useEffect(() => {
    callLoadImages();
  }, [entries.length]);

  const breakpointCols = {
    default: 3,
    950: 3,
    700: 2,
    500: 1
  };

  const isNoResults = entries.length === 0 && isLoaded


  return (
    <div className={classNames(className, styles.root)}>
      {isNoResults ? noResults :
        <Masonry
          className={styles.grid}
          columnClassName={styles.column}
          breakpointCols={breakpointCols}>
          {entries.map(data => {
            const { id = '', __type = '', contentType = ''} = data;
            data.img = {} // suppress images
            data.value = data.body;
            return (
              <SingleItem data={data} size={2} key={id}>
                {showType && (
                  <header>
                    <h3 className="tileHeader">{contentType}</h3>
                  </header>
                )}
              </SingleItem>
            );
          })}
        </Masonry>}
      {isLoaded || <Loading className={styles.loadingBar} />}
      {
        <div
          style={{ display: enabled ? 'block' : 'none' }}
          ref={bottomRef}
          className={styles.collectionEnd}
        />
      }
    </div>
  );

  async function callLoadImages() {
    await wait(300);
    loadImages();
  }
});

const NoResults = () => {
  const i18n = useLanguage();
  return <div className={styles.noResults}>{i18n('no_results')}</div>
}