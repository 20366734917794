import React from 'react'
import Helmet from 'react-helmet'
import useLanguage from '../../hooks/use_language';

const Meta = ({ title: titleProp, description: descriptionProp }) => {

    const i18n = useLanguage();
    const description = descriptionProp || i18n("meta", "intro", "description")
    const title = `${i18n("site_title")} - ${titleProp}`

    return <Helmet>
        <title>{title}</title>
        <meta itemprop="name" content={title} />
        <meta name="og:title" content={title} />
        <meta itemprop="description" content={description} />
        <meta name="twitter:card" content={description} />
        <meta name="twitter:description" content={description} />
        <meta name="og:description" content={description} />
    </Helmet>
}

export default Meta