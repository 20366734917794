import React from 'react';
import { Link } from 'react-router-dom';

export default React.memo(function (props) {
  const { to = '', target = '', children, className = '' } = props;

  if (!to) return <div>{children}</div>;

  const isExternal = to === '' || to.includes('http'),
    isAnchor = to.match(/^#/);

  const internal = (
    <Link to={to} className={className}>
      {children}
    </Link>
  ),
    standard = (
      <a
        target={target}
        style={{ color: 'inherit' }}
        href={to}
        className={className}
      >
        {children}
      </a>
    );

  return isExternal || isAnchor ? standard : internal;
});
