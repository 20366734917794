import React from 'react';
import styles from './style.module.css';

// -- Lib
import { elipse } from '../../lib/helpers';

// -- Components
import Link from '../Link';
import Thumbnail from './Thumbnail';
import Sourceband from './Sourceband';

export default function MediumTeaser(props) {
  const {
    title = '',
    date = '',
    value = '',
    provider = '',
    view = {},
    providerIcon = '',
    link = '',
    noCrop = false,
    mediaTypeIcon = ''
  } = props;
  return (
    <Link to={link}>
      <Sourceband
        provider={provider}
        providerIcon={providerIcon}
        date={date}
        mediaTypeIcon={mediaTypeIcon}
      />
      <div className={styles.content}>
        {view && <Thumbnail view={view} alt={title} noCrop={noCrop} />}
        {title && <h3 className={styles.title}>{elipse(title, 20)}</h3>}
        {value && <p>{elipse(value, 80)}</p>}
      </div>
    </Link>
  );
}
