import React, { useState } from "react";
import Intro from "../components/Intro";
import useStoreon from "storeon/react";

const videoUrl = `${process.env.PUBLIC_URL}/intro_bg.mp4`;

export default (Component) => (props) => {
  const { settings, dispatch } = useStoreon("settings"),
    [showIntro, setShowIntro] = useState(!settings.blockIntro);

  return showIntro ? (
    <Intro close={close} videoUrl={videoUrl} />
  ) : (
    <Component {...props} />
  );

  /**
   * Close the intro; scroll to top of page
   * @param {boolean} showAgain - if false, sets localstorage to suppress it again
   */
  function close(showAgain) {
    dispatch("settings/hide-intro", !showAgain);
    setShowIntro(false);
    window.scrollTo(0, 0);
  }
};
