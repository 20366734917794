import React from "react";
import useLanguage from "../../hooks/use_language";
import styles from "./style.module.css";

export default function () {
  const i18n = useLanguage();
  return (
    <div className={styles.signup}>
      <h3>{i18n("footer", "stay_informed")}</h3>
      <div id="mc_embed_signup">
        <form
          action="https://bfna.us20.list-manage.com/subscribe/post?u=53fc7e266c23506906a0a602f&amp;id=4cd7254e93"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          target="_blank"
          noValidate
        >
          <div>
            <input
              type="submit"
              value="Subscribe"
              name="subscribe"
              id="mc-embedded-subscribe"
            />
          </div>
        </form>
      </div>
    </div>
  );
}
