import React, { useEffect, useMemo } from "react";

// -- Libs
import { ACTIVITY_RANGE_COLORS, BLUE } from "../../lib/constants";
import { getMaxCount } from "../../lib/helpers";

// -- Modules
import { Link } from "react-router-dom";
import Map, { ZoomOutButton } from "../../components/Map";
import MapKeyRange from "../../components/MapKeyRange";

// -- Hooks
import useLanguage from "../../hooks/use_language";
import useStoreon from "storeon/react";

function DeepDivesMap(props = {}) {
  const { countries = [], onTileClick = () => {}, className = "" } = props;

  const i18n = useLanguage(),
    { deepdiveCounts = {}, dispatch } = useStoreon(
      "deepdives",
      "deepdiveCounts"
    ),
    { data = {} } = deepdiveCounts;

  useEffect(() => {
    dispatch("deepdives/counts/fetch");
  }, []);

  const maxCount = useMemo(() => getMaxCount(data), [Object.keys(data).length]);

  return (
    <Map
      countries={countries}
      tileClickHandler={onTileClick}
      geographyActiveFill={BLUE}
      className={className}
      tileFill={tileMapFill}
    >
      {isFocused() && (
        <>
          <Link to={"/deep-dives"}>
            <ZoomOutButton />
          </Link>
        </>
      )}
      {isFocused() || (
        <MapKeyRange
          leftLabel={i18n("map_keys", "less_active")}
          rightLabel={i18n("map_keys", "more_active")}
          colors={ACTIVITY_RANGE_COLORS}
        />
      )}
    </Map>
  );

  /**
   * Are any countries passed in props (is map in focused state)
   * @return {bool}
   */
  function isFocused() {
    return countries.length > 0;
  }

  function tileMapFill(countryId = 0, inEU) {
    const score = data[countryId] || 0,
      pct = score / maxCount || 0,
      lightness = 100 - pct * 45;

    if (!inEU) {
      return false;
    }
    return `hsl(196, 88%, ${lightness}%)`;
  }
}

export default DeepDivesMap;
