import React from 'react';
import styles from './style.module.css';

export default React.memo(function(props) {
  const { leftLabel = '', rightLabel = '', colors = [] } = props;
  return (
    <div className={styles.root}>
      <span className={styles.label}>{leftLabel}</span>
      <div className={styles.colors}>
        {colors.map((color = '') => (
          <div style={{ background: color }} key={color} />
        ))}
      </div>
      <span className={styles.label}>{rightLabel}</span>
    </div>
  );
});
