import React from "react";
import PagesMenu from "../PagesMenu";
import styles from "./style.module.css";
import { classNames } from "../../lib/helpers";
import useLanguage from "../../hooks/use_language";
import Ancillary from "./Ancillary";
import Signup from "./Signup";

export { Ancillary, Signup };

export default function (props) {
  const { helpEmail = "sara.leming@bfna.org" } = props;

  const i18n = useLanguage();

  return (
    <footer className={classNames(styles.root)}>
      <aside className={classNames(styles.questions)}>
        {`${i18n("footer", "questions")} `}
        <a href={`mailto:${helpEmail}`}>{i18n("footer", "let_us_know")}</a>
      </aside>
      <div className={classNames(styles.columns, "constrain")}>
        <PagesMenu
          activeClassName={styles.active}
          className={styles.pagesMenu}
        />
        <div>
          <Signup />
          <Ancillary />
        </div>
      </div>

      <div className={classNames(styles.copyright)}>
        <span>
          &copy; {new Date().getFullYear()} {i18n("title")}
        </span>
        <a>{i18n("foundation")} </a>
      </div>
    </footer>
  );
}
