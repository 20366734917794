import React, { useEffect } from 'react';
import { formatDate, elipse } from '../../lib/helpers';
import styles from './style.module.css';
import { useInView } from 'react-intersection-observer';
import Link from '../Link';

export default function(props) {
  const {
    date,
    title = '',
    text = '',
    className = '',
    id = '',
    link = '',
    onIntersected = () => {}
  } = props;
  const [ref, isInView] = useInView({
    threshold: 1.0
  });

  useEffect(() => {
    isInView && onIntersected(date);
  }, [isInView]);

  const content = (
    <React.Fragment>
      <span className={styles.date}>{formatDate(date)}</span>
      <h3 className={styles.title}>{title}</h3>
      <p className={styles.text}>{elipse(text, 16)}</p>
    </React.Fragment>
  );

  return (
    <li data-id={id} className={className} ref={ref}>
      {link ? (
        <Link to={link} className={styles.eventA}>
          {content}
        </Link>
      ) : (
        content
      )}
    </li>
  );
}
