import { useEffect } from 'react';

export default function(disable=false, deps=[]) {
  useEffect(() => {
    if (!disable) return
    const bodyClass = document.querySelector('body').classList;
    bodyClass.add('modal-is-open');
    return () => bodyClass.remove('modal-is-open');
  }, [disable]);
}


