import React from 'react';
import styles from './style.module.css';
import {classNames} from '../../lib/helpers'


export default function(props) {
    const {
        size = 0,
        current = 0,
        onNext = () => {},
        onPrevious = () => {},
        className
    } = props;


    return (
        <div className={classNames(styles.paginationTwo, className)}>
            <button onClick={onPrevious} disabled={current <= 0}>
                <div className={classNames(styles.nextPrevious, styles.previous)}/>
            </button>
            <button onClick={onNext} disabled={current >= size-1}>
                <div className={classNames(styles.nextPrevious, styles.next)}/>
            </button>
        </div>
    );
}
