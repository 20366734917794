import React from 'react';
import styles from './style.module.css';
import { classNames, groupByGroupSize } from '../../lib/helpers';

// -- Components
import Carousel from '../Carousel';
import SingleItem from '../SingleItem';
import { Pagination2 as Pagination } from '../CarouselControls';

export default function List({
  className = '',
  children,
  groupSize = 1,
  perpage = 5,
  items = [],
  size = 0,
  isWide = false
}) {
  const slides = groupByGroupSize(items, perpage);
  return (
    <aside className={[styles.root, className].join(' ')}>
      <Carousel
        slides={slides.map((collection, idx) => {
          return (
            <ul
              key={idx}
              className={classNames(
                styles.itemList,
                styles[`width-${groupSize}`]
              )}
            >
              {collection.map(item => {
                return (
                  <li key={item.id}>
                    <SingleItem
                      data={item}
                      size={size}
                      link={`#${item.id}`}
                      isWide={isWide}
                    />
                  </li>
                );
              })}
            </ul>
          );
        })}
        render={(slides, { goTo, current, size }) => {
          return (
            <React.Fragment>
              {size > 1 && (
                <Pagination
                  size={size}
                  current={current}
                  className={styles.pagination}
                  onNext={() => goTo(current + 1)}
                  onPrevious={() => goTo(current - 1)}
                />
              )}
              <div className={styles.bg}>
                  <header>{children}</header>
                  {slides}
              </div>
            </React.Fragment>
          );
        }}
      />
    </aside>
  );
}
