import useStoreon from "storeon/react";
import { useEffect } from "react";

export default function useTheme(theme = "light") {
  const { dispatch } = useStoreon();

  useEffect(() => {
    dispatch("setTheme", theme);
  }, [theme]);
}
