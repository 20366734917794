import React from "react";

export default React.memo(function (props) {
  const {
    name = "",
    title: titleProp = "",
    className = "",
    style = {},
  } = props;
  const title = titleProp ? <title>{titleProp}</title> : "";
  switch (name) {
    case "featured":
      return (
        <svg version="1.1" x="0px" y="0px" viewBox="0 0 16 16" {...props}>
          <g>
            <g>
              <path
                d="M3.87,8c0,0.65,0.03,1.33,0.1,2.06H0.29C0.1,9.35,0,8.67,0,8s0.1-1.35,0.29-2.06h3.68
         C3.9,6.65,3.87,7.33,3.87,8z M5.71,0.32C4.94,1.4,4.4,2.92,4.1,4.9H0.61c0.47-1.1,1.16-2.05,2.05-2.85S4.57,0.67,5.71,0.32z
          M0.61,11.1H4.1c0.32,1.98,0.86,3.5,1.61,4.58c-1.14-0.34-2.16-0.92-3.05-1.73C1.77,13.15,1.09,12.19,0.61,11.1z M4.9,8
         c0-0.69,0.03-1.38,0.1-2.06h6c0.06,0.69,0.1,1.38,0.1,2.06s-0.03,1.38-0.1,2.06H5C4.94,9.38,4.9,8.69,4.9,8z M10.87,4.9H5.16
         C5.4,3.44,5.77,2.26,6.29,1.35S7.38,0,8,0s1.19,0.45,1.71,1.35C10.23,2.26,10.61,3.44,10.87,4.9z M5.16,11.1h5.68
         c-0.24,1.46-0.61,2.65-1.13,3.55C9.19,15.55,8.62,16,8,16s-1.19-0.45-1.71-1.35S5.4,12.56,5.16,11.1z M15.39,4.9H11.9
         c-0.32-1.98-0.86-3.5-1.61-4.58c1.14,0.34,2.16,0.92,3.05,1.73S14.91,3.81,15.39,4.9z M10.29,15.68c0.77-1.08,1.31-2.6,1.61-4.58
         h3.48c-0.47,1.1-1.16,2.05-2.05,2.85C12.45,14.76,11.43,15.33,10.29,15.68z M15.71,5.94C15.9,6.62,16,7.31,16,8
         s-0.1,1.38-0.29,2.06h-3.68c0.06-0.71,0.1-1.4,0.1-2.06s-0.03-1.35-0.1-2.06H15.71z"
              />
            </g>
          </g>
        </svg>
      );
    case "dashboard":
      return (
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 28 16"
        >
          <g>
            <polygon points="0,14 4,14 4,13 1,13 1,3 4,3 4,2 0,2 	" />
            <polygon points="24,2 24,3 27,3 27,13 24,13 24,14 28,14 28,2 	" />
            <path d="M6,16h16V0H6V16z M7,1h14v14H7V1z" />
          </g>
        </svg>
      );
    case "infographic":
      return (
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 13 10"
          className={className}
          style={style}
        >
          <g>
            <rect y="9" width="13" height="1" />
            <g>
              <rect x="0" y="3" width="1" height="5" />
              <rect x="3" y="4" width="1" height="4" />
              <rect x="12" y="1" width="1" height="7" />
              <rect x="9" width="1" height="8" />
              <rect x="6" y="2" width="1" height="6" />
            </g>
          </g>
        </svg>
      );
    case "video":
      return (
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 13 10"
          className={className}
          style={style}
        >
          <g>
            <g>
              <polygon points="8.5,10 0,10 0,0 8.5,0 8.5,2.5 7.5,2.5 7.5,1 1,1 1,9 7.5,9 7.5,7.5 8.5,7.5 		" />
            </g>
            <g>
              <path d="M12,2.61v4.78L9.08,5L12,2.61 M13,0.5L7.5,5L13,9.5V0.5L13,0.5z" />
            </g>
          </g>
        </svg>
      );
    case "audio":
      return (
        <svg
          version="1.1"
          x="0px"
          y="0px"
          viewBox="0 0 13 10"
          className={className}
          style={style}
        >
          <g>
            <polygon points="0,2 0,8 3,8 7,10 7,7 6,7 6,8.38 3.24,7 1,7 1,3 3.24,3 6,1.62 6,3 7,3 7,0 3,2 	" />
            <path d="M10,5c0-1.1-0.9-2-2-2v1c0.55,0,1,0.45,1,1c0,0.55-0.45,1-1,1v1C9.1,7,10,6.1,10,5z" />
            <path d="M8,0v1c2.21,0,4,1.79,4,4s-1.79,4-4,4v1c2.76,0,5-2.24,5-5C13,2.24,10.76,0,8,0z" />
          </g>
        </svg>
      );
    case "relationship":
      return (
        <svg
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 28 16"
          className={className}
          style={style}
        >
          {title}
          <path d="M8 15H1V1h7c1.87 0 3.63.73 4.95 2.05L17.9 8l-2.49 2.49.71.71L19.31 8l-5.66-5.66A7.973 7.973 0 0 0 8 0H0v16h8c1.66 0 3.2-.51 4.48-1.37l-.72-.72A7.05 7.05 0 0 1 8 15z" />
          <path d="M20 0c-1.66 0-3.2.51-4.48 1.37l.72.72A7.05 7.05 0 0 1 20 1h7v14h-7a6.96 6.96 0 0 1-4.95-2.05L10.1 8l2.49-2.49-.71-.71L8.69 8l5.66 5.66A7.973 7.973 0 0 0 20 16h8V0h-8z" />
        </svg>
      );
    case "deepdive":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 28 16"
          className={className}
          style={style}
        >
          {title}
          <path d="M0 0v16h28V0H0zm27 7.5h-5.84v1H27V15H14.5v-2.81h-1V15H1V8.5h5.84v-1H1V1h12.5v2.81h1V1H27v6.5z" />
          <path d="M8.84 5.81v4.39h10.33V5.81H8.84zm9.32 3.38H9.84V6.81h8.33v2.38z" />
        </svg>
      );
    case "explore":
      return (
        <svg
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 28 16"
          className={className}
          style={style}
        >
          {title}
          <path d="M0 0v16h28V0H0zm27 15H1V1h26v14z" />
          <path d="M5.5 12.21l.79.79H7.5v-1h-.79L6 11.29V4.71L6.71 4h.79V3H6.29l-.79.79L4.71 3H3.5v1h.79l.71.71v6.58l-.71.71H3.5v1h1.21z" />
        </svg>
      );
    case "Twitter":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          className={className}
          style={style}
        >
          {title}
          <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
        </svg>
      );
    case "Facebook":
      return (
        <svg
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="61"
          height="61"
          viewBox="0 0 60.734 61"
        >
          <g>
            <path
              d="M57.378,0.001H3.352C1.502,0.001,0,1.5,0,3.353v54.026c0,1.853,1.502,3.354,3.352,3.354h29.086V37.214h-7.914v-9.167h7.914
         v-6.76c0-7.843,4.789-12.116,11.787-12.116c3.355,0,6.232,0.251,7.071,0.36v8.198l-4.854,0.002c-3.805,0-4.539,1.809-4.539,4.462
         v5.851h9.078l-1.187,9.166h-7.892v23.52h15.475c1.852,0,3.355-1.503,3.355-3.351V3.351C60.731,1.5,59.23,0.001,57.378,0.001z"
            />
          </g>
        </svg>
      );

    case "logo":
      return (
        <svg
          version="1.1"
          x="0px"
          y="0px"
          viewBox="0 0 302.01 33.72"
          className={className}
          style={style}
        >
          {title}
          <path
            d="M302.01,0v33.72H0v-1.01h175.33V1.01H0V0H302.01z M9.58,29.13V6.6h4.44V4.83H2.71V6.6H7.3v22.53H9.58z
	 M18.97,29.13V17.19h3.81l3.57,11.94h2.22l-3.72-12.42c0.88-0.18,1.56-0.55,2.04-1.11c0.48-0.56,0.82-1.24,1.02-2.04
	c0.2-0.8,0.3-1.65,0.3-2.55c0-1.28-0.19-2.38-0.57-3.31c-0.38-0.93-1-1.64-1.88-2.13c-0.87-0.49-2.07-0.73-3.58-0.73h-5.49v24.3
	H18.97z M18.97,6.54h3.15c1.54,0,2.57,0.37,3.09,1.11c0.52,0.74,0.78,1.86,0.78,3.36c0,1-0.12,1.83-0.36,2.49
	c-0.24,0.66-0.64,1.16-1.2,1.5c-0.56,0.34-1.35,0.51-2.37,0.51h-3.09V6.54z M33.22,29.13l1.23-6.51h5.79l1.2,6.51h2.25l-5.16-24.3
	h-2.4L31,29.13H33.22z M37.33,7.86l2.61,13.11h-5.16L37.33,7.86z M48.49,29.13V10.05l7.8,19.08h1.62V4.83h-1.98V23.7L48.19,4.83
	h-1.65v24.3H48.49z M69.8,28.81c0.84-0.41,1.5-1.02,1.96-1.84c0.47-0.82,0.71-1.84,0.71-3.06c0-1.38-0.35-2.62-1.05-3.72
	c-0.7-1.1-1.53-2.1-2.49-3l-4.29-4.05c-0.64-0.62-1.08-1.2-1.33-1.74c-0.25-0.54-0.38-1.14-0.38-1.8c0-1.1,0.32-1.91,0.96-2.43
	c0.64-0.52,1.46-0.78,2.46-0.78c0.94,0,1.67,0.19,2.21,0.57c0.53,0.38,0.92,0.94,1.17,1.67c0.25,0.73,0.42,1.6,0.5,2.62l1.95-0.51
	c-0.1-1.12-0.29-2.07-0.57-2.85c-0.28-0.78-0.67-1.41-1.16-1.9s-1.08-0.84-1.77-1.07c-0.69-0.22-1.47-0.33-2.33-0.33
	c-1.66,0-2.99,0.45-3.99,1.35c-1,0.9-1.5,2.19-1.5,3.87c0,0.94,0.18,1.81,0.54,2.6c0.36,0.79,0.92,1.55,1.68,2.29l4.29,4.11
	c0.92,0.86,1.65,1.69,2.18,2.48c0.53,0.79,0.79,1.71,0.79,2.77c0,1.22-0.3,2.11-0.9,2.67c-0.6,0.56-1.44,0.84-2.52,0.84
	c-0.98,0-1.75-0.24-2.31-0.71s-0.97-1.11-1.23-1.92c-0.26-0.81-0.44-1.71-0.54-2.72l-2.01,0.6c0.1,1.32,0.37,2.47,0.81,3.46
	c0.44,0.99,1.09,1.76,1.96,2.31s1.96,0.83,3.29,0.83C67.99,29.43,68.96,29.22,69.8,28.81z M76.48,29.13l1.23-6.51h5.79l1.2,6.51
	h2.25l-5.16-24.3h-2.4l-5.13,24.3H76.48z M80.59,7.86l2.61,13.11h-5.16L80.59,7.86z M93.7,29.13V6.6h4.44V4.83H86.83V6.6h4.59v22.53
	H93.7z M110,29.13v-1.71h-6.9V4.83h-2.28v24.3H110z M113.85,29.13l1.23-6.51h5.79l1.2,6.51h2.25l-5.16-24.3h-2.4l-5.13,24.3H113.85z
	 M117.96,7.86l2.61,13.11h-5.16L117.96,7.86z M129.12,29.13V10.05l7.8,19.08h1.62V4.83h-1.98V23.7l-7.74-18.87h-1.65v24.3H129.12z
	 M148.01,29.13V6.6h4.44V4.83h-11.31V6.6h4.59v22.53H148.01z M157.37,29.13V4.83h-2.28v24.3H157.37z M170.3,28.51
	c0.83-0.61,1.41-1.46,1.74-2.56s0.49-2.36,0.49-3.78v-1.44h-2.16v1.59c0,1.06-0.08,1.98-0.25,2.77s-0.52,1.41-1.04,1.84
	c-0.52,0.44-1.29,0.66-2.31,0.66c-0.98,0-1.75-0.2-2.31-0.6s-0.96-1-1.18-1.8c-0.23-0.8-0.35-1.8-0.35-3V11.85
	c0-1.08,0.09-2.03,0.29-2.85c0.19-0.82,0.56-1.46,1.11-1.91s1.37-0.67,2.45-0.67c1.02,0,1.79,0.21,2.31,0.63
	c0.52,0.42,0.87,1.01,1.04,1.77c0.17,0.76,0.25,1.67,0.25,2.73v1.41h2.16v-1.47c0-1.42-0.18-2.65-0.54-3.68
	c-0.36-1.03-0.96-1.82-1.79-2.38c-0.83-0.56-1.98-0.84-3.44-0.84c-1.6,0-2.85,0.32-3.75,0.96c-0.9,0.64-1.53,1.53-1.88,2.67
	c-0.35,1.14-0.53,2.46-0.53,3.96v9.66c0,1.4,0.17,2.67,0.51,3.83s0.96,2.07,1.85,2.75c0.89,0.68,2.15,1.02,3.79,1.02
	C168.3,29.43,169.47,29.12,170.3,28.51z M181.46,29.13V17.64h3.57c1.38,0,2.49-0.29,3.34-0.87c0.85-0.58,1.46-1.35,1.83-2.32
	c0.37-0.97,0.55-2.03,0.55-3.17c0-1.3-0.18-2.43-0.54-3.39c-0.36-0.96-0.96-1.71-1.79-2.25s-1.97-0.81-3.44-0.81h-5.82v24.3H181.46z
	 M181.46,6.54h2.94c1.08,0,1.91,0.13,2.51,0.41c0.59,0.27,1,0.75,1.24,1.44c0.24,0.69,0.36,1.66,0.36,2.9
	c0,1.16-0.14,2.08-0.42,2.76c-0.28,0.68-0.72,1.17-1.33,1.46c-0.61,0.29-1.41,0.43-2.38,0.43h-2.91V6.54z M203.17,29.13v-1.71h-6.54
	V17.4h5.28v-1.68h-5.28V6.6h6.48V4.83h-8.79v24.3H203.17z M209.04,29.13V17.19h3.81l3.57,11.94h2.22l-3.72-12.42
	c0.88-0.18,1.56-0.55,2.04-1.11c0.48-0.56,0.82-1.24,1.02-2.04c0.2-0.8,0.3-1.65,0.3-2.55c0-1.28-0.19-2.38-0.57-3.31
	c-0.38-0.93-1-1.64-1.88-2.13c-0.87-0.49-2.06-0.73-3.58-0.73h-5.49v24.3H209.04z M209.04,6.54h3.15c1.54,0,2.57,0.37,3.09,1.11
	c0.52,0.74,0.78,1.86,0.78,3.36c0,1-0.12,1.83-0.36,2.49s-0.64,1.16-1.2,1.5c-0.56,0.34-1.35,0.51-2.37,0.51h-3.09V6.54z
	 M224.68,29.13V4.83h-2.28v24.3H224.68z M237.29,28.81c0.84-0.41,1.5-1.02,1.96-1.84c0.47-0.82,0.71-1.84,0.71-3.06
	c0-1.38-0.35-2.62-1.05-3.72c-0.7-1.1-1.53-2.1-2.49-3l-4.29-4.05c-0.64-0.62-1.08-1.2-1.33-1.74c-0.25-0.54-0.38-1.14-0.38-1.8
	c0-1.1,0.32-1.91,0.96-2.43c0.64-0.52,1.46-0.78,2.46-0.78c0.94,0,1.67,0.19,2.21,0.57c0.53,0.38,0.92,0.94,1.17,1.67
	c0.25,0.73,0.41,1.6,0.49,2.62l1.95-0.51c-0.1-1.12-0.29-2.07-0.57-2.85c-0.28-0.78-0.67-1.41-1.16-1.9s-1.08-0.84-1.77-1.07
	c-0.69-0.22-1.46-0.33-2.32-0.33c-1.66,0-2.99,0.45-3.99,1.35c-1,0.9-1.5,2.19-1.5,3.87c0,0.94,0.18,1.81,0.54,2.6
	c0.36,0.79,0.92,1.55,1.68,2.29l4.29,4.11c0.92,0.86,1.64,1.69,2.18,2.48c0.53,0.79,0.79,1.71,0.79,2.77c0,1.22-0.3,2.11-0.9,2.67
	c-0.6,0.56-1.44,0.84-2.52,0.84c-0.98,0-1.75-0.24-2.31-0.71c-0.56-0.47-0.97-1.11-1.23-1.92c-0.26-0.81-0.44-1.71-0.54-2.72
	l-2.01,0.6c0.1,1.32,0.37,2.47,0.81,3.46s1.1,1.76,1.96,2.31c0.87,0.55,1.96,0.83,3.29,0.83C235.48,29.43,236.45,29.22,237.29,28.81
	z M252.93,28.51c0.83-0.61,1.41-1.46,1.74-2.56s0.49-2.36,0.49-3.78v-1.44H253v1.59c0,1.06-0.08,1.98-0.25,2.77
	s-0.52,1.41-1.04,1.84c-0.52,0.44-1.29,0.66-2.31,0.66c-0.98,0-1.75-0.2-2.31-0.6s-0.96-1-1.18-1.8c-0.23-0.8-0.35-1.8-0.35-3V11.85
	c0-1.08,0.09-2.03,0.29-2.85c0.19-0.82,0.56-1.46,1.11-1.91s1.37-0.67,2.45-0.67c1.02,0,1.79,0.21,2.31,0.63
	c0.52,0.42,0.87,1.01,1.04,1.77c0.17,0.76,0.25,1.67,0.25,2.73v1.41h2.16v-1.47c0-1.42-0.18-2.65-0.54-3.68
	c-0.36-1.03-0.96-1.82-1.79-2.38c-0.83-0.56-1.98-0.84-3.44-0.84c-1.6,0-2.85,0.32-3.75,0.96c-0.9,0.64-1.53,1.53-1.88,2.67
	c-0.35,1.14-0.53,2.46-0.53,3.96v9.66c0,1.4,0.17,2.67,0.51,3.83s0.96,2.07,1.85,2.75c0.89,0.68,2.15,1.02,3.79,1.02
	C250.92,29.43,252.1,29.12,252.93,28.51z M268.71,28.48c0.9-0.63,1.54-1.5,1.91-2.62c0.37-1.12,0.55-2.41,0.55-3.87V11.88
	c0-1.48-0.19-2.76-0.57-3.86c-0.38-1.09-1.03-1.93-1.94-2.53c-0.91-0.6-2.15-0.9-3.73-0.9c-1.56,0-2.79,0.3-3.71,0.9
	c-0.91,0.6-1.56,1.44-1.95,2.53c-0.39,1.09-0.59,2.38-0.59,3.86v10.08c0,1.46,0.19,2.75,0.55,3.88c0.37,1.13,1.01,2.01,1.92,2.64
	c0.91,0.63,2.17,0.94,3.77,0.94C266.55,29.43,267.81,29.11,268.71,28.48z M262.48,26.95c-0.57-0.43-0.96-1.03-1.17-1.8
	c-0.21-0.77-0.31-1.67-0.31-2.69V11.46c0-1.02,0.11-1.91,0.31-2.67c0.21-0.76,0.6-1.34,1.17-1.75s1.38-0.62,2.45-0.62
	c1.08,0,1.9,0.21,2.46,0.62c0.56,0.41,0.94,1,1.15,1.75c0.21,0.76,0.31,1.65,0.31,2.67v11.01c0,1.02-0.11,1.92-0.31,2.69
	c-0.21,0.77-0.6,1.37-1.15,1.8c-0.56,0.43-1.38,0.64-2.46,0.64C263.87,27.6,263.05,27.38,262.48,26.95z M277.48,29.13V17.64h3.57
	c1.38,0,2.49-0.29,3.34-0.87c0.85-0.58,1.46-1.35,1.83-2.32c0.37-0.97,0.55-2.03,0.55-3.17c0-1.3-0.18-2.43-0.54-3.39
	c-0.36-0.96-0.96-1.71-1.79-2.25s-1.97-0.81-3.44-0.81h-5.82v24.3H277.48z M277.48,6.54h2.94c1.08,0,1.91,0.13,2.51,0.41
	c0.59,0.27,1,0.75,1.24,1.44c0.24,0.69,0.36,1.66,0.36,2.9c0,1.16-0.14,2.08-0.42,2.76c-0.28,0.68-0.72,1.17-1.33,1.46
	c-0.61,0.29-1.41,0.43-2.38,0.43h-2.91V6.54z M299.19,29.13v-1.71h-6.54V17.4h5.28v-1.68h-5.28V6.6h6.48V4.83h-8.79v24.3H299.19z"
          />
        </svg>
      );

    case "foundation":
      return (
          <svg version="1.1" id="Layer_1"  x="0px" y="0px" viewBox="0 0 695.1 266.6" style={style}>
            {title}
            <path d="M62.2,76.5c0-13.7-8.1-24.4-22-26.6c7-4.3,12.4-11.8,12.4-23.3C52.6,10.7,41.8,0,23.3,0H0v106.3h29.2
	C48.4,106.3,62.2,95.1,62.2,76.5z M7,6.4h13.5c5.6,0,12.5,0.3,17.7,4.5c3.5,2.9,7.2,8.1,7.2,16.6c0,7.3-2.7,12.7-7,16.2
	c-4.6,3.8-9.1,4.5-14.5,4.5H7V6.4z M7,99.9V54.5h16.6c11.8,0,17.1,1.7,19.9,3c8,3.7,11.3,10.2,11.3,19.8c0,4.9-1.1,11.2-6.9,16.3
	C40.7,100,32,100,24.8,100H7V99.9z"/>
            <path d="M110.1,108c16.1,0,24.7-10.3,28.5-17.6l-5.4-3.2c-4.1,8.6-12.1,14.8-22.6,14.8c-13.7,0-25.8-10.8-25.8-27.4l55.6-0.1
	c0-20.7-13.2-34.7-30.8-34.7c-15.9,0-31.5,11.6-31.5,34.2C78.1,93,89.9,108,110.1,108z M109.6,46c12.9,0,22.5,9.7,24.1,22.6
	l-48.4-0.1C87,55.9,96.4,45.9,109.6,46z"/>
            <path d="M165.6,106.3V71.4c0-8.1,0.2-25.5,13.2-25.5c2.9,0,4.1,0.8,4.8,1.3l3.1-5.8c-2.1-1.1-4.5-1.6-6.8-1.6c-7,0-12,4.3-14.3,9.9
	v-8.4h-6.4v65H165.6z"/>
            <polygon points="201.1,106.3 207.5,106.3 207.5,47.3 218.8,47.3 218.8,41.3 207.5,41.3 207.5,18 201.1,18 201.1,41.3 193.9,41.3
	193.9,47.3 201.1,47.3 "/>
            <path d="M259.4,108c16.1,0,24.7-10.3,28.5-17.6l-5.4-3.2c-4.1,8.6-12.1,14.8-22.6,14.8c-13.7,0-25.8-10.8-25.8-27.4l55.6-0.1
	c0-20.7-13.2-34.7-30.7-34.7c-15.9,0-31.5,11.6-31.5,34.2C227.4,93,239.2,108,259.4,108z M258.9,45.9c12.9,0,22.5,9.7,24.1,22.7
	l-48.4-0.1C236.3,55.9,245.7,45.9,258.9,45.9z"/>
            <rect x="308.5" width="6.4" height="106.3"/>
            <path d="M353.6,102c-7.2,0-14-4.5-16.2-13.1l-6.2,1.9c2.7,10.8,11.6,17.2,22.5,17.2c10,0,21.2-5.9,21.2-20.7c0-3.7-0.6-7.2-3.3-10.5
	c-4.1-5.3-10.2-7-16.7-9.6c-6.4-2.5-11.5-4.6-11.5-11c0-4.8,3.7-10.4,11-10.4c4.9-0.1,9.4,2.8,11.3,7.3l5.4-2.8
	c-3-7-9.4-10.5-17-10.5c-9.9,0-17.4,6.9-17.4,16.7c0,3.1,1,6,2.9,8.5c2.9,3.8,7.6,5.9,14.2,8.6c9.9,4,14.5,6.7,14.5,14
	C368.1,96.1,361.9,102,353.6,102z"/>
            <path d="M397.3,68.7c0-12.9,5.3-22.8,16.6-22.8c14,0,14.3,14.5,14.3,18.6v41.8h6.4V67.1c0-4,0.2-21.2,17.4-21.2
	c3.6-0.2,7.1,1.2,9.7,3.7c3.5,3.7,3.8,9.1,3.8,13.4v43.3h6.4V63c0-5.3-0.3-12.4-5.6-17.7c-2.9-2.9-7.5-5.4-14.7-5.4
	c-8.6,0-15.6,4-19.3,11.8c-1.3-3.3-5.4-11.8-18.2-11.8c-7-0.3-13.5,3.4-16.9,9.6v-8.1H391v65h6.3V68.7z"/>
            <path d="M522.5,108.1c10.2,0.2,19.7-4.9,25.3-13.4v11.6h6.4v-65h-6.4v11.4c-6.2-8.8-15.1-12.8-25.3-12.8c-19,0-31.7,13.9-31.7,33.9
	C490.8,95.2,505.4,108.1,522.5,108.1z M522.8,45.9c11.2,0,25.3,8.1,25.3,27.4c0,15-8.9,28.7-25.2,28.7c-16.4,0-25.5-14-25.5-28.7
	C497.5,55.6,509.9,45.9,522.8,45.9z"/>
            <path d="M583.5,72.5c0-6.4,0.6-12.4,3.5-17.5c2.4-4.3,7-9.1,15.9-9.1c7.8,0,11.3,4,12.8,6.4c2.4,3.7,2.5,8.6,2.5,13.1v41h6.4v-41
	c0-5.7-0.3-11.8-3.2-16.4c-1.4-2.2-6.4-9.1-18-9.1c-11,0-16.9,6.2-19.9,11.6V41.3h-6.4v65h6.4V72.5z"/>
            <path d="M653.9,72.5c0-6.4,0.6-12.4,3.5-17.5c2.4-4.3,7-9.1,15.9-9.1c7.8,0,11.3,4,12.8,6.4c2.4,3.7,2.5,8.6,2.5,13.1v41h6.4v-41
	c0-5.7-0.3-11.8-3.2-16.4c-1.4-2.2-6.4-9.1-18-9.1c-11,0-16.9,6.2-19.9,11.6V41.3h-6.4v65h6.4V72.5z"/>
            <path d="M329.9,162.6h-9.5v64.2h9.5c19.3,0,32.5-13.1,32.5-32.1C362.5,176.1,349.1,162.6,329.9,162.6z"/>
            <polygon points="389.3,202.9 416.9,202.9 403.2,168.3 "/>
            <path d="M543.6,161.6c-18.8,0-32.8,14.6-32.8,33.3s14,33.2,32.8,33.2s32.8-14.6,32.8-33.2l0,0C576.5,176.2,562.5,161.6,543.6,161.6z
	"/>
            <path d="M106.8,161.6C88,161.6,74,176.2,74,194.9s14,33.2,32.8,33.2s32.8-14.6,32.8-33.2h0.1C139.6,176.2,125.6,161.6,106.8,161.6z"
            />
            <path d="M0,122.5v144.1h695.1V122.5H0z M61.3,162.6H36.5v26.5h24.1v4.4H36.5v37.8h-4.8v-73h29.6C61.3,158.3,61.3,162.6,61.3,162.6z
	 M144.6,194.9c0,21-16.5,37.5-37.9,37.5c-21.3,0-37.9-16.5-37.9-37.5c0-20.9,17-37.9,37.9-37.9S144.6,174,144.6,194.9L144.6,194.9z
	 M210.1,203.9c0,8-0.4,13.2-4.2,18.7c-5.5,8-14.1,9.8-20.7,9.8s-15.2-1.9-20.7-9.8c-3.7-5.5-4.2-10.7-4.2-18.7v-45.6h4.7v45.6
	c0,6.9,0.3,11.4,3.1,15.8c4.3,6.9,12.3,8.4,17,8.4s12.7-1.5,17-8.4c2.7-4.4,3.1-8.9,3.1-15.8v-45.7h4.8L210.1,203.9L210.1,203.9z
	 M295.8,233.1L234.6,169v62.3h-4.9v-73.8l61.3,64.2v-63.4h4.8V233.1z M357,221.1c-9.2,9.2-20.5,10.2-26.9,10.2h-14.5v-73H330
	c6.5,0,17.7,1,26.9,10.2c7.8,7.8,10.5,18.4,10.5,26.4S364.8,213.3,357,221.1z M428.4,231.3l-9.7-24h-31.1l-9.7,24h-5.3l30.8-75.2
	l30.3,75.2H428.4z M473.8,162.6h-18.9v68.6H450v-68.6h-18.9v-4.4h42.7L473.8,162.6L473.8,162.6z M490,231.2h-4.8v-73h4.8V231.2z
	 M581.5,194.9c0,21-16.5,37.5-37.9,37.5s-37.9-16.5-37.9-37.5c0-20.9,17-37.9,37.9-37.9S581.5,174,581.5,194.9L581.5,194.9z
	 M663.3,233.1L602,169v62.3h-4.8v-73.8l61.3,64.2v-63.4h4.8V233.1z"/>
          </svg>
      );

    case "back_to_grid":
      return (
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 30 30"
          className={className}
        >
          <g>
            <path d="M10.58,20.68h5.05v-5.05h-5.05V20.68z M11.58,16.63h3.05v3.05h-3.05V16.63z" />
            <path d="M4.26,8.05h5.05V3H4.26V8.05z M5.26,4h3.05v3.05H5.26V4z" />
            <path d="M10.58,8.05h5.05V3h-5.05V8.05z M11.58,4h3.05v3.05h-3.05V4z" />
            <path d="M4.26,14.37h5.05V9.32H4.26V14.37z M5.26,10.32h3.05v3.05H5.26V10.32z" />
            <path d="M10.58,14.37h5.05V9.32h-5.05V14.37z M11.58,10.32h3.05v3.05h-3.05V10.32z" />
            <path d="M21.95,3h-5.05v5.05h5.05V3z M20.95,7.05h-3.05V4h3.05V7.05z" />
            <path d="M4.26,20.68h5.05v-5.05H4.26V20.68z M5.26,16.63h3.05v3.05H5.26V16.63z" />
            <path d="M21.95,9.32h-5.05v5.05h5.05V9.32z M20.95,13.37h-3.05v-3.05h3.05V13.37z" />
            <path
              d="M25.74,16.43l-8.04-0.04l-0.04,8.08l1,0.01l0.03-6.34l3.5,3.5c0.76,0.75,1.16,1.76,1.15,2.84V27h1v-2.52
          c0.02-1.34-0.49-2.6-1.44-3.55l-3.53-3.53l6.36,0.03L25.74,16.43z"
            />
          </g>
        </svg>
      );

    default:
      return "";
  }
});
