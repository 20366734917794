import React from 'react';
import styles from './style.module.css'

// -- Components
import Icons from '../Icons';
import { Link } from 'react-router-dom';

export default function(props = {}) {
  const { title = '' } = props;
  return (
    <div className={styles.logo}>
    <h1>
      <Link to="/">
        <Icons name="logo" title={title} />
      </Link>
    </h1>
    </div>
  );
}
