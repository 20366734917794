import { useState, useEffect } from 'react';
import { wait } from "../lib/helpers";

/**
 * Delay setting a country Id if history has ID set as well
 * Useful for using a zoomed out map when you get to a page and then have it zoom in after a delay
 * @param {string} id,
 * @param {Router.History} history
 * @param {number} delay
 * @return {string} current countryID
 */
export default function useSetMapCountryFromHistory(id = '', history = {}, delay = 100) {

    const { location = {} } = history,
        goTo = location && location.state && location.state.id

    const [mapCountryId, setMapCountryId] = useState(goTo ? null : id)

    useEffect(() => {
        goTo ? setMapCountryIdAsync(goTo) : setMapCountryId(id)
    }, [goTo, id])

    return mapCountryId

    /**
     * set the country id after a delay
     */
    async function setMapCountryIdAsync() {
        await wait(delay)
        setMapCountryId(goTo)
    }
}