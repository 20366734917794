import LazyLoad from 'vanilla-lazyload';
import {
  IMAGE_LOAD_ERROR
} from './constants';

const imageLoadError = new CustomEvent(IMAGE_LOAD_ERROR);

/**
 * Call lazyLoad update on images
 * This is neccessary to lazy load images after the element has been dynamically created
 * Effect should be called using useEffect everytime new lazyloaded images enter the DOM
 * @param {HTMLElement} container
 */

export function loadImages(container = '') {
  if (!window) return;
  const lazyLoadInstance =
    window.lazyLoadInstance ||
    new LazyLoad({
      callback_error: el => {
        el.dispatchEvent(imageLoadError);
      },
      elements_selector: `${container} img[data-src]`
    });
  lazyLoadInstance.update()
}
