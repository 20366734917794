import { COUNTRIES } from "../../lib/constants";

/**
 * Reduce article counts data to friendly names/numbers if article count is above 0
 * only returns top `limit` results
 * @param {object}
 * @param {number} limit
 * @return {object}
 */
function reduceArticleCounts(data = {}, limit = 5) {
  return Object.entries(data)
    .map(([key, value]) => {
      const countryName = COUNTRIES[key] && COUNTRIES[key].name;
      return {
        id: key,
        name: countryName,
        count: value
      };
    })
    .filter(({ count }) => {
      return !!count;
    })
    .sort((a, b) => {
      return b.count - a.count;
    })
    .slice(0, 5);
}

export { reduceArticleCounts };
