import React from "react";
import styles from "./style.module.css";
import { findFlagUrlByIso3Code } from "country-flags-svg";
import { tileFillColorFromScore } from "../../lib/helpers";
import {COUNTRIES} from '../../lib/constants'

export default React.memo(function(props) {
  const { id = "", name = "", code = "", score } = props,
    flag = findFlagUrlByIso3Code(COUNTRIES[id].code),
    background = { background: tileFillColorFromScore(score || 5) },
    displayScore = score ? score.toFixed(1) : "N/A";
  
  return (
    <div className={styles.root} {...props}>
      <div>
        {flag && <img src={flag} alt={`Flag of ${name}`} />}
        <span>{code}</span>
      </div>
      <div style={background} className={styles.score}>
        {displayScore}
      </div>
      <h2>{name}</h2>
    </div>
  );
});
