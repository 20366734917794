import React from 'react';
import Empty from '../components/Empty';
import useLanguage from '../hooks/use_language';

export default function() {
  const i18n = useLanguage();
  return (
    <Empty style={{}}>
      <h2>{i18n('errors', 'not_found')}</h2>
    </Empty>
  );
}
