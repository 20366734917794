import React from "react";
import styles from "./style.module.css";
import { elipse } from "../../lib/helpers";

import Link from "../Link";
import Thumbnail from "./Thumbnail";

export default function SmallTeaser(props) {
  const {
    title = "",
    date = "",
    value = "",
    provider = "",
    className = "",
    img = {},
    link = "",
    noCrop = false,
    mediaTypeIcon = ""
  } = props;
  return (
    <Link to={link} className={className}>
      <div className={styles.content}>
        {mediaTypeIcon}
        {img.url && <Thumbnail img={img} alt={title} noCrop={noCrop} />}
        <div className={styles.textContainer}>
          <div className={styles.dateline}>
            {date && <h5 className={styles.date}>{date}</h5>}
            {provider && <h5 className={styles.provider}>{provider}</h5>}
          </div>
          {title && <h3 className={styles.title}>{elipse(title, 20)}</h3>}
          {value && <p>{value}</p>}
        </div>
      </div>
    </Link>
  );
}
