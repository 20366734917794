import React from "react";
import styles from "./style.module.css";
import { classNames } from "../../../lib/helpers";
import { Link } from "react-router-dom";

function DashboardFigure(props = {}) {
  const {
    figure = () => {},
    children,
    className = "",
    subhead = "",
    to = ""
  } = props;

  const fig = (
    <figure className={classNames(className, styles.root)}>
      <div>{figure}</div>
      <div>
        {subhead && <h4 className={styles.subhead}>{subhead}</h4>}
        {children}
      </div>
    </figure>
  );

  return to ? (
    <Link className={styles.anchored} to={to}>
      {fig}
    </Link>
  ) : (
    fig
  );
}

export default DashboardFigure;
