import React from 'react';
import styles from './style.module.css';

import Image from '../Image';

export default function Thumbnail(props) {
  const { alt = '', img = {}, noCrop = false } = props,
    { url } = img;
  return (
    <Image
      alt={alt}
      src={url}
      cropRatio={1}
      className={styles.thumbnail}
      width={0.3}
      lazy
      noCrop={noCrop}
    />
  );
}
