import React from 'react';
import Icons from '../Icons';
import styles from './style.module.css';
import { classNames } from '../../lib/helpers';

export default function (props) {
  const { className = '' } = props;
  return (
    <Icons
      name="back_to_grid"
      className={classNames(styles.zoomOutButton, className)}
    />
  );
}
