import React from "react";
import styles from "./style.module.css";
import numbro from "numbro";
import { classNames } from "../../../lib/helpers";

function NumberFigure(props = {}) {
  const { bg = "", number = 0, big = false, shadow = false } = props;

  const numbroOpts =
    number > 1000
      ? { mantissa: 1, average: true }
      : { thousandSeparated: true, mantissa: 0 };

  return (
    <div
      style={{ background: bg }}
      className={classNames(
        styles.root,
        big ? styles.big : "",
        shadow ? styles.shadow : ""
      )}
    >
      {numbro(number).format(numbroOpts)}
    </div>
  );
}

export default NumberFigure;
