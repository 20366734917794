import React from 'react';
import styles from './style.module.css';

export default function Tag(props) {
  const { name = '', remove = () => {}, label = '' } = props,
    setLabel =
      props.setLabel ||
      function() {
        return name;
      };

  return (
    <button onClick={remove} className={styles.resetButton}>
      <i className="material-icons"> clear </i> {label || setLabel(name)}{' '}
    </button>
  );
}
