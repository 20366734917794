import React, { useRef, useMemo } from "react";
import styles from "./style.module.css";
import fade from "../../styles/transitions/fadeFast.module.css";
import { CSSTransition } from "react-transition-group";
import { isEU } from "../../lib/countries";
import { interpolate } from "flubber";

// -- Libs
import { data as europe } from "../../lib/europe_map";
import { classNames, animate, easing } from "../../lib/helpers";

// -- Components
import Islands from "./Islands";
import EmptyTiles from "./EmptyTiles";

export default React.memo(function (props) {
  const {
    children,
    state = "",
    countries = [],
    geographyStroke = "rgba(255,255,255,0.2)",
    geographyFill = "rgba(20,10,0,0.25)",
    geographyActiveFill = "",
    rows = 10,
    columns = 10,
    emptyTileFill = "rgba(0, 0, 0, 0.075)",
    viewBox = "0 0 1200 1200",
    getD,
    tileFill = () => {},
    animationTime = 700,
  } = props;

  let scale, translate, zoomedInTransform;

  const primay = useRef(null),
    [country] = countries,
    strokeWidth = country ? 2 : 0;

  if (country) {
    const { zoom = {} } = europe[country];
    ({ scale = 1, translate = [0, -50] } = zoom);
    zoomedInTransform =
      countries.length < 2
        ? `translate(${translate
            .map((val) => `${val}%`)
            .join(", ")}) scale(${scale})`
        : `translateY(-60%)`;
  }

  useMemo(() => {
    if (state === "entering") {
      zoomToCountry(primay.current);
      primay.current.style.transform = zoomedInTransform;
    }
    if (state === "exiting") {
      zoomToTiles(primay.current);
      primay.current.style.transform = "";
    }
  }, [state]);

  const showIslands = !!country,
    showGrid = !showIslands;

  return (
    <svg
      className={styles[state]}
      ref={primay}
      style={
        ["exiting", "entered"].includes(state)
          ? { transform: zoomedInTransform }
          : {}
      }
      stroke={geographyStroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      version="1.2"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
    >
      <CSSTransition
        in={showIslands}
        timeout={200}
        classNames={fade}
        mountOnEnter
      >
        <Islands
          className={classNames(styles.secondarySvg, styles.islands)}
          highlight={country}
          highlightColor={geographyActiveFill || geographyFill}
          defaultColor={geographyFill}
        />
      </CSSTransition>
      <CSSTransition in={showGrid} timeout={700} classNames={fade} mountOnEnter>
        <EmptyTiles
          rows={rows}
          columns={columns}
          className={styles.secondarySvg}
          emptyTileFill={emptyTileFill}
          getD={getD}
        />
      </CSSTransition>
      {children}
    </svg>
  );
  function zoomToCountry(svg) {
    if (!window) return;

    Object.keys(europe).forEach((k) => {
      const { d } = europe[k];
      const target = svg.querySelector(`#${k}`);
      if (!d) {
        target.setAttribute("d", "");
        return;
      }
      if (!target) return;
      const geo = target.getAttribute("d"),
        interpolator = interpolate(geo, d);

      target.style.fill = countries.includes(k)
        ? geographyActiveFill
        : geographyFill;

      animate(
        (val) => {
          target.setAttribute("d", interpolator(val));
        },
        animationTime,
        easing.easeOutQuint
      );
    });
  }

  function zoomToTiles(svg) {
    Object.keys(europe).forEach((k) => {
      const { d, tile } = europe[k];
      if (!d) return;
      const target = svg.querySelector(`#${k}`);
      if (!target) return;
      const geo = target.getAttribute("d");
      if (!tile) return;
      const aSquare = getD(tile);
      if (!aSquare) return;
      const interpolator = interpolate(geo, aSquare);

      target.style.fill = tileFill(k, isEU(k));

      animate(
        (val) => {
          target.setAttribute("d", interpolator(val));
        },
        animationTime,
        easing.easeOutQuint
      );
    });
  }
});
