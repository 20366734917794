import React, { useEffect } from "react";
import styles from "./style.module.css";
import { draw } from "./draw";

function DashboardSparkline(props = {}) {
  const { bg = "#fff", data = [], id = "", color = "#000" } = props;

  const svgId = `relationship-sparkline-${id}`;

  useEffect(() => {
    if (data.length < 3) {
      return;
    }
    // build the chart
    draw({
      selector: `#${svgId}`,
      data,
      width: 120,
      height: 45,
      color
    });
  }, [data.length]);

  return (
    <div style={{ background: bg }} className={styles.root}>
      <div id={svgId} />
    </div>
  );
}

export default DashboardSparkline;
