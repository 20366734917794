/**
 * @typedef {object} DateRange
 * @property {Date} from
 * @property {Date} to
 */

/**
 * Get dates from a range
 * e.g., `1.5.2019-2.5.2019`
 * @param {string} dateRangeString
 * @param {string} speparator - date range separator
 * @returns {object} out
 */
export function getDatesFromRange(dateRangeString = "", separator = "-") {
  const out = {
    from: null,
    to: null
  };

  if (!dateRangeString) return out;

  const [_from, _to] = dateRangeString.split(separator);
  const to = stringToDate(_to) || null;
  const from = stringToDate(_from) || null;

  return {
    to,
    from
  };
}

/**
 * Translate string to date
 * @param {string} dateString
 * @param {string} separator
 * @returns {Date}
 */
export function stringToDate(dateString = "", separator = ".") {
  if (!dateString) return null;
  try {
    const [date, month, year] = dateString.split(separator);
    return new Date(Number(year), Number(month) - 1, Number(date));
  } catch (e) {
    return null;
  }
}

/**
 * Translate date to string
 * @param {Date} date
 * @param {string} separator
 * @returns {Date}
 */
export function dateToString(date, separator = ".") {
  return `${date.getDate()}${separator}${date.getMonth() +
    1}${separator}${date.getFullYear()}`;
}

/**
 * Get Unix timestamp from date
 * @param {Date} date
 * @returns {number} in milliseconds
 */
export function getUnixTimeStamp(date) {
  if (!date) return null;
  return parseInt(date.getTime().toFixed(0));
}

/**
 * Get a point in time X months from date
 * @param {Date} date
 * @param {number} months
 * @return {Date}
 */
export function getXMonthsFromDate(date, months = 1) {
  const d = new Date(date);
  d.setMonth(d.getMonth() + months);
  return d;
}

/**
 * Sort by date
 * @param {object} a
 * @param {date} a.date
 * @param {object} b
 * @param {date} b.date
 */
export function sortByDate(a, b) {
  return a.date < b.date ? 1 : -1;
}

export function getTimeSlices(min, max, interval = 6, padding = 0) {
  if (!min || !max || !min instanceof Date || !min instanceof Date)
    throw new Error(`min and max date required. Received ${min}, ${max}`);
  const out = [];

  const maxTime = new Date(max).getTime() + padding,
    minTime = new Date(min).getTime() - padding,
    interTime = getXMonthsFromDate(max, -interval);

  out.push([interTime, maxTime]);
  out.push([minTime, maxTime]);
  return out;
}
