import React, { useEffect, useRef, useState } from 'react';
import styles from './style.module.css';
import { classNames, wait } from '../../lib/helpers';
import TimelineEvent from './TimelineEvent';

export default function(props) {
  const {
      events = [],
      children,
      className = '',
      eventFocusEvent = '',
      setEventFocus = () => {}
    } = props,
    scroller = useRef(null),
    [scrollTarget, setScrollTarget] = useState(null);

  useEffect(() => {
    if (!eventFocusEvent || !window || !scroller.current) return;
    window.addEventListener(eventFocusEvent, ({ detail }) => {
      scrollTo(scroller.current, detail.id);
    });
    return () => {
      window.removeEventListener(eventFocusEvent, scrollTo);
    };
  }, []);

  return (
    <section
      className={classNames(
        styles.root,
        className,
        scrollTarget ? styles.scrolling : ''
      )}
    >
      {children}
      <div className={styles.container}>
        <div className={styles.scrollable} ref={scroller}>
          <ul>
            {events.map(data => {
              const {
                  date = '',
                  title = '',
                  value = '',
                  id = '',
                  link = ''
                } = data,
                className = id === scrollTarget ? styles.targeted : '';
              return (
                <TimelineEvent
                  key={id}
                  id={id}
                  className={className}
                  date={date}
                  title={title}
                  text={value}
                  onIntersected={setEventFocus}
                  link={link}
                />
              );
            })}
          </ul>
        </div>
      </div>
    </section>
  );

  /**
   * Scroll to element by ID
   * @param {HTMLElement} scroller - container with overlflow: auto
   * @param {string} id
   */
  async function scrollTo(scroller, id = '') {
    const padding = 10;
    if (!scroller || !id) return;
    const target = scroller.querySelector(`[data-id="${id}"]`);
    setScrollTarget(id);
    scroller.scrollTo({
      top: target.offsetTop - padding,
      behavior: 'smooth'
    });
    await wait(700);
    setScrollTarget(null);
  }
}
