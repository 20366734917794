import React, { useEffect } from "react";
import useFetchEntries from "../../hooks/use_fetch_entries";
import useStoreon from "storeon/react";
import Loading from "../Loading";
import { sortByDate } from '../../lib/date'

export default Component => props => {
  const { slug = "", countryCode } = props;
  const countrySlug = `country-${slug}`,
    { statusHistory = {}, dispatch } = useStoreon("statusHistory"),
    { error: timelineError, pending: timelinePending, data: timelineData } = useFetchEntries(`${countrySlug}-timeline`, 999),
    { error, data = [] } = statusHistory

  // fetch statusHistory
  useEffect(() => {
    dispatch("status-history/fetch", countrySlug);
  }, [countrySlug]);

  useEffect(() => {
    if (!timelineData.length) {
      return;
    }
    const { date } = timelineData.sort(sortByDate)[0] || {}
    dispatch("lastUpdated/update", {id: countryCode, date: new Date(date)})
  }, [dispatch, timelineData])

  if (error || timelineError) {
    console.error(error || timelineError)
    return ""
  }

  return data[countrySlug] && !timelinePending ? <Component
    {...props}
    timeline={timelineData}
    statusHistory={data[countrySlug]}
  /> : <Loading />
};
