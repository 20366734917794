import Joi from "@hapi/joi";
import { MEDIA_CHILDREN, CONTENT_TYPES_MAP } from "./constants";
import { slugify } from "./helpers";
import { countriesAsArray } from "./countries";
import { convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import unescape from "unescape";

export { newContentStrategy, newData, newDeepDive, newArticle };

/**
 * Takes raw data from API and marshals it into a schema
 * @param {object} data - raw data
 * @param {object} options
 * @returns {object|null}
 */
function newContentStrategy(data = {}, options = {}) {
  const {
    // type,
    // provider,
    // "content-type": contentType = "",
    "keywords": keywords = {},
    "link": url = ""
  } = data;

  let type = "";
  if ("content-type" in keywords) {
    type = keywords["content-type"][0];
  }

  try {
    if (type === "deepdive") return newDeepDive(data, options);
    // if (provider === "finity-data:article") return newData(data, options);
    // if (contentType === "finity-data:article") return newArticle(data, options);
    if (url !== "") return newArticle(data, options);
    // if (contentType.includes("tweet") && url) return newTweet(data);
    return data;
  } catch (e) {
    console.error(e);
    return null;
  }
}

/**
 * Return a tweet
 * @property {object} data
 * @return {object}
 */
export function newTweet(data) {
  const {
      user = {},
      "ext-url": url = "",
      id = "",
      value = "",
      "created-at": date
    } = data,
    {
      icon: avatar = "",
      name = "",
      displayname = "",
      service = "",
      "ext-url": userUrl = ""
    } = user;

  return {
    __type: "tweet",
    avatar,
    name,
    displayname,
    date,
    service,
    url,
    id,
    value: unescape(value),
    userUrl
  };
}

/**
 * Returns a new data type content object
 * @param {object} data - raw data
 * @returns {object}
 */
function newData(data = {}) {}

// -- Articles
const articleSchema = Joi.object()
  .keys({
    id: Joi.string().required(),
    title: Joi.string(),
    created_at: Joi.string().required(),
    keywords: Joi.object().required(),
    body: Joi.string(),
    webshot_url: Joi.string(),
    // "created-at": Joi.number().required(),
    // "content-type": Joi.string().required(),
    // value: Joi.string().required(),
    // entities: Joi.array().required()
  })
  .unknown(true);

/**
 * Returns a new article type content object
 * @param {object} data - raw data
 * @param {object} options
 * @returns {object}
 */
function newArticle(data = {}, { imageSize }) {
  const { error, value: result } = Joi.validate(data, articleSchema);
  if (error) {
    throw new Error(error);
  }
  const {
      id = "",
      title = "",
      body = "",
      created_at = "",
      keywords = {},
      link = "",
      webshot_url = "",
      // provider = "",
      // entities = [],
      // "created-at": createdAt = "",
      // "content-type": contentType = "",
      // "ext-url": extUrl = "",
      // user = {},
      // value = "",
      // tags = []
    } = result
    // [views = {} ] = entities.map(({ views }) => views),
    // targetSize = imageSize || "small" || "medium" || "large",
    // img =
    //   views[targetSize] || views["small"] || views["medium"] || views["large"],
    // {
    //   icon: providerIcon = "",
    //   "ext-url": providerUrl,
    //   displayname: providerName
    // } = user,
    // {
    //   tags: filteredTags = [],
    //   documentSubtypes = []
    // } = filterDocumentSubtypesFromTags(tags);

    const tags = keywords["tag"];
    let providerIcon = "";
    if ("domain-favicon" in keywords) {
      providerIcon = keywords["domain-favicon"][0];
    }

    let providerName = "";
    if ("article-domain" in keywords) {
      providerName = keywords["article-domain"][0];
    }
    
    const {
      tags: filteredTags = [],
      documentSubtypes = []
    } = filterDocumentSubtypesFromTags(tags);
  return {
    __type: "article",
    // link: `#${id}`,
    link: `#${id}`,
    id,
    title,
    date: new Date(created_at),
    value: body, // Used on popup modal
    body: body, // Used on tiles in search view
    // contentType: CONTENT_TYPES_MAP.get(contentType),
    contentType: "Article",
    img: {url: webshot_url},
    extUrl: link,
    provider: providerName,
    providerIcon,
    providerUrl: link,
    providerName,
    tags: filteredTags,
    documentSubtypes
  };
}

// -- Deep Dives

const deepdiveSchema = Joi.object()
  .keys({
    id: Joi.string().required(),
    title: Joi.string().required(),
    created_at: Joi.string().required(),
    keywords: Joi.object().required(),
    body: Joi.string(),
    webshot_url: Joi.string(),
    // name: Joi.string().required(),
    // "created-at": Joi.number().required(),
    // description: Joi.string().required(),
    // meta: Joi.object().required(
    //   Joi.object({
    //     text: Joi.object().required()
    //   })
    // ),
    // tags: Joi.array().required()
  })
  .unknown(true);

/**
 * Returns a new deepdive type schema
 * @param {object} data - raw data
 * @param {object} options
 * @returns {object}
 */
function newDeepDive(data = {}) {
  const { error, value: result } = Joi.validate(data, deepdiveSchema);
  if (error) {
    throw new Error(error);
  }
  const {
      title = "",
      // meta = {},
      created_at: createdAt,
      modified_at: lastModified,
      body = "",
      keywords = {},
      webshot_url = ""
    } = result;
    // { image = "", tag = "" } = meta;

    const tags = keywords["tag"];
    const deepdive_tag = keywords["story-groups-id"][0];
    const id = deepdive_tag;

  return {
    __type: "deepdive",
    link: `/deep-dives/${id}`,
    id,
    deepdive_tag,
    value: body,
    title,
    img: {
      url: webshot_url,
      height: 0,
      width: 0
    },
    date: new Date(createdAt),
    countries: countriesFromTags(tags),
    body,
    lastModified
  };
}

/**
 * Creates a new story (like static page) from API data
 * @param {object} data
 * @param {[]DraftJS.RawState} elements
 * @param {object} meta
 * @param {string} name
 * @returns {object}
 */
export function newStory(data = {}) {
  const { elements = [], meta = {}, name, tags = [], id = "" } = data,
    { thumbnail: image = "" } = meta;
  const description = elements.reduce((acc, item) => {
    const { options = {} } = item,
      { rawState } = options;
    try {
      return `${acc}${stateToHTML(convertFromRaw(rawState))}`;
    } catch (e) {
      return acc;
    }
  }, "");
  return {
    description,
    image,
    name,
    tags,
    id,
    slug: slugify(name)
  };
}

/**
 * Marshal data into an timeline event
 * @param {object} data
 * @return {object}
 */
export function newTimelineEvent(data = {}) {
  // const {
  //     value = "",
  //     data: articleData = {},
  //     "created-at": date = "",
  //     tags = []
  //   } = data,
  //   {
  //     title = "",
  //     url = "",
  //     provider_name: providerName = "",
  //     score = 0
  //   } = articleData;

  const {
    created_at: date = "",
    title = "",
    body: value = "",
    url = "",
    providerName = "",
    keywords = []
  } = data;

  const score = keywords["score"][0];
  const tags = keywords["tag"];

  return {
    date,
    title,
    text: value,
    url,
    providerName,
    score,
    tags
  };
}

/**
 * Given an array of tags, get country data from them (i.e., name/code/etc)
 * @param {Array} tags
 * @return {Array}
 */
function countriesFromTags(tags = []) {
  const regex = /^country-/;
  return tags
    .filter(c => !!c.match(regex))
    .map(tag => {
      const slug = tag.replace(regex, "");
      return countriesAsArray().find(country => {
        return country.slug && country.slug === slug;
      });
    });
}

/**
 * Remove documentType subtags from tags and add them to input
 * @param {[]string} unfiltered
 * @return {{tags: []string, documentSubtypes: []string}}
 */
function filterDocumentSubtypesFromTags(unfiltered = []) {
  const tags = [],
    documentSubtypes = [];
  unfiltered.forEach(tag => {
    if (isSubType(tag)) {
      documentSubtypes.push(tag);
      return;
    }
    tags.push(tag);
  });
  return {
    tags,
    documentSubtypes
  };
}

/**
 * Check if article is document subtype
 * @param {string} tag
 * @return {boolean}
 */
function isSubType(tag) {
  return MEDIA_CHILDREN.includes(tag);
}
