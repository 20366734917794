import React from "react";
import styles from "./style.module.css";

// -- Lib
import { findFlagUrlByIso2Code } from "country-flags-svg";
import { LANGUAGES } from "../../lib/constants";

// -- Components
import Dropdown from "../Dropdown";

// -- Hooks
import useLanguage from "../../hooks/use_language";
import useStoreon from "storeon/react";

export default function () {
  const { settings = {}, dispatch } = useStoreon("settings"),
    i18n = useLanguage();

  const options = LANGUAGES.map(({ id, flag }) => ({
    id,
    flag,
    name: i18n("languages", id),
  }))
    .sort((a, b) => (a.name > b.name ? 1 : -1))
    .map(({ id = "", flag = "", name = "" }) => {
      return {
        value: id,
        label: (
          <div>
            <span>{name}</span>
            <img src={findFlagUrlByIso2Code(flag)} alt={name} />
          </div>
        ),
      };
    });

  // -- Render
  return (
    <Dropdown
      className={styles.langDd}
      options={options}
      value={settings.lang}
      onChange={setLanguage}
    />
  );

  function setLanguage({ value }) {
    dispatch("settings/set-language", value);
  }
}
