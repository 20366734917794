import React, { useState, useEffect } from "react";
import styles from "./style.module.css";
import useTheme from "../../hooks/use_theme";

// -- Libs
import { classNames } from "../../lib/helpers";

// -- Components
import Carousel from "../Carousel";
import { Pagination, Next, Previous } from "../CarouselControls";
import Submenu from "../Submenu";
import Icons from "../Icons";

// -- Hooks
import useLanguage from "../../hooks/use_language";
import useStoreon from "storeon/react";

export default function (props) {
  const { dispatch } = useStoreon("tags", "settings");
  const { close = () => { }, videoUrl = "" } = props;
  dispatch("i18n/fetch");

  const i18n = useLanguage()
  const [blockIntro, setBlockIntro] = useState(true);
  const [currentSlide, setCurrentSlide] = useState("");
  const [highlight, setHighlight] = useState("");

  useTheme("dark");

  useEffect(() => {
    if (currentSlide === 1) return setHighlight("relationship");
    if (currentSlide === 2) return setHighlight("deepdive");
    if (currentSlide === 3) return setHighlight("explore");
    return setHighlight("");
  }, [currentSlide]);

  // -- Render
  return (
    <>
      <Submenu highlight={highlight} className={styles.submenu} disabled >
        <div className={styles.foundation}>
          <Icons name="foundation" title="Bertelsmann Foundation" style={{height: '3.5em'}} />
        </div>
      </Submenu>
      <article className={styles.root}>
        <figure className={styles.videContainer}>
          <video autoPlay muted loop>
            <source src={videoUrl} />
          </video>
        </figure>
        <div>
          <h1 className={styles.heading}>
            <span className={styles.heading__med}>
              {i18n("intro", "welcome")}
            </span>
            <span className={styles.heading__small}>
              ~ {i18n("intro", "to_the")} ~
            </span>
            <Icons
              name="logo"
              title={i18n("site_title")}
              className={styles.heading__logo}
            />
          </h1>
          <Carousel
            className={styles.carousel}
            slides={getSlides(4)}
            render={(slides, controls) => {
              const { current = 0 } = controls;
              setCurrentSlide(current);
              return (
                <>
                  <div className={styles.flexContainer}>
                    <div className={styles.flexContainer__narrow}>
                      <Previous
                        {...controls}
                        className={classNames(
                          styles.nextPrevious,
                          styles.previous
                        )}
                      />
                    </div>
                    <div className={styles.flexContainer__wide}>{slides}</div>
                    <div className={styles.flexContainer__narrow}>
                      <Next
                        {...controls}
                        className={classNames(styles.nextPrevious, styles.next)}
                      />
                    </div>
                  </div>
                  <Pagination
                    {...controls}
                    className={styles.pagination}
                    variant="tiny"
                  />
                </>
              );
            }}
          />
          <p className={styles.cookieNotify}>
            {i18n("intro", "cookies_warning")}
          </p>
          <form onSubmit={onSubmit}>
            <button className={styles.continue}>
              {i18n("intro", "enter")}
            </button>
            <div className={styles.showAgain}>
              <label className={styles.dontShowLabel}>
                <input
                  type="checkbox"
                  checked={blockIntro}
                  onChange={toggleShowAgain}
                />
                {i18n("intro", "dont_show")}
              </label>
            </div>
          </form>
        </div>
      </article>
    </>
  );

  /**
   * Handle submit closes the intro
   */
  function onSubmit(ev) {
    ev.preventDefault();
    close(blockIntro);
  }

  /**
   * Toggle showAgain
   */
  function toggleShowAgain() {
    setBlockIntro(!blockIntro);
  }

  /**
   * Generate slides from i18n document
   * i18n must have entries that match intro.slide_x.header & intro.slide_x.body
   * @param {number} count
   * @return {[]jsx}
   */
  function getSlides(count) {
    const out = [];
    for (let x = 1; x <= count; x++) {
      out.push(
        <div className={styles.slide} key={x}>
          <h2>{i18n("intro", `slide_${x}`, "header")}</h2>
          <p>{i18n("intro", `slide_${x}`, "body")}</p>
        </div>
      );
    }
    return out;
  }
}
