import { fetchTrends } from "../lib/api";
import { EU_COUNTRIES } from "../lib/constants";
import { getSlugFromId } from "../lib/countries";

export default store => {
  store.on("@init", () => ({
    articleCounts: {
      data: [],
      error: null,
      pending: false
    }
  }));

  // -- Entries Total Count
  store.on("articleCounts/fetch", fetchArticleCounts);

  store.on("articleCounts/save", (_, articleCounts = {}) => {
    return { articleCounts };
  });

  store.on("articleCounts/pending", ({ articleCounts }) => {
    return {
      articleCounts: { ...articleCounts, pending: true }
    };
  });

  /**
   * Fetch article counts by country
   * @fires [articleCounts/save|articleCounts/pending]
   */
  async function fetchArticleCounts({ articleCounts = {} }) {
    const { data = {} } = articleCounts;
    if (Object.keys(data).length) return;
    store.dispatch("articleCounts/pending");
    const [error, counts = {}] = await fetchCounts();
    store.dispatch("articleCounts/save", {
      data: counts,
      error
    });
  }
};

/**
 * Sets article counts by country
 * Used to determine map tile colors
 * @return {[error|null, object|null]}
 */
async function fetchCounts() {
  const out = { ...EU_COUNTRIES };
  let error = null;

  const [fetchErr, trends = {}] = await fetchTrends(
    {
      filter: "tag:country-* $link:*",
      limit: 120
    },
    true
  );

  if (fetchErr) {
    error = fetchErr || new Error(`could not find entry trends`);
    return [error, null];
  }
  Object.keys(out).forEach(key => {
    out[key] = trends[`country-${getSlugFromId(key)}`] || 0;
  });
  return [null, out];
}
