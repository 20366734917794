import React, { useEffect } from "react";
import Dropdown from "../Dropdown";
import { COUNTRIES } from "../../lib/constants";
import useLanguage from "../../hooks/use_language";
import { getCountryTagId } from "../../lib/countries";
import useStoreon from "storeon/react";

export default React.memo(function (props) {
  const { className = "", initialValue = "", onChange = () => { } } = props;

  const { relationships = [], dispatch } = useStoreon("relationships");

  useEffect(() => {
    dispatch("relationships/fetch");
  });

  const i18n = useLanguage(),
    dropdownOptions = Object.keys(COUNTRIES)
      .filter(key => COUNTRIES[key].inEU)
      .filter(inRelationshipsTracker)
      .map(key => {
        return {
          value: key,
          label: i18n("countries", key.toLowerCase()) || key
        };
      });


  return (
    <Dropdown
      className={className}
      options={dropdownOptions}
      onChange={onChange}
      value={initialValue}
      placeholder="Select an option"
    />
  );

  function inRelationshipsTracker(countryId) {
    const slug = getCountryTagId(countryId),
      { data = [] } = relationships;

    return data.some(({ keywords = {} }) => {
      const tag = keywords["story-groups-id"][0];
      return tag === slug;
    });
  }
});
