import { useEffect, useState } from "react";

import { fetchEntries } from "../lib/api";

import { sortByDate } from "../lib/date";

/**
 * Fetch entries based on tag
 * @param {string} tag
 * @param {number} limit
 * @return {[error, []Entry]}
 */
export default function (tag = "", limit = 20) {
  const [state, setState] = useState({
    pending: true,
    error: null,
    data: []
  });

  useEffect(() => {
    if (!tag) return;
    (async () => {
      const [error, events = []] = await fetchEntries({
        tags: [tag],
        limit,
        offset: 0
      });
      setState({
        data: events.sort(sortByDate),
        error,
        pending: false
      });
    })()
  }, [tag]);

  return state;
}

