import Tile from './tile';

export default class TileSet {
  constructor() {
    this.entries = [];
    this.tiles = new Map();
  }

  get ids() {
    return Array.from(this.tiles.keys());
  }

  addTile(name = '', ...args) {
    if (!name || typeof name !== 'string') {
      throw new Error(`Tile name must be non-empty string. Recieved: ${name}`);
    }
    this.tiles.set(name, new Tile(...args));
    return this.tiles.get(name);
  }

  deleteTile(name = '') {
    this.tiles.delete(name);
  }

  tile(name = '', ...args) {
    return this.tiles.get(name) || this.addTile(name, ...args);
  }

  split(name = '') {
    const newTiles = this.tile(name).splitItems(name);
    this.deleteTile(name);
    newTiles.forEach((tile, idx) => {
      this.tiles.set(`${name}_${idx}`, tile);
    });
  }

  sort() {
    this.tiles = new Map(
      [...this.tiles].sort((a, b) => {
        const [{ }, { sortWeight: weightA = 0 }] = a,
          [{ }, { sortWeight: weightB = 0 }] = b;
        return weightA < weightB ? 1 : -1;
      })
    );
    return this;
  }

  get tilesArray() {
    return Array.from(this.removeEmtpy().tiles.entries(), ([key, tile]) => {
      tile.id = key;
      return tile;
    });
  }

  // -- Private
  removeEmtpy() {
    this.tiles.forEach((tile, key) => {
      // Remove empty tiles
      if (tile.empty) {
        this.deleteTile(key);
      }
    });
    return this;
  }
}
