import React, { useState, useEffect } from "react";
import useStoreon from "storeon/react";

// -- Styles
import styles from "./style.module.css";

// -- Lib
import { classNames } from "../../lib/helpers";

// -- Hooks
import useLanguage from "../../hooks/use_language";

// -- Components
import { withRouter } from "react-router-dom";
import Logo from "./Logo";
import LanguageDropdown from "../LanguageDropdown";
import Icon from '../Icons'

export default withRouter(function (props = {}) {
  const { children, location } = props;
  const [showMenu, setShowMenu] = useState(false);
  const i18n = useLanguage();
  const { theme } = useStoreon("theme");

  // Close if on new page
  useEffect(() => {
    setShowMenu(false);
  }, [location.pathname]);

  return (
    <header
      className={classNames(
        styles.root,
        styles[theme],
        showMenu ? styles.showMenu : ""
      )}
    >
      <div className={classNames(styles.flexContainer, "constrain")}>
        <Logo title={i18n("site_title")} />
        {children}
        <a className={styles.foundation} href="https://www.bfna.org/">
          <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="Bertelsmann Foundation"  height="60"/>
        </a>
        <LanguageDropdown />
      </div>
    </header>
  );
});
