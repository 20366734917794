import { fetchPath } from '../lib/api';
import { fetchSquirroPath } from '../lib/api';
import { sortByDate } from '../lib/date';
import { newTimelineEvent } from '../lib/normalize_data';

export default store => {
  store.on('@init', () => ({
    statusHistory: {
      data: {},
      error: null,
      fetched: false,
      pending: false
    }
  }));

  store.on('status-history/fetch-all', async ({ statusHistory = {} }) => {
    const { fetched, pending } = statusHistory
    if (fetched || pending) return
    store.dispatch('status-history/pending');
    const [error, results = []] = await fetchTimeline();
    const data = results.reduce(reduceResult, statusHistory.data);

    store.dispatch('status-history/save', {
      data,
      error,
      fetched: true,
      pending: false
    });
  });

  store.on(
    'status-history/fetch',
    async ({ statusHistory = {} }, countryId = '') => {
      if (!countryId) throw new Error('countryId is required');
      if (statusHistory.data[countryId]) return;
      const [error, results = []] = await fetchTimeline(countryId),
        data = results.reduce(reduceResult, statusHistory.data);

      store.dispatch('status-history/save', {
        ...statusHistory,
        data: { ...statusHistory.data, ...data },
        error
      });
    }
  );

  store.on('status-history/save', (_, statusHistory = {}) => {
    return { statusHistory };
  });

  store.on('status-history/pending', ({ statusHistory }) => {
    store.dispatch('status-history/save', {
      ...statusHistory,
      pending: true
    });
  });

  /**
   * Reducer marshals fetched results into countryId
   * @param {object} acc
   * @param {object} result
   * @return {object}
   */
  function reduceResult(acc, result = {}) {
    const countryId = result.countryId;
    acc[countryId] = [...(acc[countryId] || []), result];
    return acc;
  }
};

/**
 * Fetch timeline events
 * @param {...string} tags
 * @return {[error, []Entry]}
 */
export async function fetchTimeline(...tags) {
  const [error, entries = []] = await fetchSquirroPath('/api/entries', {
    sort: 'latest',
    // index: 'messages',
    query: 'content-type:message',
    tags,
    // fields: ['tags', 'data', 'created-at'],
    limit: 900
  });
  return [
    error,
    entries.items
      .map(newTimelineEvent)
      .map(({ score, date, tags }) => {
        return {
          value: score,
          date: new Date(date),
          countryId: tags[0]
        };
      })
      .sort(sortByDate)
      .filter(d => !!d)
  ];
}
