import React from 'react';
import numbro from 'numbro';
import styles from './style.module.css';

export default function(props) {
  const { count, itemsFound = '' } = props;

  const numbroOpts =
    count > 100000
      ? { mantissa: 1, average: true }
      : { thousandSeparated: true, mantissa: 0 };

  return (
    count !== null && (
      <h4 className={styles.itemsFound}>
        {numbro(count).format(numbroOpts)} {itemsFound}
      </h4>
    )
  );
}
