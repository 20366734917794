import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import useStoreon from "storeon/react";
import { debounce } from "lodash";

const nearTop = 10;
const debounceTimer = 100;

const ScrollToTop = ({ children, location: { pathname } }) => {
  const { dispatch } = useStoreon();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // const [isNearTop, setIsNearTop] = useState(true); // used to style the filters container

  // Set whether the scroll position is near the top or not
  useEffect(() => {
    const _onScroll = debounce(() => {
      dispatch("setIsScrollPositionTop", window.pageYOffset < nearTop);
    }, debounceTimer);
    window.addEventListener("scroll", _onScroll);
    return () => {
      window.removeEventListener("scroll", _onScroll);
    };
  });

  return children;
};

export default withRouter(ScrollToTop);
